<div *ngIf="isLoaded" class="outerColumn">
  <p class="infoText" style="font-size:17px !important;text-decoration: underline;width:100% !important;text-align: center;margin-bottom: 50px"><b>{{werf.naam}}</b></p>
  <p class="infoText" *ngIf="werf.uitvoeringsTermijn != null">Uitvoeringstermijn: <b>{{werf.uitvoeringsTermijn}}</b> werkdagen</p>
  <p class="infoText" *ngIf="latestDagboek != null && werf.uitvoeringsTermijn != null">Aantal dagboeken/verstreken dagen: <b>{{latestDagboek.bladNummer}}</b></p>
  <p style="font-size: 18px" class="infoText" *ngIf="werf.uitvoeringsTermijn == null"><b>Gelieve een uitvoerinstermijn in te stellen op deze werf bij werfinstellingen!</b></p>
  <p class="infoText" *ngIf="firstDagboek != null ">Dagboeken tussen: <b>{{dateToString(firstDate)}}</b> tot <b>{{dateToString(lastDate)}}</b></p>
  <p style="font-size: 18px; margin-top: 60px;" *ngIf="firstDagboek == null" class="infoText"><b>U heeft nog geen dagboeken aangemaakt op deze werf.</b></p>
  <p></p>
 <!-- <p  *ngIf="firstDagboek != null" style="font-weight: bold;margin-bottom: 5px;">Selecteer periode 3 laatste weken:</p>
  <div  *ngIf="firstDagboek != null" class="periodeRowWrap">
    <div *ngFor="let range of weekDateRanges" class="date-range-button">
      <button (click)="fetchDataForRange(range.start, range.end)">
        {{ range.start | date:'dd-MM-yyyy' }} tot {{ range.end | date:'dd-MM-yyyy' }}
      </button>
    </div>
  </div>
  <div  *ngIf="firstDagboek != null" style="margin-top: 30px" class="date-range-button">
  <button  class="date-range-button" (click)="selectWholeProject()">
    Periode start tot heden selecteren ->
  </button>
  </div>-->
  <div  *ngIf="firstDagboek != null" class="smallColumn">
    <p class="smallTitle">Periode download dagboeken:</p>
    <form [formGroup]="rangeForm">
      <div style="display:flex;flex-direction: row" formGroupName="dateRange">
        <mat-form-field (click)="picker.open()" class="rangePickerFormField" appearance="fill">
          <mat-label>Kies een periode</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input  (click)="picker.open()" matInput
                   matStartDate
                   formControlName="start"
                   placeholder="Startdatum"
                   #startDateInput>
            <input  (click)="picker.open()" matInput
                   matEndDate
                   formControlName="end"
                   placeholder="Einddatum"
                   #endDateInput>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <button  class="closeButton" [ngClass]="!rangeForm.get('dateRange').value.start || !rangeForm.get('dateRange').value.end ? 'hideButton' : ''"
                 nbButton status="warning" (click)="clearDatum()">
          <nb-icon style="font-size: 22px" pack="fa" icon="xmark"></nb-icon>
        </button>
      </div>
    </form>


  </div>
  <div *ngIf="firstDagboek != null" style="display: flex; flex-direction:row;justify-content: space-evenly; margin:20px auto 0 auto;">
    <button class='button2' mat-raised-button color="primary" (click)="downloadPdf()" >
      <div class="divInsideButton"><i style="margin-right: 6px;font-size: 20px" class="fa fa-file-pdf"></i> DOWNLOAD PDF</div>
    </button>
  </div>
</div>



