import {LOCALE_ID, NgModule} from '@angular/core';
import {
  MatDatepicker, MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker
} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FormService} from "../services/form.service";
import {
    NbAutocompleteModule, NbButtonModule,
    NbCardModule,
    NbCheckboxModule, NbDatepickerModule, NbFormFieldModule,
    NbIconModule,
    NbInputModule,
    NbMenuModule,
    NbSelectModule, NbSpinnerModule, NbTabsetModule, NbTimepickerModule,
    NbToastrModule, NbToggleModule, NbTooltipModule
} from "@nebular/theme";
import {PagesRoutingModule} from "./pages-routing.module";
import {PagesComponent} from "./pages.component";
import {OverzichtDagrapportenComponent} from "./overzicht-dagrapporten/overzicht-dagrapporten.component";
import {CdkTableModule} from "@angular/cdk/table";
import {ExcelService} from "../services/ExcelService";
import {SettingsComponent} from "./settings/settings.component";

import {FaIconComponent, FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {AdminComponent} from "./admin/admin.component";
import {PhotoPopupDialog} from "./dagrapporten/werk-edit/photo-popup/photo-popup.component";
import {UpdatePopupDialogComponent} from "./update-popup-dialog/update-popup-dialog.component";
import {HasChangedPopupComponent} from "./has-changed-popup/has-changed-popup.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {
  AanmaakvolgordePopupDialogComponent
} from "./aanmaakvolgorde-popup-dialog/aanmaakvolgorde-popup-dialog.component";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {VerlofsComponent} from "./verlofs/verlofs.component";
import {RejectDialogComponent} from "./verlofs/reject-dialog.component";
import {MatIconModule} from "@angular/material/icon";
import {NgClass, NgOptimizedImage, registerLocaleData} from "@angular/common";
import {DagboekExcelService} from "../services/DagboekExcelService";
import {
  DeleteDialogStaatDerVerlet,
  StaatDerVerletdagenComponent
} from "./staat-der-verletdagen/staat-der-verletdagen.component";
import {SaveTotalenDialogComponent} from "./staat-der-verletdagen/save-totalen-dialog/save-totalen-dialog.component";
import {VerlofdagenInstellingenComponent} from "./verlofdagen-instellingen/verlofdagen-instellingen.component";
import {SharedModule} from "../services/shared.module";
import {AanwezigheidslijstComponent} from "./aanwezigheidslijst/aanwezigheidslijst.component";
import {DownloadPopupComponent} from "./download-popup/download-popup.component";
import {KlantenComponent} from "./klanten/klanten.component";
import {KlantenAddComponent} from "./klanten/klanten-add/klanten-add.component";
import {DeleteDialogKlant, KlantenEditComponent} from "./klanten/klanten-edit/klanten-edit.component";
import {QRCodeModule} from "angularx-qrcode";
import {EuralCodeCheckerService} from "../services/euralCodeChecker.service";
import {
  MaakConnectieDialogComponent
} from './klanten/maak-connectie-dialog/maak-connectie-dialog.component';
import {BedrijfExistsDialogComponent} from "./klanten/bedrijf-exists.dialog.component";
import {DagboekPdfDialogComponent} from "./staat-der-verletdagen/dagboek-pdf-dialog/dagboek-pdf-dialog.component";
import { NbMomentDateService} from "@nebular/moment";
import {MatProgressSpinner, MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatCard, MatCardModule, MatCardTitle} from "@angular/material/card";
import {MatDialogActions, MatDialogContent, MatDialogModule, MatDialogTitle} from "@angular/material/dialog";
import {MatInput, MatInputModule} from "@angular/material/input";
import {MatButton, MatButtonModule} from "@angular/material/button";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatSelectModule} from "@angular/material/select";
import {MatTableModule} from "@angular/material/table";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ThemeModule} from "../@theme/theme.module";
import {
  MomentDateAdapter
} from "@angular/material-moment-adapter";
import {MatFormFieldModule} from "@angular/material/form-field";
import localeNl from '@angular/common/locales/nl';
import {AddNewCompanyComponent} from "./admin/add-new-company/add-new-company.component";
import {EditCompanyComponent} from "./admin/edit-company/edit-company.component";
import {WerfplanningComponent} from "./werfplanning/werfplanning.component";
import {VasteVerlofdagenBouwService} from "../services/vasteVerlofdagenBouw.service";
import {MatChip, MatChipSet} from "@angular/material/chips";
import {MatList, MatListItem} from "@angular/material/list";
import {MatButtonToggle, MatButtonToggleGroup} from "@angular/material/button-toggle";
import {ExtraInfoWeerverletBepaling} from "./staat-der-verletdagen/extraInfoWeerverletBepaling";
import {NgxMatTimepickerComponent, NgxMatTimepickerDirective} from "ngx-mat-timepicker";
import {
  SaveMaandTotalenPopupComponent
} from "./staat-der-verletdagen/save-maand-totalen-popup/save-maand-totalen-popup.component";
import {MatTooltip} from "@angular/material/tooltip";

registerLocaleData(localeNl);

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'dddd, D MMMM yyyy',
  },
  display: {
    dateInput: 'dddd, D MMMM yyyy', // 'Saturday, 6 January 2024'
    monthYearLabel: 'MMMM yyyy',
    dateA11yLabel: 'dddd, LLLL D, yyyy',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

@NgModule({ declarations: [
        PagesComponent,
        OverzichtDagrapportenComponent,
        SettingsComponent,
        AdminComponent,
        UpdatePopupDialogComponent,
        HasChangedPopupComponent,
        AanmaakvolgordePopupDialogComponent,
        VerlofsComponent,
        RejectDialogComponent,
        StaatDerVerletdagenComponent,
        SaveTotalenDialogComponent,
        VerlofdagenInstellingenComponent,
        AanwezigheidslijstComponent,
        DownloadPopupComponent,
        KlantenComponent,
        KlantenAddComponent,
        KlantenAddComponent,
        KlantenEditComponent,
        DeleteDialogKlant,
        MaakConnectieDialogComponent,
        BedrijfExistsDialogComponent,
        PhotoPopupDialog,
        DagboekPdfDialogComponent,
        AddNewCompanyComponent,
        EditCompanyComponent,
        WerfplanningComponent,
        ExtraInfoWeerverletBepaling,
        SaveMaandTotalenPopupComponent,
        DeleteDialogStaatDerVerlet
    ],
    exports: [
        PagesComponent
    ], imports: [FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        NbIconModule,
        NbMenuModule,
        PagesRoutingModule,
        MatCardModule,
        NbInputModule,
        MatButtonModule,
        MatInputModule,
        MatDialogModule,
        NbCheckboxModule,
        NbSelectModule,
        MatSelectModule,
        NbDatepickerModule,
        NbCardModule,
        MatTableModule,
        CdkTableModule,
        NbSpinnerModule,
        FontAwesomeModule,
        DragDropModule,
        NbAutocompleteModule,
        InfiniteScrollModule,
        NbButtonModule,
        MatIconModule,
        NgOptimizedImage,
        NbTabsetModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        SharedModule,
        NbFormFieldModule,
        QRCodeModule,
        MatProgressSpinner,
        MatCardTitle,
        MatCard,
        MatDialogContent,
        FaIconComponent,
        MatDialogActions,
        MatDialogTitle,
        MatInput,
        MatButton,
        NgClass,
        ThemeModule,
        MatDateRangeInput,
        MatDatepickerToggle,
        MatDateRangePicker,
        MatDatepicker,
        MatDatepickerInput,
        MatFormFieldModule,
        MatDatepickerModule, MatNativeDateModule,
        FontAwesomeModule,
        NbTimepickerModule.forRoot({
            twelveHoursFormat: false, // use 24 hour format by default
            format: 'HH:mm', // display format
            localization: {
                hoursText: 'Uur',
                minutesText: 'Min',
                secondsText: 'Sec',
                ampmText: 'Am/Pm',
            }
        }), MatChipSet, MatChip, MatListItem, MatList, MatButtonToggle, NbToggleModule, MatButtonToggleGroup, NgxMatTimepickerComponent, NgxMatTimepickerDirective, NbTooltipModule, MatTooltip], providers: [FormService, DagboekExcelService, EuralCodeCheckerService, NbMomentDateService, ExcelService, VasteVerlofdagenBouwService,
        { provide: MAT_DATE_LOCALE, useValue: 'nl-NL' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }, provideHttpClient(withInterceptorsFromDi())] })


export class PagesModule {}
