import { Injectable } from '@angular/core';
import {Euralcode} from "../models/E-digid/euralCode";

@Injectable({
  providedIn: 'root'
})
export class EuralCodeCheckerService {
  euralCodeListNederlands: Euralcode[] = [
    {
      "__EMPTY": "01 01 01",
      "Geconsolideerde versie van 01/06/2015": "afval van de winning van metaalhoudende mineralen"
    },
    {
      "__EMPTY": "01 01 02",
      "Geconsolideerde versie van 01/06/2015": "afval van de winning van niet-metaalhoudende mineralen"
    },
    {
      "__EMPTY": "01 03 04*",
      "Geconsolideerde versie van 01/06/2015": "zuurvormende tailings verkregen bij de verwerking van sulfide-erts"
    },
    {
      "__EMPTY": "01 03 05*",
      "Geconsolideerde versie van 01/06/2015": "andere tailings die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "01 03 06",
      "Geconsolideerde versie van 01/06/2015": "niet onder 01 03 04 en 01 03 05 vallende tailings"
    },
    {
      "__EMPTY": "01 03 07*",
      "Geconsolideerde versie van 01/06/2015": "ander afval van de fysische en chemische verwerking van metaalhoudende mineralen dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "01 03 08",
      "Geconsolideerde versie van 01/06/2015": "niet onder 01 03 07 vallend stof- en poederachtig afval"
    },
    {
      "__EMPTY": "01 03 09",
      "Geconsolideerde versie van 01/06/2015": "niet onder 01 03 10 vallend rood slib van de aluminiumproductie"
    },
    {
      "__EMPTY": "01 03 10*",
      "Geconsolideerde versie van 01/06/2015": "niet onder 01 03 07 vallend rood slib van de aluminiumproductie dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "01 03 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "01 04 07*",
      "Geconsolideerde versie van 01/06/2015": "afval van de fysische en chemische verwerking van niet-metaalhoudende mineralen dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "01 04 08",
      "Geconsolideerde versie van 01/06/2015": "niet onder 01 04 07 vallend grind- en rotsafval"
    },
    {
      "__EMPTY": "01 04 09",
      "Geconsolideerde versie van 01/06/2015": "zand- en kleiafval"
    },
    {
      "__EMPTY": "01 04 10",
      "Geconsolideerde versie van 01/06/2015": "niet onder 01 04 07 vallend stof- en poederachtig afval"
    },
    {
      "__EMPTY": "01 04 11",
      "Geconsolideerde versie van 01/06/2015": "niet onder 01 04 07 vallend afval van de kali- en steenzoutverwerking"
    },
    {
      "__EMPTY": "01 04 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 01 04 07 en 01 04 11 vallende schilfers en ander afval van het wassen en schoonmaken van mineralen"
    },
    {
      "__EMPTY": "01 04 13",
      "Geconsolideerde versie van 01/06/2015": "niet onder 01 04 07 vallend afval van het hakken en zagen van steen"
    },
    {
      "__EMPTY": "01 04 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "01 05 04",
      "Geconsolideerde versie van 01/06/2015": "zoetwaterboorgruis en -afval"
    },
    {
      "__EMPTY": "01 05 05*",
      "Geconsolideerde versie van 01/06/2015": "oliehoudend boorgruis en -afval"
    },
    {
      "__EMPTY": "01 05 06*",
      "Geconsolideerde versie van 01/06/2015": "boorgruis en ander boorafval dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "01 05 07",
      "Geconsolideerde versie van 01/06/2015": "niet onder 01 05 05 en 01 05 06 vallend bariethoudend boorgruis en -afval"
    },
    {
      "__EMPTY": "01 05 08",
      "Geconsolideerde versie van 01/06/2015": "niet onder 01 05 05 en 01 05 06 vallend chloridehoudend boorgruis en -afval"
    },
    {
      "__EMPTY": "01 05 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "02 01 01",
      "Geconsolideerde versie van 01/06/2015": "slib van wassen en schoonmaken"
    },
    {
      "__EMPTY": "02 01 02",
      "Geconsolideerde versie van 01/06/2015": "afval van dierlijke weefsels"
    },
    {
      "__EMPTY": "02 01 03",
      "Geconsolideerde versie van 01/06/2015": "afval van plantaardige weefsels"
    },
    {
      "__EMPTY": "02 01 04",
      "Geconsolideerde versie van 01/06/2015": "kunststofafval (exclusief verpakkingen)"
    },
    {
      "__EMPTY": "02 01 06",
      "Geconsolideerde versie van 01/06/2015": "dierlijke feces, urine en mest (inclusief gebruikt stro), afvalwater, gescheiden ingezameld en elders verwerkt"
    },
    {
      "__EMPTY": "02 01 07",
      "Geconsolideerde versie van 01/06/2015": "afval van de bosbouw"
    },
    {
      "__EMPTY": "02 01 08*",
      "Geconsolideerde versie van 01/06/2015": "agrochemisch afval dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "02 01 09",
      "Geconsolideerde versie van 01/06/2015": "niet onder 02 01 08 vallend agrochemisch afval"
    },
    {
      "__EMPTY": "02 01 10",
      "Geconsolideerde versie van 01/06/2015": "metaalafval"
    },
    {
      "__EMPTY": "02 01 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "02 02 01",
      "Geconsolideerde versie van 01/06/2015": "slib van wassen en schoonmaken"
    },
    {
      "__EMPTY": "02 02 02",
      "Geconsolideerde versie van 01/06/2015": "afval van dierlijke weefsels"
    },
    {
      "__EMPTY": "02 02 03",
      "Geconsolideerde versie van 01/06/2015": "voor consumptie of verwerking ongeschikt materiaal"
    },
    {
      "__EMPTY": "02 02 04",
      "Geconsolideerde versie van 01/06/2015": "slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "02 02 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "02 03 01",
      "Geconsolideerde versie van 01/06/2015": "slib van wassen, schoonmaken, pellen, centrifugeren en scheiden"
    },
    {
      "__EMPTY": "02 03 02",
      "Geconsolideerde versie van 01/06/2015": "afval van conserveermiddelen"
    },
    {
      "__EMPTY": "02 03 03",
      "Geconsolideerde versie van 01/06/2015": "afval van oplosmiddelenextractie"
    },
    {
      "__EMPTY": "02 03 04",
      "Geconsolideerde versie van 01/06/2015": "voor consumptie of verwerking ongeschikt materiaal"
    },
    {
      "__EMPTY": "02 03 05",
      "Geconsolideerde versie van 01/06/2015": "slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "02 03 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "02 04 01",
      "Geconsolideerde versie van 01/06/2015": "grond van het schoonmaken en wassen van bieten"
    },
    {
      "__EMPTY": "02 04 02",
      "Geconsolideerde versie van 01/06/2015": "afgekeurd calciumcarbonaat (= schuimaarde)"
    },
    {
      "__EMPTY": "02 04 03",
      "Geconsolideerde versie van 01/06/2015": "slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "02 04 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "02 05 01",
      "Geconsolideerde versie van 01/06/2015": "voor consumptie of verwerking ongeschikt materiaal"
    },
    {
      "__EMPTY": "02 05 02",
      "Geconsolideerde versie van 01/06/2015": "slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "02 05 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "02 06 01",
      "Geconsolideerde versie van 01/06/2015": "voor consumptie of verwerking ongeschikt materiaal"
    },
    {
      "__EMPTY": "02 06 02",
      "Geconsolideerde versie van 01/06/2015": "afval van conserveermiddelen"
    },
    {
      "__EMPTY": "02 06 03",
      "Geconsolideerde versie van 01/06/2015": "slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "02 06 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "02 07 01",
      "Geconsolideerde versie van 01/06/2015": "afval van wassen, schoonmaken en mechanische bewerking van de grondstoffen"
    },
    {
      "__EMPTY": "02 07 02",
      "Geconsolideerde versie van 01/06/2015": "afval van de destillatie van alcoholische dranken"
    },
    {
      "__EMPTY": "02 07 03",
      "Geconsolideerde versie van 01/06/2015": "afval van chemische behandeling"
    },
    {
      "__EMPTY": "02 07 04",
      "Geconsolideerde versie van 01/06/2015": "voor consumptie of verwerking ongeschikt materiaal"
    },
    {
      "__EMPTY": "02 07 05",
      "Geconsolideerde versie van 01/06/2015": "slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "02 07 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "03 01 01",
      "Geconsolideerde versie van 01/06/2015": "schors- en kurkafval"
    },
    {
      "__EMPTY": "03 01 04*",
      "Geconsolideerde versie van 01/06/2015": "zaagsel, schaafsel, spaanders, hout, spaanplaat en fineer die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "03 01 05",
      "Geconsolideerde versie van 01/06/2015": "niet onder 03 01 04 vallend zaagsel, schaafsel, spaanders, hout, spaanplaat en fineer"
    },
    {
      "__EMPTY": "03 01 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "03 02 01*",
      "Geconsolideerde versie van 01/06/2015": "niet-gehalogeneerde organische houtverduurzamingsmiddelen"
    },
    {
      "__EMPTY": "03 02 02*",
      "Geconsolideerde versie van 01/06/2015": "organochloor-houtverduurzamingsmiddelen"
    },
    {
      "__EMPTY": "03 02 03*",
      "Geconsolideerde versie van 01/06/2015": "organometaal-houtverduurzamingsmiddelen"
    },
    {
      "__EMPTY": "03 02 04*",
      "Geconsolideerde versie van 01/06/2015": "anorganische houtverduurzamingsmiddelen"
    },
    {
      "__EMPTY": "03 02 05*",
      "Geconsolideerde versie van 01/06/2015": "andere houtverduurzamingsmiddelen die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "03 02 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemde houtverduurzamingsmiddelen"
    },
    {
      "__EMPTY": "03 03 01",
      "Geconsolideerde versie van 01/06/2015": "schors- en houtafval"
    },
    {
      "__EMPTY": "03 03 02",
      "Geconsolideerde versie van 01/06/2015": "„green liquor”-slib (afkomstig van de terugwinning van de kookvloeistof)"
    },
    {
      "__EMPTY": "03 03 05",
      "Geconsolideerde versie van 01/06/2015": "ontinktingsslib van papierrecycling"
    },
    {
      "__EMPTY": "03 03 07",
      "Geconsolideerde versie van 01/06/2015": "mechanisch afgescheiden rejects afkomstig van de verpulping van papier- en kartonafval"
    },
    {
      "__EMPTY": "03 03 08",
      "Geconsolideerde versie van 01/06/2015": "afval van het scheiden van voor recycling bestemd papier en karton"
    },
    {
      "__EMPTY": "03 03 09",
      "Geconsolideerde versie van 01/06/2015": "kalkneerslagafval"
    },
    {
      "__EMPTY": "03 03 10",
      "Geconsolideerde versie van 01/06/2015": "onbruikbare vezels en door mechanische afscheiding verkregen vezel-, vulstof- en coatingslib"
    },
    {
      "__EMPTY": "03 03 11",
      "Geconsolideerde versie van 01/06/2015": "niet onder 03 03 10 vallend slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "03 03 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "04 01 01",
      "Geconsolideerde versie van 01/06/2015": "schraapafval"
    },
    {
      "__EMPTY": "04 01 02",
      "Geconsolideerde versie van 01/06/2015": "loogafval"
    },
    {
      "__EMPTY": "04 01 03*",
      "Geconsolideerde versie van 01/06/2015": "ontvettingsafval dat oplosmiddelen bevat zonder vloeibare fase"
    },
    {
      "__EMPTY": "04 01 04",
      "Geconsolideerde versie van 01/06/2015": "chroomhoudende looivloeistof"
    },
    {
      "__EMPTY": "04 01 05",
      "Geconsolideerde versie van 01/06/2015": "chroomvrije looivloeistof"
    },
    {
      "__EMPTY": "04 01 06",
      "Geconsolideerde versie van 01/06/2015": "chroomhoudend slib, met name van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "04 01 07",
      "Geconsolideerde versie van 01/06/2015": "chroomvrij slib, met name van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "04 01 08",
      "Geconsolideerde versie van 01/06/2015": "chroomhoudend gelooid leerafval (snij-afval, polijststof)"
    },
    {
      "__EMPTY": "04 01 09",
      "Geconsolideerde versie van 01/06/2015": "afval van bewerking en afwerking"
    },
    {
      "__EMPTY": "04 01 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "04 02 09",
      "Geconsolideerde versie van 01/06/2015": "afval van composietmaterialen (geïmpregneerde textiel, elastomeren, plastomeren)"
    },
    {
      "__EMPTY": "04 02 10",
      "Geconsolideerde versie van 01/06/2015": "organisch afval van natuurlijke producten (bv. vet en was)"
    },
    {
      "__EMPTY": "04 02 14*",
      "Geconsolideerde versie van 01/06/2015": "afval van afwerking dat organische oplosmiddelen bevat"
    },
    {
      "__EMPTY": "04 02 15",
      "Geconsolideerde versie van 01/06/2015": "niet onder 04 02 14 vallend afval van afwerking"
    },
    {
      "__EMPTY": "04 02 16*",
      "Geconsolideerde versie van 01/06/2015": "kleurstoffen en pigmenten die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "04 02 17",
      "Geconsolideerde versie van 01/06/2015": "niet onder 04 02 16 vallende kleurstoffen en pigmenten"
    },
    {
      "__EMPTY": "04 02 19*",
      "Geconsolideerde versie van 01/06/2015": "slib van afvalwaterbehandeling ter plaatse dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "04 02 20",
      "Geconsolideerde versie van 01/06/2015": "niet onder 04 02 19 vallend slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "04 02 21",
      "Geconsolideerde versie van 01/06/2015": "afval van onverwerkte textielvezels"
    },
    {
      "__EMPTY": "04 02 22",
      "Geconsolideerde versie van 01/06/2015": "afval van verwerkte textielvezels"
    },
    {
      "__EMPTY": "04 02 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "05 01 02*",
      "Geconsolideerde versie van 01/06/2015": "ontzoutingsslib"
    },
    {
      "__EMPTY": "05 01 03*",
      "Geconsolideerde versie van 01/06/2015": "tankbodemslib"
    },
    {
      "__EMPTY": "05 01 04*",
      "Geconsolideerde versie van 01/06/2015": "zuur alkylslib"
    },
    {
      "__EMPTY": "05 01 05*",
      "Geconsolideerde versie van 01/06/2015": "gemorste olie"
    },
    {
      "__EMPTY": "05 01 06*",
      "Geconsolideerde versie van 01/06/2015": "olieachtig slib afkomstig van onderhoudswerkzaamheden aan installaties of apparaten"
    },
    {
      "__EMPTY": "05 01 07*",
      "Geconsolideerde versie van 01/06/2015": "zuurteer"
    },
    {
      "__EMPTY": "05 01 08*",
      "Geconsolideerde versie van 01/06/2015": "overige teer"
    },
    {
      "__EMPTY": "05 01 09*",
      "Geconsolideerde versie van 01/06/2015": "slib van afvalwaterbehandeling ter plaatse dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "05 01 10",
      "Geconsolideerde versie van 01/06/2015": "niet onder 05 01 09 vallend slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "05 01 11*",
      "Geconsolideerde versie van 01/06/2015": "afval van brandstofzuivering met behulp van basen"
    },
    {
      "__EMPTY": "05 01 12*",
      "Geconsolideerde versie van 01/06/2015": "olie die zuren bevat"
    },
    {
      "__EMPTY": "05 01 13",
      "Geconsolideerde versie van 01/06/2015": "ketelvoedingwaterslib"
    },
    {
      "__EMPTY": "05 01 14",
      "Geconsolideerde versie van 01/06/2015": "afval van koeltorens"
    },
    {
      "__EMPTY": "05 01 15*",
      "Geconsolideerde versie van 01/06/2015": "afgewerkte bleekaarde"
    },
    {
      "__EMPTY": "05 01 16",
      "Geconsolideerde versie van 01/06/2015": "zwavelhoudend afval van de ontzwaveling van petroleum"
    },
    {
      "__EMPTY": "05 01 17",
      "Geconsolideerde versie van 01/06/2015": "bitumen"
    },
    {
      "__EMPTY": "05 01 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "05 06 01*",
      "Geconsolideerde versie van 01/06/2015": "zuurteer"
    },
    {
      "__EMPTY": "05 06 03*",
      "Geconsolideerde versie van 01/06/2015": "overige teer"
    },
    {
      "__EMPTY": "05 06 04",
      "Geconsolideerde versie van 01/06/2015": "afval van koeltorens"
    },
    {
      "__EMPTY": "05 06 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "05 07 01*",
      "Geconsolideerde versie van 01/06/2015": "kwikhoudend afval"
    },
    {
      "__EMPTY": "05 07 02",
      "Geconsolideerde versie van 01/06/2015": "zwavelhoudend afval"
    },
    {
      "__EMPTY": "05 07 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "06 01 01*",
      "Geconsolideerde versie van 01/06/2015": "zwavelzuur en zwaveligzuur"
    },
    {
      "__EMPTY": "06 01 02*",
      "Geconsolideerde versie van 01/06/2015": "zoutzuur"
    },
    {
      "__EMPTY": "06 01 03*",
      "Geconsolideerde versie van 01/06/2015": "waterstoffluoride"
    },
    {
      "__EMPTY": "06 01 04*",
      "Geconsolideerde versie van 01/06/2015": "fosfor- en fosforigzuur"
    },
    {
      "__EMPTY": "06 01 05*",
      "Geconsolideerde versie van 01/06/2015": "salpeter- en salpeterigzuur"
    },
    {
      "__EMPTY": "06 01 06*",
      "Geconsolideerde versie van 01/06/2015": "overige zuren"
    },
    {
      "__EMPTY": "06 01 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "06 02 01*",
      "Geconsolideerde versie van 01/06/2015": "calciumhydroxide"
    },
    {
      "__EMPTY": "06 02 03*",
      "Geconsolideerde versie van 01/06/2015": "ammoniumhydroxide"
    },
    {
      "__EMPTY": "06 02 04*",
      "Geconsolideerde versie van 01/06/2015": "natrium- en kaliumhydroxide"
    },
    {
      "__EMPTY": "06 02 05*",
      "Geconsolideerde versie van 01/06/2015": "overige basen"
    },
    {
      "__EMPTY": "06 02 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "06 03 11*",
      "Geconsolideerde versie van 01/06/2015": "vaste zouten en oplossingen die cyanide bevatten"
    },
    {
      "__EMPTY": "06 03 13*",
      "Geconsolideerde versie van 01/06/2015": "vaste zouten en oplossingen die zware metalen bevatten"
    },
    {
      "__EMPTY": "06 03 14",
      "Geconsolideerde versie van 01/06/2015": "niet onder 06 03 11 en 06 03 13 vallende vaste zouten en oplossingen"
    },
    {
      "__EMPTY": "06 03 15*",
      "Geconsolideerde versie van 01/06/2015": "metaaloxiden die zware metalen bevatten"
    },
    {
      "__EMPTY": "06 03 16",
      "Geconsolideerde versie van 01/06/2015": "niet onder 06 03 15 vallende metaaloxiden"
    },
    {
      "__EMPTY": "06 03 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "06 04 03*",
      "Geconsolideerde versie van 01/06/2015": "arseenhoudend afval"
    },
    {
      "__EMPTY": "06 04 04*",
      "Geconsolideerde versie van 01/06/2015": "kwikhoudend afval"
    },
    {
      "__EMPTY": "06 04 05*",
      "Geconsolideerde versie van 01/06/2015": "afval dat andere zware metalen bevat"
    },
    {
      "__EMPTY": "06 04 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "06 05 02*",
      "Geconsolideerde versie van 01/06/2015": "slib van afvalwaterbehandeling ter plaatse dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "06 05 03",
      "Geconsolideerde versie van 01/06/2015": "niet onder 06 05 02 vallend slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "06 06 02*",
      "Geconsolideerde versie van 01/06/2015": "afval dat gevaarlijke sulfiden bevat"
    },
    {
      "__EMPTY": "06 06 03",
      "Geconsolideerde versie van 01/06/2015": "niet onder 06 06 02 vallend afval dat sulfiden bevat"
    },
    {
      "__EMPTY": "06 06 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "06 07 01*",
      "Geconsolideerde versie van 01/06/2015": "asbesthoudend afval van elektrolyse"
    },
    {
      "__EMPTY": "06 07 02*",
      "Geconsolideerde versie van 01/06/2015": "actieve kool van de chloorbereiding"
    },
    {
      "__EMPTY": "06 07 03*",
      "Geconsolideerde versie van 01/06/2015": "bariumsulfaatslib dat kwik bevat"
    },
    {
      "__EMPTY": "06 07 04*",
      "Geconsolideerde versie van 01/06/2015": "oplossingen en zuren, bv. contactzuur"
    },
    {
      "__EMPTY": "06 07 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "06 08 02*",
      "Geconsolideerde versie van 01/06/2015": "afval dat gevaarlijke chloorsilanen bevat"
    },
    {
      "__EMPTY": "06 08 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "06 09 02",
      "Geconsolideerde versie van 01/06/2015": "fosforhoudende slakken"
    },
    {
      "__EMPTY": "06 09 03*",
      "Geconsolideerde versie van 01/06/2015": "calciumhoudend reactieafval dat gevaarlijke stoffen bevat of daarmee is verontreinigd"
    },
    {
      "__EMPTY": "06 09 04",
      "Geconsolideerde versie van 01/06/2015": "niet onder 06 09 03 vallend calciumhoudend reactieafval"
    },
    {
      "__EMPTY": "06 09 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "06 10 02*",
      "Geconsolideerde versie van 01/06/2015": "afval dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "06 10 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "06 11 01",
      "Geconsolideerde versie van 01/06/2015": "calciumhoudend reactieafval van de productie van titaandioxide"
    },
    {
      "__EMPTY": "06 11 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "06 13 01*",
      "Geconsolideerde versie van 01/06/2015": "anorganische gewasbeschermingsmiddelen, houtverduurzamingsmiddelen en andere biociden"
    },
    {
      "__EMPTY": "06 13 02*",
      "Geconsolideerde versie van 01/06/2015": "afgewerkte actieve kool (exclusief 06 07 02)"
    },
    {
      "__EMPTY": "06 13 03",
      "Geconsolideerde versie van 01/06/2015": "zwartsel (carbon black)"
    },
    {
      "__EMPTY": "06 13 04*",
      "Geconsolideerde versie van 01/06/2015": "afval van asbestverwerking"
    },
    {
      "__EMPTY": "06 13 05*",
      "Geconsolideerde versie van 01/06/2015": "roet"
    },
    {
      "__EMPTY": "06 13 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "07 01",
      "Geconsolideerde versie van 01/06/2015": "afval van bereiding, formulering, levering en gebruik (BFLG) van organische basischemicaliën"
    },
    {
      "__EMPTY": "07 01 01*",
      "Geconsolideerde versie van 01/06/2015": "waterige wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 01 03*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde organische oplosmiddelen, wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 01 04*",
      "Geconsolideerde versie van 01/06/2015": "overige organische oplosmiddelen, wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 01 07*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde destillatieresiduen en reactieresiduen"
    },
    {
      "__EMPTY": "07 01 08*",
      "Geconsolideerde versie van 01/06/2015": "overige destillatieresiduen en reactieresiduen"
    },
    {
      "__EMPTY": "07 01 09*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde filterkoek en afgewerkte absorbentia"
    },
    {
      "__EMPTY": "07 01 10*",
      "Geconsolideerde versie van 01/06/2015": "overige filterkoek en afgewerkte absorbentia"
    },
    {
      "__EMPTY": "07 01 11*",
      "Geconsolideerde versie van 01/06/2015": "slib van afvalwaterbehandeling ter plaatse dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "07 01 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 07 01 11 vallend slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "07 01 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "07 02 01*",
      "Geconsolideerde versie van 01/06/2015": "waterige wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 02 03*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde organische oplosmiddelen, wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 02 04*",
      "Geconsolideerde versie van 01/06/2015": "overige organische oplosmiddelen, wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 02 07*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde destillatieresiduen en reactieresiduen"
    },
    {
      "__EMPTY": "07 02 08*",
      "Geconsolideerde versie van 01/06/2015": "overige destillatieresiduen en reactieresiduen"
    },
    {
      "__EMPTY": "07 02 09*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde filterkoek en afgewerkte absorbentia"
    },
    {
      "__EMPTY": "07 02 10*",
      "Geconsolideerde versie van 01/06/2015": "overige filterkoek en afgewerkte absorbentia"
    },
    {
      "__EMPTY": "07 02 11*",
      "Geconsolideerde versie van 01/06/2015": "slib van afvalwaterbehandeling ter plaatse dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "07 02 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 07 02 11 vallend slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "07 02 13",
      "Geconsolideerde versie van 01/06/2015": "kunststofafval"
    },
    {
      "__EMPTY": "07 02 14*",
      "Geconsolideerde versie van 01/06/2015": "afval van additieven die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "07 02 15",
      "Geconsolideerde versie van 01/06/2015": "afval van niet onder 07 02 14 bedoelde additieven"
    },
    {
      "__EMPTY": "07 02 16*",
      "Geconsolideerde versie van 01/06/2015": "afval dat gevaarlijke siliconen bevat"
    },
    {
      "__EMPTY": "07 02 17",
      "Geconsolideerde versie van 01/06/2015": "afval dat niet onder 07 02 16 vallende siliconen bevat"
    },
    {
      "__EMPTY": "07 02 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "07 03 01*",
      "Geconsolideerde versie van 01/06/2015": "waterige wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 03 03*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde organische oplosmiddelen, wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 03 04*",
      "Geconsolideerde versie van 01/06/2015": "overige organische oplosmiddelen, wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 03 07*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde destillatieresiduen en reactieresiduen"
    },
    {
      "__EMPTY": "07 03 08*",
      "Geconsolideerde versie van 01/06/2015": "overige destillatieresiduen en reactieresiduen"
    },
    {
      "__EMPTY": "07 03 09*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde filterkoek en afgewerkte absorbentia"
    },
    {
      "__EMPTY": "07 03 10*",
      "Geconsolideerde versie van 01/06/2015": "overige filterkoek en afgewerkte absorbentia"
    },
    {
      "__EMPTY": "07 03 11*",
      "Geconsolideerde versie van 01/06/2015": "slib van afvalwaterbehandeling ter plaatse dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "07 03 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 07 03 11 vallend slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "07 03 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "07 04 01*",
      "Geconsolideerde versie van 01/06/2015": "waterige wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 04 03*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde organische oplosmiddelen, wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 04 04*",
      "Geconsolideerde versie van 01/06/2015": "overige organische oplosmiddelen, wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 04 07*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde destillatieresiduen en reactieresiduen"
    },
    {
      "__EMPTY": "07 04 08*",
      "Geconsolideerde versie van 01/06/2015": "overige destillatieresiduen en reactieresiduen"
    },
    {
      "__EMPTY": "07 04 09*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde filterkoek en afgewerkte absorbentia"
    },
    {
      "__EMPTY": "07 04 10*",
      "Geconsolideerde versie van 01/06/2015": "overige filterkoek en afgewerkte absorbentia"
    },
    {
      "__EMPTY": "07 04 11*",
      "Geconsolideerde versie van 01/06/2015": "slib van afvalwaterbehandeling ter plaatse dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "07 04 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 07 04 11 vallend slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "07 04 13*",
      "Geconsolideerde versie van 01/06/2015": "vaste afvalstoffen die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "07 04 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "07 05 01*",
      "Geconsolideerde versie van 01/06/2015": "waterige wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 05 03*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde organische oplosmiddelen, wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 05 04*",
      "Geconsolideerde versie van 01/06/2015": "overige organische oplosmiddelen, wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 05 07*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde destillatieresiduen en reactieresiduen"
    },
    {
      "__EMPTY": "07 05 08*",
      "Geconsolideerde versie van 01/06/2015": "overige destillatieresiduen en reactieresiduen"
    },
    {
      "__EMPTY": "07 05 09*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde filterkoek en afgewerkte absorbentia"
    },
    {
      "__EMPTY": "07 05 10*",
      "Geconsolideerde versie van 01/06/2015": "overige filterkoek en afgewerkte absorbentia"
    },
    {
      "__EMPTY": "07 05 11*",
      "Geconsolideerde versie van 01/06/2015": "slib van afvalwaterbehandeling ter plaatse dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "07 05 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 07 05 11 vallend slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "07 05 13*",
      "Geconsolideerde versie van 01/06/2015": "vaste afvalstoffen die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "07 05 14",
      "Geconsolideerde versie van 01/06/2015": "niet onder 07 05 13 vallende vaste afvalstoffen"
    },
    {
      "__EMPTY": "07 05 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "07 06 01*",
      "Geconsolideerde versie van 01/06/2015": "waterige wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 06 03*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde organische oplosmiddelen, wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 06 04*",
      "Geconsolideerde versie van 01/06/2015": "overige organische oplosmiddelen, wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 06 07*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde destillatieresiduen en reactieresiduen"
    },
    {
      "__EMPTY": "07 06 08*",
      "Geconsolideerde versie van 01/06/2015": "overige destillatieresiduen en reactieresiduen"
    },
    {
      "__EMPTY": "07 06 09*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde filterkoek en afgewerkte absorbentia"
    },
    {
      "__EMPTY": "07 06 10*",
      "Geconsolideerde versie van 01/06/2015": "overige filterkoek en afgewerkte absorbentia"
    },
    {
      "__EMPTY": "07 06 11*",
      "Geconsolideerde versie van 01/06/2015": "slib van afvalwaterbehandeling ter plaatse dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "07 06 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 07 06 11 vallend slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "07 06 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "07 07 01*",
      "Geconsolideerde versie van 01/06/2015": "waterige wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 07 03*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde organische oplosmiddelen, wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 07 04*",
      "Geconsolideerde versie van 01/06/2015": "overige organische oplosmiddelen, wasvloeistoffen en moederlogen"
    },
    {
      "__EMPTY": "07 07 07*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde destillatieresiduen en reactieresiduen"
    },
    {
      "__EMPTY": "07 07 08*",
      "Geconsolideerde versie van 01/06/2015": "overige destillatieresiduen en reactieresiduen"
    },
    {
      "__EMPTY": "07 07 09*",
      "Geconsolideerde versie van 01/06/2015": "gehalogeneerde filterkoek en afgewerkte absorbentia"
    },
    {
      "__EMPTY": "07 07 10*",
      "Geconsolideerde versie van 01/06/2015": "overige filterkoek en afgewerkte absorbentia"
    },
    {
      "__EMPTY": "07 07 11*",
      "Geconsolideerde versie van 01/06/2015": "slib van afvalwaterbehandeling ter plaatse dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "07 07 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 07 07 11 vallend slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "07 07 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "08 01 11*",
      "Geconsolideerde versie van 01/06/2015": "afval van verf en lak dat organische oplosmiddelen of andere gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "08 01 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 08 01 11 vallend afval van verf en lak"
    },
    {
      "__EMPTY": "08 01 13*",
      "Geconsolideerde versie van 01/06/2015": "slib van verf of lak dat organische oplosmiddelen of andere gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "08 01 14",
      "Geconsolideerde versie van 01/06/2015": "niet onder 08 01 13 vallend slib van verf of lak"
    },
    {
      "__EMPTY": "08 01 15*",
      "Geconsolideerde versie van 01/06/2015": "waterig slib dat verf of lak met organische oplosmiddelen of andere gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "08 01 16",
      "Geconsolideerde versie van 01/06/2015": "niet onder 08 01 15 vallend waterig slib dat verf of lak bevat"
    },
    {
      "__EMPTY": "08 01 17*",
      "Geconsolideerde versie van 01/06/2015": "afval van verf- en lakverwijdering dat organische oplosmiddelen of andere gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "08 01 18",
      "Geconsolideerde versie van 01/06/2015": "niet onder 08 01 17 vallend afval van verf- en lakverwijdering"
    },
    {
      "__EMPTY": "08 01 19*",
      "Geconsolideerde versie van 01/06/2015": "waterige suspensies die verf of lak met organische oplosmiddelen of andere gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "08 01 20",
      "Geconsolideerde versie van 01/06/2015": "niet onder 08 01 19 vallende waterige suspensies die verf of lak bevatten"
    },
    {
      "__EMPTY": "08 01 21*",
      "Geconsolideerde versie van 01/06/2015": "afval van verf- of lakverwijderaar"
    },
    {
      "__EMPTY": "08 01 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "08 02 02",
      "Geconsolideerde versie van 01/06/2015": "waterig slib dat keramisch materiaal bevat"
    },
    {
      "__EMPTY": "08 02 03",
      "Geconsolideerde versie van 01/06/2015": "waterige suspensies die keramisch materiaal bevatten"
    },
    {
      "__EMPTY": "08 02 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "08 03 07",
      "Geconsolideerde versie van 01/06/2015": "waterig slib dat inkt bevat"
    },
    {
      "__EMPTY": "08 03 08",
      "Geconsolideerde versie van 01/06/2015": "waterig vloeibaar afval dat inkt bevat"
    },
    {
      "__EMPTY": "08 03 12*",
      "Geconsolideerde versie van 01/06/2015": "inktafval dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "08 03 13",
      "Geconsolideerde versie van 01/06/2015": "niet onder 08 03 12 vallend inktafval"
    },
    {
      "__EMPTY": "08 03 14*",
      "Geconsolideerde versie van 01/06/2015": "inktslib dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "08 03 15",
      "Geconsolideerde versie van 01/06/2015": "niet onder 08 03 14 vallend inktslib"
    },
    {
      "__EMPTY": "08 03 16*",
      "Geconsolideerde versie van 01/06/2015": "afval van etsoplossingen"
    },
    {
      "__EMPTY": "08 03 17*",
      "Geconsolideerde versie van 01/06/2015": "tonerafval dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "08 03 18",
      "Geconsolideerde versie van 01/06/2015": "niet onder 08 03 17 vallend tonerafval"
    },
    {
      "__EMPTY": "08 03 19*",
      "Geconsolideerde versie van 01/06/2015": "dispersieolie"
    },
    {
      "__EMPTY": "08 03 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "08 04 09*",
      "Geconsolideerde versie van 01/06/2015": "afval van lijm en kit dat organische oplosmiddelen of andere gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "08 04 10",
      "Geconsolideerde versie van 01/06/2015": "niet onder 08 04 09 vallend afval van lijm en kit"
    },
    {
      "__EMPTY": "08 04 11*",
      "Geconsolideerde versie van 01/06/2015": "slib van lijm en kit dat organische oplosmiddelen of andere gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "08 04 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 08 04 11 vallend slib van lijm en kit"
    },
    {
      "__EMPTY": "08 04 13*",
      "Geconsolideerde versie van 01/06/2015": "waterig slib dat lijm of kit met organische oplosmiddelen of andere gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "08 04 14",
      "Geconsolideerde versie van 01/06/2015": "niet onder 08 04 13 vallend waterig slib dat lijm of kit bevat"
    },
    {
      "__EMPTY": "08 04 15*",
      "Geconsolideerde versie van 01/06/2015": "waterig vloeibaar afval dat lijm of kit met organische oplosmiddelen of andere gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "08 04 16",
      "Geconsolideerde versie van 01/06/2015": "niet onder 08 04 15 vallend waterig vloeibaar afval dat lijm of kit bevat"
    },
    {
      "__EMPTY": "08 04 17*",
      "Geconsolideerde versie van 01/06/2015": "harsolie"
    },
    {
      "__EMPTY": "08 04 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "08 05 01*",
      "Geconsolideerde versie van 01/06/2015": "isocyanaatafval"
    },
    {
      "__EMPTY": "09 01 01*",
      "Geconsolideerde versie van 01/06/2015": "ontwikkelvloeistof en activatoroplossing op basis van water"
    },
    {
      "__EMPTY": "09 01 02*",
      "Geconsolideerde versie van 01/06/2015": "ontwikkelvloeistof voor offsetplaten op basis van water"
    },
    {
      "__EMPTY": "09 01 03*",
      "Geconsolideerde versie van 01/06/2015": "ontwikkelvloeistof op basis van oplosmiddelen"
    },
    {
      "__EMPTY": "09 01 04*",
      "Geconsolideerde versie van 01/06/2015": "fixeervloeistof"
    },
    {
      "__EMPTY": "09 01 05*",
      "Geconsolideerde versie van 01/06/2015": "bleek- en bleekfixeervloeistof"
    },
    {
      "__EMPTY": "09 01 06*",
      "Geconsolideerde versie van 01/06/2015": "zilverhoudend afval van ter plaatse behandeld fotografisch afval"
    },
    {
      "__EMPTY": "09 01 07",
      "Geconsolideerde versie van 01/06/2015": "fotografische film en papier die zilver of zilververbindingen bevatten"
    },
    {
      "__EMPTY": "09 01 08",
      "Geconsolideerde versie van 01/06/2015": "fotografische film en papier zonder zilver of zilververbindingen"
    },
    {
      "__EMPTY": "09 01 10",
      "Geconsolideerde versie van 01/06/2015": "wegwerpcamera's zonder batterijen"
    },
    {
      "__EMPTY": "09 01 11*",
      "Geconsolideerde versie van 01/06/2015": "wegwerpcamera's met onder 16 06 01, 16 06 02 of 16 06 03 vermelde batterijen"
    },
    {
      "__EMPTY": "09 01 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 09 01 11 vallende wegwerpcamera's met batterijen"
    },
    {
      "__EMPTY": "09 01 13*",
      "Geconsolideerde versie van 01/06/2015": "niet onder 09 01 06 vallend waterig vloeibaar afval van ter plaatse uitgevoerde terugwinning van zilver"
    },
    {
      "__EMPTY": "09 01 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "10 01 01",
      "Geconsolideerde versie van 01/06/2015": "bodemas, slakken en ketelstof (exclusief het onder 10 01 04 vallende ketelstof)"
    },
    {
      "__EMPTY": "10 01 02",
      "Geconsolideerde versie van 01/06/2015": "koolvliegas"
    },
    {
      "__EMPTY": "10 01 03",
      "Geconsolideerde versie van 01/06/2015": "vliegas van turf en onbehandeld hout"
    },
    {
      "__EMPTY": "10 01 04*",
      "Geconsolideerde versie van 01/06/2015": "olievliegas and -ketelstof"
    },
    {
      "__EMPTY": "10 01 05",
      "Geconsolideerde versie van 01/06/2015": "calciumhoudend reactieafval van rookgasontzwaveling in vaste vorm"
    },
    {
      "__EMPTY": "10 01 07",
      "Geconsolideerde versie van 01/06/2015": "calciumhoudend reactieafval van rookgasontzwaveling in slibvorm"
    },
    {
      "__EMPTY": "10 01 09*",
      "Geconsolideerde versie van 01/06/2015": "zwavelzuur"
    },
    {
      "__EMPTY": "10 01 13*",
      "Geconsolideerde versie van 01/06/2015": "vliegas van als brandstof gebruikte geëmulgeerde koolwaterstoffen"
    },
    {
      "__EMPTY": "10 01 14*",
      "Geconsolideerde versie van 01/06/2015": "bij meeverbranden vrijkomende bodemas, slakken en ketelstof die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "10 01 15",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 01 14 vallende bij meeverbranden vrijkomende bodemas, slakken en ketelstof"
    },
    {
      "__EMPTY": "10 01 16*",
      "Geconsolideerde versie van 01/06/2015": "bij meeverbranden vrijkomende vliegas die gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 01 17",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 01 16 vallende bij meeverbranden vrijkomende vliegas"
    },
    {
      "__EMPTY": "10 01 18*",
      "Geconsolideerde versie van 01/06/2015": "afval van gasreiniging dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 01 19",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 01 05, 10 01 07 en 10 01 18 vallend afval van gasreiniging"
    },
    {
      "__EMPTY": "10 01 20*",
      "Geconsolideerde versie van 01/06/2015": "slib van afvalwaterbehandeling ter plaatse dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 01 21",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 01 20 vallend slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "10 01 22*",
      "Geconsolideerde versie van 01/06/2015": "waterig slib van ketelreiniging dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 01 23",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 01 22 vallend waterig slib van ketelreiniging"
    },
    {
      "__EMPTY": "10 01 24",
      "Geconsolideerde versie van 01/06/2015": "wervelbedzand"
    },
    {
      "__EMPTY": "10 01 25",
      "Geconsolideerde versie van 01/06/2015": "afval van de opslag en toebereiding van brandstof voor kolengestookte elektriciteitscentrales"
    },
    {
      "__EMPTY": "10 01 26",
      "Geconsolideerde versie van 01/06/2015": "afval van koelwaterzuivering"
    },
    {
      "__EMPTY": "10 01 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "10 02 01",
      "Geconsolideerde versie van 01/06/2015": "afval van de verwerking van slakken"
    },
    {
      "__EMPTY": "10 02 02",
      "Geconsolideerde versie van 01/06/2015": "onverwerkte slakken"
    },
    {
      "__EMPTY": "10 02 07*",
      "Geconsolideerde versie van 01/06/2015": "vast afval van gasreiniging dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 02 08",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 02 07 vallend vast afval van gasreiniging"
    },
    {
      "__EMPTY": "10 02 10",
      "Geconsolideerde versie van 01/06/2015": "walshuid"
    },
    {
      "__EMPTY": "10 02 11*",
      "Geconsolideerde versie van 01/06/2015": "oliehoudend afval van koelwaterzuivering"
    },
    {
      "__EMPTY": "10 02 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 02 11 vallend afval van koelwaterzuivering"
    },
    {
      "__EMPTY": "10 02 13*",
      "Geconsolideerde versie van 01/06/2015": "slib en filterkoek van gasreiniging die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "10 02 14",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 02 13 vallende slib en filterkoek van gasreiniging"
    },
    {
      "__EMPTY": "10 02 15",
      "Geconsolideerde versie van 01/06/2015": "overig(e) slib en filterkoek"
    },
    {
      "__EMPTY": "10 02 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "10 03 02",
      "Geconsolideerde versie van 01/06/2015": "anodeafval"
    },
    {
      "__EMPTY": "10 03 04*",
      "Geconsolideerde versie van 01/06/2015": "slakken van primaire productie"
    },
    {
      "__EMPTY": "10 03 05",
      "Geconsolideerde versie van 01/06/2015": "aluminiumoxideafval"
    },
    {
      "__EMPTY": "10 03 08*",
      "Geconsolideerde versie van 01/06/2015": "zoutslakken van secundaire productie"
    },
    {
      "__EMPTY": "10 03 09*",
      "Geconsolideerde versie van 01/06/2015": "black drosses van secundaire productie"
    },
    {
      "__EMPTY": "10 03 15*",
      "Geconsolideerde versie van 01/06/2015": "skimmings die brandbaar zijn of waaruit bij contact met water gevaarlijke hoeveelheden brandbare gassen vrijkomen"
    },
    {
      "__EMPTY": "10 03 16",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 03 15 vallende skimmings"
    },
    {
      "__EMPTY": "10 03 17*",
      "Geconsolideerde versie van 01/06/2015": "teerhoudend afval van de anodefabricage"
    },
    {
      "__EMPTY": "10 03 18",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 03 17 vallend koolstofhoudend afval van de anodefabricage"
    },
    {
      "__EMPTY": "10 03 19*",
      "Geconsolideerde versie van 01/06/2015": "rookgasstof dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 03 20",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 03 19 vallend rookgasstof"
    },
    {
      "__EMPTY": "10 03 21*",
      "Geconsolideerde versie van 01/06/2015": "overige deeltjes en stof (inclusief kogelmolenstof) die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "10 03 22",
      "Geconsolideerde versie van 01/06/2015": "overige, niet onder 10 03 21 vallende deeltjes en stof (inclusief kogelmolenstof)"
    },
    {
      "__EMPTY": "10 03 23*",
      "Geconsolideerde versie van 01/06/2015": "vast afval van gasreiniging dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 03 24",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 03 23 vallend vast afval van gasreiniging"
    },
    {
      "__EMPTY": "10 03 25*",
      "Geconsolideerde versie van 01/06/2015": "bij gasreiniging vrijkomende slib en filterkoek die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "10 03 26",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 03 25 vallende bij gasreiniging verkregen slib en filterkoek"
    },
    {
      "__EMPTY": "10 03 27*",
      "Geconsolideerde versie van 01/06/2015": "oliehoudend afval van koelwaterzuivering"
    },
    {
      "__EMPTY": "10 03 28",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 03 27 vallend afval van koelwaterzuivering"
    },
    {
      "__EMPTY": "10 03 29*",
      "Geconsolideerde versie van 01/06/2015": "afval van de behandeling van zoutslakken en black drosses dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 03 30",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 03 29 vallend afval van de behandeling van zoutslakken en black drosses"
    },
    {
      "__EMPTY": "10 03 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "10 04 01*",
      "Geconsolideerde versie van 01/06/2015": "slakken van primaire en secundaire productie"
    },
    {
      "__EMPTY": "10 04 02*",
      "Geconsolideerde versie van 01/06/2015": "dross en skimmings van primaire en secundaire productie"
    },
    {
      "__EMPTY": "10 04 03*",
      "Geconsolideerde versie van 01/06/2015": "calciumarsenaat"
    },
    {
      "__EMPTY": "10 04 04*",
      "Geconsolideerde versie van 01/06/2015": "rookgasstof"
    },
    {
      "__EMPTY": "10 04 05*",
      "Geconsolideerde versie van 01/06/2015": "overige deeltjes en stof"
    },
    {
      "__EMPTY": "10 04 06*",
      "Geconsolideerde versie van 01/06/2015": "vast afval van gasreiniging"
    },
    {
      "__EMPTY": "10 04 07*",
      "Geconsolideerde versie van 01/06/2015": "slib en filterkoek van gasreiniging"
    },
    {
      "__EMPTY": "10 04 09*",
      "Geconsolideerde versie van 01/06/2015": "oliehoudend afval van koelwaterzuivering"
    },
    {
      "__EMPTY": "10 04 10",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 04 09 vallend afval van koelwaterzuivering"
    },
    {
      "__EMPTY": "10 04 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "10 05 01",
      "Geconsolideerde versie van 01/06/2015": "slakken van primaire en secundaire productie"
    },
    {
      "__EMPTY": "10 05 03*",
      "Geconsolideerde versie van 01/06/2015": "rookgasstof"
    },
    {
      "__EMPTY": "10 05 04",
      "Geconsolideerde versie van 01/06/2015": "overige deeltjes en stof"
    },
    {
      "__EMPTY": "10 05 05*",
      "Geconsolideerde versie van 01/06/2015": "vast afval van gasreiniging"
    },
    {
      "__EMPTY": "10 05 06*",
      "Geconsolideerde versie van 01/06/2015": "slib en filterkoek van gasreiniging"
    },
    {
      "__EMPTY": "10 05 08*",
      "Geconsolideerde versie van 01/06/2015": "oliehoudend afval van koelwaterzuivering"
    },
    {
      "__EMPTY": "10 05 09",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 05 08 vallend afval van koelwaterzuivering"
    },
    {
      "__EMPTY": "10 05 10*",
      "Geconsolideerde versie van 01/06/2015": "dross en skimmings die brandbaar zijn of waaruit bij contact met water gevaarlijke hoeveelheden brandbare gassen vrijkomen"
    },
    {
      "__EMPTY": "10 05 11",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 05 10 vallende dross en skimmings"
    },
    {
      "__EMPTY": "10 05 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "10 06 01",
      "Geconsolideerde versie van 01/06/2015": "slakken van primaire en secundaire productie"
    },
    {
      "__EMPTY": "10 06 02",
      "Geconsolideerde versie van 01/06/2015": "dross en skimmings van primaire en secundaire productie"
    },
    {
      "__EMPTY": "10 06 03*",
      "Geconsolideerde versie van 01/06/2015": "rookgasstof"
    },
    {
      "__EMPTY": "10 06 04",
      "Geconsolideerde versie van 01/06/2015": "overige deeltjes en stof"
    },
    {
      "__EMPTY": "10 06 06*",
      "Geconsolideerde versie van 01/06/2015": "vast afval van gasreiniging"
    },
    {
      "__EMPTY": "10 06 07*",
      "Geconsolideerde versie van 01/06/2015": "slib en filterkoek van gasreiniging"
    },
    {
      "__EMPTY": "10 06 09*",
      "Geconsolideerde versie van 01/06/2015": "oliehoudend afval van koelwaterzuivering"
    },
    {
      "__EMPTY": "10 06 10",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 06 09 vallend afval van koelwaterzuivering"
    },
    {
      "__EMPTY": "10 06 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "10 07 01",
      "Geconsolideerde versie van 01/06/2015": "slakken van primaire en secundaire productie"
    },
    {
      "__EMPTY": "10 07 02",
      "Geconsolideerde versie van 01/06/2015": "dross en skimmings van primaire en secundaire productie"
    },
    {
      "__EMPTY": "10 07 03",
      "Geconsolideerde versie van 01/06/2015": "vast afval van gasreiniging"
    },
    {
      "__EMPTY": "10 07 04",
      "Geconsolideerde versie van 01/06/2015": "overige deeltjes en stof"
    },
    {
      "__EMPTY": "10 07 05",
      "Geconsolideerde versie van 01/06/2015": "slib en filterkoek van gasreiniging"
    },
    {
      "__EMPTY": "10 07 07*",
      "Geconsolideerde versie van 01/06/2015": "oliehoudend afval van koelwaterzuivering"
    },
    {
      "__EMPTY": "10 07 08",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 07 07 vallend afval van koelwaterzuivering"
    },
    {
      "__EMPTY": "10 07 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "10 08 04",
      "Geconsolideerde versie van 01/06/2015": "deeltjes en stof"
    },
    {
      "__EMPTY": "10 08 08*",
      "Geconsolideerde versie van 01/06/2015": "zoutslakken van primaire en secundaire productie"
    },
    {
      "__EMPTY": "10 08 09",
      "Geconsolideerde versie van 01/06/2015": "overige slakken"
    },
    {
      "__EMPTY": "10 08 10*",
      "Geconsolideerde versie van 01/06/2015": "dross en skimmings die brandbaar zijn of waaruit bij contact met water gevaarlijke hoeveelheden brandbare gassen vrijkomen"
    },
    {
      "__EMPTY": "10 08 11",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 08 10 vallende dross en skimmings"
    },
    {
      "__EMPTY": "10 08 12*",
      "Geconsolideerde versie van 01/06/2015": "teerhoudend afval van de anodefabricage"
    },
    {
      "__EMPTY": "10 08 13",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 08 12 vallend koolstofhoudend afval van de anodefabricage"
    },
    {
      "__EMPTY": "10 08 14",
      "Geconsolideerde versie van 01/06/2015": "anodeafval"
    },
    {
      "__EMPTY": "10 08 15*",
      "Geconsolideerde versie van 01/06/2015": "rookgasstof dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 08 16",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 08 15 vallend rookgasstof"
    },
    {
      "__EMPTY": "10 08 17*",
      "Geconsolideerde versie van 01/06/2015": "slib en filterkoek van rookgasreiniging die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "10 08 18",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 08 17 vallende slib en filterkoek van rookgasreiniging"
    },
    {
      "__EMPTY": "10 08 19*",
      "Geconsolideerde versie van 01/06/2015": "oliehoudend afval van koelwaterzuivering"
    },
    {
      "__EMPTY": "10 08 20",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 08 19 vallend afval van koelwaterzuivering"
    },
    {
      "__EMPTY": "10 08 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "10 09 03",
      "Geconsolideerde versie van 01/06/2015": "ovenslak"
    },
    {
      "__EMPTY": "10 09 05*",
      "Geconsolideerde versie van 01/06/2015": "gietkernen en -vormen die gevaarlijke stoffen bevatten en niet voor gieten zijn gebruikt"
    },
    {
      "__EMPTY": "10 09 06",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 09 05 vallende gietkernen en -vormen die niet voor gieten zijn gebruikt"
    },
    {
      "__EMPTY": "10 09 07*",
      "Geconsolideerde versie van 01/06/2015": "gietkernen en -vormen die gevaarlijke stoffen bevatten en voor gieten zijn gebruikt"
    },
    {
      "__EMPTY": "10 09 08",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 09 07 vallende gietkernen en -vormen die voor gieten zijn gebruikt"
    },
    {
      "__EMPTY": "10 09 09*",
      "Geconsolideerde versie van 01/06/2015": "rookgasstof dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 09 10",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 09 09 vallend rookgasstof"
    },
    {
      "__EMPTY": "10 09 11*",
      "Geconsolideerde versie van 01/06/2015": "andere deeltjes die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "10 09 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 09 11 vallende deeltjes"
    },
    {
      "__EMPTY": "10 09 13*",
      "Geconsolideerde versie van 01/06/2015": "bindmiddelafval dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 09 14",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 09 13 vallend bindmiddelafval"
    },
    {
      "__EMPTY": "10 09 15*",
      "Geconsolideerde versie van 01/06/2015": "afval van scheurindicatorstoffen dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 09 16",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 09 15 vallend afval van scheurindicatorstoffen"
    },
    {
      "__EMPTY": "10 09 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "10 10 03",
      "Geconsolideerde versie van 01/06/2015": "ovenslak"
    },
    {
      "__EMPTY": "10 10 05*",
      "Geconsolideerde versie van 01/06/2015": "gietkernen en -vormen die gevaarlijke stoffen bevatten en niet voor gieten zijn gebruikt"
    },
    {
      "__EMPTY": "10 10 06",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 10 05 vallende gietkernen en -vormen die niet voor gieten zijn gebruikt"
    },
    {
      "__EMPTY": "10 10 07*",
      "Geconsolideerde versie van 01/06/2015": "gietkernen en -vormen die gevaarlijke stoffen bevatten en voor gieten zijn gebruikt"
    },
    {
      "__EMPTY": "10 10 08",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 10 07 vallende gietkernen en -vormen die voor gieten zijn gebruikt"
    },
    {
      "__EMPTY": "10 10 09*",
      "Geconsolideerde versie van 01/06/2015": "rookgasstof dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 10 10",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 10 09 vallend rookgasstof"
    },
    {
      "__EMPTY": "10 10 11*",
      "Geconsolideerde versie van 01/06/2015": "andere deeltjes die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "10 10 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 10 11 vallende deeltjes"
    },
    {
      "__EMPTY": "10 10 13*",
      "Geconsolideerde versie van 01/06/2015": "bindmiddelafval dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 10 14",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 10 13 vallend bindmiddelafval"
    },
    {
      "__EMPTY": "10 10 15*",
      "Geconsolideerde versie van 01/06/2015": "afval van scheurindicatorstoffen dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 10 16",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 10 15 vallend afval van scheurindicatorstoffen"
    },
    {
      "__EMPTY": "10 10 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "10 11 03",
      "Geconsolideerde versie van 01/06/2015": "afval van glasvezelmateriaal"
    },
    {
      "__EMPTY": "10 11 05",
      "Geconsolideerde versie van 01/06/2015": "deeltjes en stof"
    },
    {
      "__EMPTY": "10 11 09*",
      "Geconsolideerde versie van 01/06/2015": "afval van het mengsel vóór thermische verwerking dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 11 10",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 11 09 vallend afval van het mengsel vóór thermische verwerking"
    },
    {
      "__EMPTY": "10 11 11*",
      "Geconsolideerde versie van 01/06/2015": "glasafval in de vorm van kleine glasdeeltjes en glaspoeder die zware metalen bevatten (bv. van kathodestraalbuizen)"
    },
    {
      "__EMPTY": "10 11 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 11 11 vallend glasafval"
    },
    {
      "__EMPTY": "10 11 13*",
      "Geconsolideerde versie van 01/06/2015": "slib van het polijsten en slijpen van glas dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 11 14",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 11 13 vallend slib van het polijsten en slijpen van glas"
    },
    {
      "__EMPTY": "10 11 15*",
      "Geconsolideerde versie van 01/06/2015": "vast afval van rookgasreiniging dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 11 16",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 11 15 vallend vast afval van rookgasreiniging"
    },
    {
      "__EMPTY": "10 11 17*",
      "Geconsolideerde versie van 01/06/2015": "slib en filterkoek van rookgasreiniging die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "10 11 18",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 11 17 vallende slib en filterkoek van rookgasreiniging"
    },
    {
      "__EMPTY": "10 11 19*",
      "Geconsolideerde versie van 01/06/2015": "vast afval van afvalwaterbehandeling ter plaatse dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 11 20",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 11 19 vallend vast afval van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "10 11 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "10 12 01",
      "Geconsolideerde versie van 01/06/2015": "afval van het mengsel vóór thermische verwerking"
    },
    {
      "__EMPTY": "10 12 03",
      "Geconsolideerde versie van 01/06/2015": "deeltjes en stof"
    },
    {
      "__EMPTY": "10 12 05",
      "Geconsolideerde versie van 01/06/2015": "slib en filterkoek van gasreiniging"
    },
    {
      "__EMPTY": "10 12 06",
      "Geconsolideerde versie van 01/06/2015": "afgedankte vormen"
    },
    {
      "__EMPTY": "10 12 08",
      "Geconsolideerde versie van 01/06/2015": "afval van keramische producten, stenen, tegels en bouwmaterialen (na thermische verwerking)"
    },
    {
      "__EMPTY": "10 12 09*",
      "Geconsolideerde versie van 01/06/2015": "vast afval van gasreiniging dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 12 10",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 12 09 vallend vast afval van gasreiniging"
    },
    {
      "__EMPTY": "10 12 11*",
      "Geconsolideerde versie van 01/06/2015": "glazuurafval dat zware metalen bevat"
    },
    {
      "__EMPTY": "10 12 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 12 11 vallend glazuurafval"
    },
    {
      "__EMPTY": "10 12 13",
      "Geconsolideerde versie van 01/06/2015": "slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "10 12 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "10 13 01",
      "Geconsolideerde versie van 01/06/2015": "afval van het mengsel voor thermische verwerking"
    },
    {
      "__EMPTY": "10 13 04",
      "Geconsolideerde versie van 01/06/2015": "afval van het branden en blussen van kalk"
    },
    {
      "__EMPTY": "10 13 06",
      "Geconsolideerde versie van 01/06/2015": "deeltjes en stof (exclusief 10 13 12 en 10 13 13)"
    },
    {
      "__EMPTY": "10 13 07",
      "Geconsolideerde versie van 01/06/2015": "slib en filterkoek van gasreiniging"
    },
    {
      "__EMPTY": "10 13 09*",
      "Geconsolideerde versie van 01/06/2015": "afval van de fabricage van asbestcement dat asbest bevat"
    },
    {
      "__EMPTY": "10 13 10",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 13 09 vallend afval van de fabricage van asbestcement"
    },
    {
      "__EMPTY": "10 13 11",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 13 09 en 10 13 10 vallend afval van cementhoudende composietmaterialen"
    },
    {
      "__EMPTY": "10 13 12*",
      "Geconsolideerde versie van 01/06/2015": "vast afval van gasreiniging dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "10 13 13",
      "Geconsolideerde versie van 01/06/2015": "niet onder 10 13 12 vallend vast afval van gasreiniging"
    },
    {
      "__EMPTY": "10 13 14",
      "Geconsolideerde versie van 01/06/2015": "betonafval en betonslib"
    },
    {
      "__EMPTY": "10 13 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "10 14 01*",
      "Geconsolideerde versie van 01/06/2015": "afval van gasreiniging dat kwik bevat"
    },
    {
      "__EMPTY": "11 01 05*",
      "Geconsolideerde versie van 01/06/2015": "beitszuren"
    },
    {
      "__EMPTY": "11 01 06*",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemde zuren"
    },
    {
      "__EMPTY": "11 01 07*",
      "Geconsolideerde versie van 01/06/2015": "basen gebruikt voor beitsen"
    },
    {
      "__EMPTY": "11 01 08*",
      "Geconsolideerde versie van 01/06/2015": "slib van fosfaatbehandeling"
    },
    {
      "__EMPTY": "11 01 09*",
      "Geconsolideerde versie van 01/06/2015": "slib en filterkoek die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "11 01 10",
      "Geconsolideerde versie van 01/06/2015": "niet onder 11 01 09 vallende slib en filterkoek"
    },
    {
      "__EMPTY": "11 01 11*",
      "Geconsolideerde versie van 01/06/2015": "waterige spoelvloeistoffen die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "11 01 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 11 01 11 vallende waterige spoelvloeistoffen"
    },
    {
      "__EMPTY": "11 01 13*",
      "Geconsolideerde versie van 01/06/2015": "afval van ontvetting dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "11 01 14",
      "Geconsolideerde versie van 01/06/2015": "niet onder 11 01 13 vallend afval van ontvetting"
    },
    {
      "__EMPTY": "11 01 15*",
      "Geconsolideerde versie van 01/06/2015": "eluaat en slib van membraansystemen of ionenwisselaars die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "11 01 16*",
      "Geconsolideerde versie van 01/06/2015": "verzadigde of afgewerkte ionenwisselaarharsen"
    },
    {
      "__EMPTY": "11 01 98*",
      "Geconsolideerde versie van 01/06/2015": "overig afval dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "11 01 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "11 02 02*",
      "Geconsolideerde versie van 01/06/2015": "slib van de zink-hydrometallurgie (inclusief jarosiet en goethiet)"
    },
    {
      "__EMPTY": "11 02 03",
      "Geconsolideerde versie van 01/06/2015": "afval van de productie van anoden voor waterige elektrolyseprocessen"
    },
    {
      "__EMPTY": "11 02 05*",
      "Geconsolideerde versie van 01/06/2015": "afval van koperhydrometallurgische processen dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "11 02 06",
      "Geconsolideerde versie van 01/06/2015": "niet onder 11 02 05 vallend afval van koperhydrometallurgische processen"
    },
    {
      "__EMPTY": "11 02 07*",
      "Geconsolideerde versie van 01/06/2015": "overig afval dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "11 02 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "11 03 01*",
      "Geconsolideerde versie van 01/06/2015": "cyanidehoudend afval"
    },
    {
      "__EMPTY": "11 03 02*",
      "Geconsolideerde versie van 01/06/2015": "overig afval"
    },
    {
      "__EMPTY": "11 05 01",
      "Geconsolideerde versie van 01/06/2015": "hardzink"
    },
    {
      "__EMPTY": "11 05 02",
      "Geconsolideerde versie van 01/06/2015": "zinkas"
    },
    {
      "__EMPTY": "11 05 03*",
      "Geconsolideerde versie van 01/06/2015": "vast afval van gasreiniging"
    },
    {
      "__EMPTY": "11 05 04*",
      "Geconsolideerde versie van 01/06/2015": "fluxbad afval"
    },
    {
      "__EMPTY": "11 05 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "12 01 01",
      "Geconsolideerde versie van 01/06/2015": "ferrometaalvijlsel en -krullen"
    },
    {
      "__EMPTY": "12 01 02",
      "Geconsolideerde versie van 01/06/2015": "ferrometaalstof en -deeltjes"
    },
    {
      "__EMPTY": "12 01 03",
      "Geconsolideerde versie van 01/06/2015": "non-ferrometaalvijlsel en -krullen"
    },
    {
      "__EMPTY": "12 01 04",
      "Geconsolideerde versie van 01/06/2015": "non-ferrometaalstof en -deeltjes"
    },
    {
      "__EMPTY": "12 01 05",
      "Geconsolideerde versie van 01/06/2015": "kunststofschaafsel en -krullen"
    },
    {
      "__EMPTY": "12 01 06*",
      "Geconsolideerde versie van 01/06/2015": "halogeenhoudende minerale machineolie (exclusief emulsies en oplossingen)"
    },
    {
      "__EMPTY": "12 01 07*",
      "Geconsolideerde versie van 01/06/2015": "halogeenvrije minerale machineolie (exclusief emulsies en oplossingen)"
    },
    {
      "__EMPTY": "12 01 08*",
      "Geconsolideerde versie van 01/06/2015": "halogeenhoudende emulsies en oplossingen voor machinale bewerking"
    },
    {
      "__EMPTY": "12 01 09*",
      "Geconsolideerde versie van 01/06/2015": "halogeenvrije emulsies en oplossingen voor machinale bewerking"
    },
    {
      "__EMPTY": "12 01 10*",
      "Geconsolideerde versie van 01/06/2015": "synthetische machineolie"
    },
    {
      "__EMPTY": "12 01 12*",
      "Geconsolideerde versie van 01/06/2015": "afgewerkte wassen en vetten"
    },
    {
      "__EMPTY": "12 01 13",
      "Geconsolideerde versie van 01/06/2015": "lasafval"
    },
    {
      "__EMPTY": "12 01 14*",
      "Geconsolideerde versie van 01/06/2015": "slib van machinale bewerking dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "12 01 15",
      "Geconsolideerde versie van 01/06/2015": "niet onder 12 01 14 vallend slib van machinale bewerking"
    },
    {
      "__EMPTY": "12 01 16*",
      "Geconsolideerde versie van 01/06/2015": "afval van gritstralen dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "12 01 17",
      "Geconsolideerde versie van 01/06/2015": "niet onder 12 01 16 vallend afval van gritstralen"
    },
    {
      "__EMPTY": "12 01 18*",
      "Geconsolideerde versie van 01/06/2015": "oliehoudend metaalslib (slib van slijpen, wetten en leppen)"
    },
    {
      "__EMPTY": "12 01 19*",
      "Geconsolideerde versie van 01/06/2015": "biologisch gemakkelijk afbreekbare machineolie"
    },
    {
      "__EMPTY": "12 01 20*",
      "Geconsolideerde versie van 01/06/2015": "afgewerkt slijpgereedschap en slijpmateriaal die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "12 01 21",
      "Geconsolideerde versie van 01/06/2015": "niet onder 12 01 20 vallend afgewerkt slijpgereedschap en slijpmateriaal"
    },
    {
      "__EMPTY": "12 01 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "12 03 01*",
      "Geconsolideerde versie van 01/06/2015": "waterige wasvloeistoffen"
    },
    {
      "__EMPTY": "12 03 02*",
      "Geconsolideerde versie van 01/06/2015": "afval van stoomontvetting"
    },
    {
      "__EMPTY": "13 01 01*",
      "Geconsolideerde versie van 01/06/2015": "hydraulische olie die pcb's bevat"
    },
    {
      "__EMPTY": "13 01 04*",
      "Geconsolideerde versie van 01/06/2015": "gechloreerde emulsies"
    },
    {
      "__EMPTY": "13 01 05*",
      "Geconsolideerde versie van 01/06/2015": "niet-gechloreerde emulsies"
    },
    {
      "__EMPTY": "13 01 09*",
      "Geconsolideerde versie van 01/06/2015": "gechloreerde minerale hydraulische olie"
    },
    {
      "__EMPTY": "13 01 10*",
      "Geconsolideerde versie van 01/06/2015": "niet-gechloreerde minerale hydraulische olie"
    },
    {
      "__EMPTY": "13 01 11*",
      "Geconsolideerde versie van 01/06/2015": "synthetische hydraulische olie"
    },
    {
      "__EMPTY": "13 01 12*",
      "Geconsolideerde versie van 01/06/2015": "biologisch gemakkelijk afbreekbare hydraulische olie"
    },
    {
      "__EMPTY": "13 01 13*",
      "Geconsolideerde versie van 01/06/2015": "overige hydraulische olie"
    },
    {
      "__EMPTY": "13 02 04*",
      "Geconsolideerde versie van 01/06/2015": "gechloreerde minerale motor-, transmissie- en smeerolie"
    },
    {
      "__EMPTY": "13 02 05*",
      "Geconsolideerde versie van 01/06/2015": "niet-gechloreerde minerale motor-, transmissie- en smeerolie"
    },
    {
      "__EMPTY": "13 02 06*",
      "Geconsolideerde versie van 01/06/2015": "synthetische motor-, transmissie- en smeerolie"
    },
    {
      "__EMPTY": "13 02 07*",
      "Geconsolideerde versie van 01/06/2015": "biologisch gemakkelijk afbreekbare motor-, transmissie- en smeerolie"
    },
    {
      "__EMPTY": "13 02 08*",
      "Geconsolideerde versie van 01/06/2015": "overige motor-, transmissie- en smeerolie"
    },
    {
      "__EMPTY": "13 03 01*",
      "Geconsolideerde versie van 01/06/2015": "olie voor isolatie en warmteoverdracht die pcb's bevat"
    },
    {
      "__EMPTY": "13 03 06*",
      "Geconsolideerde versie van 01/06/2015": "niet onder 13 03 01 vallende gechloreerde minerale olie voor isolatie en warmteoverdracht"
    },
    {
      "__EMPTY": "13 03 07*",
      "Geconsolideerde versie van 01/06/2015": "niet-gechloreerde minerale olie voor isolatie en warmteoverdracht"
    },
    {
      "__EMPTY": "13 03 08*",
      "Geconsolideerde versie van 01/06/2015": "synthetische olie voor isolatie en warmteoverdracht"
    },
    {
      "__EMPTY": "13 03 09*",
      "Geconsolideerde versie van 01/06/2015": "biologisch gemakkelijk afbreekbare olie voor isolatie en warmteoverdracht"
    },
    {
      "__EMPTY": "13 03 10*",
      "Geconsolideerde versie van 01/06/2015": "overige olie voor isolatie en warmteoverdracht"
    },
    {
      "__EMPTY": "13 04 01*",
      "Geconsolideerde versie van 01/06/2015": "bilge-olie van de binnenvaart"
    },
    {
      "__EMPTY": "13 04 02*",
      "Geconsolideerde versie van 01/06/2015": "bilge-olie uit de kadeafvoer"
    },
    {
      "__EMPTY": "13 04 03*",
      "Geconsolideerde versie van 01/06/2015": "bilge-olie van de overige scheepvaart"
    },
    {
      "__EMPTY": "13 05 01*",
      "Geconsolideerde versie van 01/06/2015": "vaste stoffen uit zandvangers en olie/waterscheiders"
    },
    {
      "__EMPTY": "13 05 02*",
      "Geconsolideerde versie van 01/06/2015": "slib uit olie/waterscheiders"
    },
    {
      "__EMPTY": "13 05 03*",
      "Geconsolideerde versie van 01/06/2015": "opvangerslib"
    },
    {
      "__EMPTY": "13 05 06*",
      "Geconsolideerde versie van 01/06/2015": "olie uit olie/waterscheiders"
    },
    {
      "__EMPTY": "13 05 07*",
      "Geconsolideerde versie van 01/06/2015": "met olie verontreinigd water uit olie/waterscheiders"
    },
    {
      "__EMPTY": "13 05 08*",
      "Geconsolideerde versie van 01/06/2015": "afvalmengsels uit zandvangers en olie/waterscheiders"
    },
    {
      "__EMPTY": "13 07 01*",
      "Geconsolideerde versie van 01/06/2015": "stookolie en dieselolie"
    },
    {
      "__EMPTY": "13 07 02*",
      "Geconsolideerde versie van 01/06/2015": "Benzine"
    },
    {
      "__EMPTY": "13 07 03*",
      "Geconsolideerde versie van 01/06/2015": "overige brandstoffen (inclusief mengsels)"
    },
    {
      "__EMPTY": "13 08 01*",
      "Geconsolideerde versie van 01/06/2015": "ontzoutingsslib en -emulsies"
    },
    {
      "__EMPTY": "13 08 02*",
      "Geconsolideerde versie van 01/06/2015": "overige emulsies"
    },
    {
      "__EMPTY": "13 08 99*",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "14 06 01*",
      "Geconsolideerde versie van 01/06/2015": "chloorfluorkoolstoffen, hcfk's, hfk's"
    },
    {
      "__EMPTY": "14 06 02*",
      "Geconsolideerde versie van 01/06/2015": "overige gehalogeneerde oplosmiddelen en mengsels van oplosmiddelen"
    },
    {
      "__EMPTY": "14 06 03*",
      "Geconsolideerde versie van 01/06/2015": "overige oplosmiddelen en mengsels van oplosmiddelen"
    },
    {
      "__EMPTY": "14 06 04*",
      "Geconsolideerde versie van 01/06/2015": "slib of vast afval dat gehalogeneerde oplosmiddelen bevat"
    },
    {
      "__EMPTY": "14 06 05*",
      "Geconsolideerde versie van 01/06/2015": "slib of vast afval dat andere oplosmiddelen bevat"
    },
    {
      "__EMPTY": "15 01 01",
      "Geconsolideerde versie van 01/06/2015": "papieren en kartonnen verpakking"
    },
    {
      "__EMPTY": "15 01 02",
      "Geconsolideerde versie van 01/06/2015": "kunststofverpakking"
    },
    {
      "__EMPTY": "15 01 03",
      "Geconsolideerde versie van 01/06/2015": "houten verpakking"
    },
    {
      "__EMPTY": "15 01 04",
      "Geconsolideerde versie van 01/06/2015": "metalen verpakking"
    },
    {
      "__EMPTY": "15 01 05",
      "Geconsolideerde versie van 01/06/2015": "composietverpakking"
    },
    {
      "__EMPTY": "15 01 06",
      "Geconsolideerde versie van 01/06/2015": "gemengde verpakking"
    },
    {
      "__EMPTY": "15 01 07",
      "Geconsolideerde versie van 01/06/2015": "glazen verpakking"
    },
    {
      "__EMPTY": "15 01 09",
      "Geconsolideerde versie van 01/06/2015": "textielen verpakking"
    },
    {
      "__EMPTY": "15 01 10*",
      "Geconsolideerde versie van 01/06/2015": "verpakking die resten van gevaarlijke stoffen bevat of daarmee is verontreinigd"
    },
    {
      "__EMPTY": "15 01 11*",
      "Geconsolideerde versie van 01/06/2015": "metalen verpakking die een gevaarlijk vaste poreuze matrix (bv. asbest) bevat, inclusief lege drukhouders"
    },
    {
      "__EMPTY": "15 02 02*",
      "Geconsolideerde versie van 01/06/2015": "absorbentia, filtermateriaal (inclusief niet elders genoemde oliefilters), poetsdoeken en beschermende kleding die met gevaarlijke stoffen zijn verontreinigd"
    },
    {
      "__EMPTY": "15 02 03",
      "Geconsolideerde versie van 01/06/2015": "niet onder 15 02 02 vallende absorbentia, filtermateriaal, poetsdoeken en beschermende kleding"
    },
    {
      "__EMPTY": "16 01 03",
      "Geconsolideerde versie van 01/06/2015": "afgedankte banden"
    },
    {
      "__EMPTY": "16 01 04*",
      "Geconsolideerde versie van 01/06/2015": "afgedankte voertuigen"
    },
    {
      "__EMPTY": "16 01 06",
      "Geconsolideerde versie van 01/06/2015": "afgedankte voertuigen die noch vloeistoffen, noch andere gevaarlijke onderdelen bevatten"
    },
    {
      "__EMPTY": "16 01 07*",
      "Geconsolideerde versie van 01/06/2015": "oliefilters"
    },
    {
      "__EMPTY": "16 01 08*",
      "Geconsolideerde versie van 01/06/2015": "onderdelen die kwik bevatten"
    },
    {
      "__EMPTY": "16 01 09*",
      "Geconsolideerde versie van 01/06/2015": "onderdelen die pcb's bevatten"
    },
    {
      "__EMPTY": "16 01 10*",
      "Geconsolideerde versie van 01/06/2015": "explosieve onderdelen (bv. airbags)"
    },
    {
      "__EMPTY": "16 01 11*",
      "Geconsolideerde versie van 01/06/2015": "remblokken die asbest bevatten"
    },
    {
      "__EMPTY": "16 01 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 16 01 11 vallende remblokken"
    },
    {
      "__EMPTY": "16 01 13*",
      "Geconsolideerde versie van 01/06/2015": "remvloeistoffen"
    },
    {
      "__EMPTY": "16 01 14*",
      "Geconsolideerde versie van 01/06/2015": "antivriesvloeistoffen die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "16 01 15",
      "Geconsolideerde versie van 01/06/2015": "niet onder 16 01 14 vallende antivriesvloeistoffen"
    },
    {
      "__EMPTY": "16 01 16",
      "Geconsolideerde versie van 01/06/2015": "tanks voor vloeibaar gas"
    },
    {
      "__EMPTY": "16 01 17",
      "Geconsolideerde versie van 01/06/2015": "ferrometalen"
    },
    {
      "__EMPTY": "16 01 18",
      "Geconsolideerde versie van 01/06/2015": "non-ferrometalen"
    },
    {
      "__EMPTY": "16 01 19",
      "Geconsolideerde versie van 01/06/2015": "kunststoffen"
    },
    {
      "__EMPTY": "16 01 20",
      "Geconsolideerde versie van 01/06/2015": "glas"
    },
    {
      "__EMPTY": "16 01 21*",
      "Geconsolideerde versie van 01/06/2015": "niet onder 16 01 07 tot en met 16 01 11 alsmede 16 01 13 en 16 01 14 vallende gevaarlijke onderdelen"
    },
    {
      "__EMPTY": "16 01 22",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemde onderdelen"
    },
    {
      "__EMPTY": "16 01 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "16 02 09*",
      "Geconsolideerde versie van 01/06/2015": "transformatoren en condensatoren die pcb's bevatten"
    },
    {
      "__EMPTY": "16 02 10*",
      "Geconsolideerde versie van 01/06/2015": "niet onder 16 02 09 vallende afgedankte apparatuur die pcb's bevat of daarmee verontreinigd is"
    },
    {
      "__EMPTY": "16 02 11*",
      "Geconsolideerde versie van 01/06/2015": "afgedankte apparatuur die chloorfluorkoolstoffen, hcfk's en/of hfk's bevat"
    },
    {
      "__EMPTY": "16 02 12*",
      "Geconsolideerde versie van 01/06/2015": "afgedankte apparatuur die vrije asbestvezels bevat"
    },
    {
      "__EMPTY": "16 02 13*",
      "Geconsolideerde versie van 01/06/2015": "niet onder 16 02 09 tot en met 16 02 12 vallende afgedankte apparatuur die gevaarlijke onderdelen (1) bevat"
    },
    {
      "__EMPTY": "16 02 14",
      "Geconsolideerde versie van 01/06/2015": "niet onder 16 02 09 tot en met 16 02 13 vallende afgedankte apparatuur"
    },
    {
      "__EMPTY": "16 02 15*",
      "Geconsolideerde versie van 01/06/2015": "uit afgedankte apparatuur verwijderde gevaarlijke onderdelen"
    },
    {
      "__EMPTY": "16 02 16",
      "Geconsolideerde versie van 01/06/2015": "niet onder 16 02 15 vallende uit afgedankte apparatuur verwijderde onderdelen"
    },
    {
      "__EMPTY": "16 03 03*",
      "Geconsolideerde versie van 01/06/2015": "anorganisch afval dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "16 03 04",
      "Geconsolideerde versie van 01/06/2015": "niet onder 16 03 03 vallend anorganisch afval"
    },
    {
      "__EMPTY": "16 03 05*",
      "Geconsolideerde versie van 01/06/2015": "organisch afval dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "16 03 06",
      "Geconsolideerde versie van 01/06/2015": "niet onder 16 03 05 vallend organisch afval"
    },
    {
      "__EMPTY": "16 03 07*",
      "Geconsolideerde versie van 01/06/2015": "metallisch kwik"
    },
    {
      "__EMPTY": "16 04 01*",
      "Geconsolideerde versie van 01/06/2015": "afvalmunitie"
    },
    {
      "__EMPTY": "16 04 02*",
      "Geconsolideerde versie van 01/06/2015": "vuurwerkafval"
    },
    {
      "__EMPTY": "16 04 03*",
      "Geconsolideerde versie van 01/06/2015": "overig explosief afval"
    },
    {
      "__EMPTY": "16 05 04*",
      "Geconsolideerde versie van 01/06/2015": "gassen in drukhouders (inclusief halonen) die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "16 05 05",
      "Geconsolideerde versie van 01/06/2015": "niet onder 16 05 04 vallende gassen in drukhouders"
    },
    {
      "__EMPTY": "16 05 06*",
      "Geconsolideerde versie van 01/06/2015": "labchemicaliën die uit gevaarlijke stoffen bestaan of deze bevatten, inclusief mengsels van labchemicaliën"
    },
    {
      "__EMPTY": "16 05 07*",
      "Geconsolideerde versie van 01/06/2015": "afgedankte anorganische chemicaliën die uit gevaarlijke stoffen bestaan of deze bevatten"
    },
    {
      "__EMPTY": "16 05 08*",
      "Geconsolideerde versie van 01/06/2015": "afgedankte organische chemicaliën die uit gevaarlijke stoffen bestaan of deze bevatten"
    },
    {
      "__EMPTY": "16 05 09",
      "Geconsolideerde versie van 01/06/2015": "niet onder 16 05 06, 16 05 07 of 16 05 08 vallende afgedankte chemicaliën"
    },
    {
      "__EMPTY": "16 06 01*",
      "Geconsolideerde versie van 01/06/2015": "loodaccu's"
    },
    {
      "__EMPTY": "16 06 02*",
      "Geconsolideerde versie van 01/06/2015": "NiCd-batterijen"
    },
    {
      "__EMPTY": "16 06 03*",
      "Geconsolideerde versie van 01/06/2015": "kwikhoudende batterijen"
    },
    {
      "__EMPTY": "16 06 04",
      "Geconsolideerde versie van 01/06/2015": "alkalibatterijen (exclusief 16 06 03)"
    },
    {
      "__EMPTY": "16 06 05",
      "Geconsolideerde versie van 01/06/2015": "overige batterijen en accu's"
    },
    {
      "__EMPTY": "16 06 06*",
      "Geconsolideerde versie van 01/06/2015": "gescheiden ingezamelde elektrolyt uit batterijen en accu's"
    },
    {
      "__EMPTY": "16 07 08*",
      "Geconsolideerde versie van 01/06/2015": "afval dat olie bevat"
    },
    {
      "__EMPTY": "16 07 09*",
      "Geconsolideerde versie van 01/06/2015": "afval dat andere gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "16 07 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "16 08 01",
      "Geconsolideerde versie van 01/06/2015": "afgewerkte katalysatoren die goud, zilver, rhenium, rhodium, palladium, iridium of platina bevatten (exclusief 16 08 07)"
    },
    {
      "__EMPTY": "16 08 02*",
      "Geconsolideerde versie van 01/06/2015": "afgewerkte katalysatoren die gevaarlijke overgangsmetalen of gevaarlijke verbindingen van overgangsmetalen bevatten"
    },
    {
      "__EMPTY": "16 08 03",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemde afgewerkte katalysatoren die overgangsmetalen of verbindingen van overgangsmetalen bevatten"
    },
    {
      "__EMPTY": "16 08 04",
      "Geconsolideerde versie van 01/06/2015": "afgewerkte katalysatoren voor wervelbedkrakers (exclusief 16 08 07)"
    },
    {
      "__EMPTY": "16 08 05*",
      "Geconsolideerde versie van 01/06/2015": "afgewerkte katalysatoren die fosforzuur bevatten"
    },
    {
      "__EMPTY": "16 08 06*",
      "Geconsolideerde versie van 01/06/2015": "afgewerkte vloeistoffen die als katalysator zijn gebruikt"
    },
    {
      "__EMPTY": "16 08 07*",
      "Geconsolideerde versie van 01/06/2015": "afgewerkte katalysatoren die met gevaarlijke stoffen zijn verontreinigd"
    },
    {
      "__EMPTY": "16 09 01*",
      "Geconsolideerde versie van 01/06/2015": "permanganaten, bv. kaliumpermanganaat"
    },
    {
      "__EMPTY": "16 09 02*",
      "Geconsolideerde versie van 01/06/2015": "chromaten, bv. kaliumchromaat, kalium- of natriumdichromaat"
    },
    {
      "__EMPTY": "16 09 03*",
      "Geconsolideerde versie van 01/06/2015": "peroxiden, bv. waterstofperoxide"
    },
    {
      "__EMPTY": "16 09 04*",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemde oxiderende stoffen"
    },
    {
      "__EMPTY": "16 10 01*",
      "Geconsolideerde versie van 01/06/2015": "waterig vloeibaar afval dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "16 10 02",
      "Geconsolideerde versie van 01/06/2015": "niet onder 16 10 01 vallend waterig vloeibaar afval"
    },
    {
      "__EMPTY": "16 10 03*",
      "Geconsolideerde versie van 01/06/2015": "waterige concentraten die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "16 10 04",
      "Geconsolideerde versie van 01/06/2015": "niet onder 16 10 03 vallende waterige concentraten"
    },
    {
      "__EMPTY": "16 11 01*",
      "Geconsolideerde versie van 01/06/2015": "koolstofhoudend ovenpuin van metallurgische processen dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "16 11 02",
      "Geconsolideerde versie van 01/06/2015": "niet onder 16 11 01 vallend koolstofhoudend ovenpuin van metallurgische processen"
    },
    {
      "__EMPTY": "16 11 03*",
      "Geconsolideerde versie van 01/06/2015": "overig ovenpuin van metallurgische processen dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "16 11 04",
      "Geconsolideerde versie van 01/06/2015": "overig, niet onder 16 11 03 vallend ovenpuin van metallurgische processen"
    },
    {
      "__EMPTY": "16 11 05*",
      "Geconsolideerde versie van 01/06/2015": "ovenpuin van niet-metallurgische processen dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "16 11 06",
      "Geconsolideerde versie van 01/06/2015": "niet onder 16 11 05 vallend ovenpuin van niet-metallurgische processen"
    },
    {
      "__EMPTY": "17 01 01",
      "Geconsolideerde versie van 01/06/2015": "beton"
    },
    {
      "__EMPTY": "17 01 02",
      "Geconsolideerde versie van 01/06/2015": "stenen"
    },
    {
      "__EMPTY": "17 01 03",
      "Geconsolideerde versie van 01/06/2015": "tegels en keramische producten"
    },
    {
      "__EMPTY": "17 01 06*",
      "Geconsolideerde versie van 01/06/2015": "mengsels van beton, stenen, tegels of keramische producten, of afzonderlijke fracties daarvan, die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "17 01 07",
      "Geconsolideerde versie van 01/06/2015": "niet onder 17 01 06 vallende mengsels van beton, stenen, tegels of keramische producten"
    },
    {
      "__EMPTY": "17 02 01",
      "Geconsolideerde versie van 01/06/2015": "hout"
    },
    {
      "__EMPTY": "17 02 02",
      "Geconsolideerde versie van 01/06/2015": "glas"
    },
    {
      "__EMPTY": "17 02 03",
      "Geconsolideerde versie van 01/06/2015": "kunststof"
    },
    {
      "__EMPTY": "17 02 04*",
      "Geconsolideerde versie van 01/06/2015": "glas, kunststof en hout die gevaarlijke stoffen bevatten of daarmee verontreinigd zijn"
    },
    {
      "__EMPTY": "17 03 01*",
      "Geconsolideerde versie van 01/06/2015": "bitumineuze mengsels die koolteer bevatten"
    },
    {
      "__EMPTY": "17 03 02",
      "Geconsolideerde versie van 01/06/2015": "niet onder 17 03 01 vallende bitumineuze mengsels"
    },
    {
      "__EMPTY": "17 03 03*",
      "Geconsolideerde versie van 01/06/2015": "koolteer en met teer behandelde producten"
    },
    {
      "__EMPTY": "17 04 01",
      "Geconsolideerde versie van 01/06/2015": "koper, brons en messing"
    },
    {
      "__EMPTY": "17 04 02",
      "Geconsolideerde versie van 01/06/2015": "aluminium"
    },
    {
      "__EMPTY": "17 04 03",
      "Geconsolideerde versie van 01/06/2015": "lood"
    },
    {
      "__EMPTY": "17 04 04",
      "Geconsolideerde versie van 01/06/2015": "zink"
    },
    {
      "__EMPTY": "17 04 05",
      "Geconsolideerde versie van 01/06/2015": "ijzer en staal"
    },
    {
      "__EMPTY": "17 04 06",
      "Geconsolideerde versie van 01/06/2015": "tin"
    },
    {
      "__EMPTY": "17 04 07",
      "Geconsolideerde versie van 01/06/2015": "gemengde metalen"
    },
    {
      "__EMPTY": "17 04 09*",
      "Geconsolideerde versie van 01/06/2015": "metaalafval dat met gevaarlijke stoffen is verontreinigd"
    },
    {
      "__EMPTY": "17 04 10*",
      "Geconsolideerde versie van 01/06/2015": "kabels die olie, koolteer of andere gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "17 04 11",
      "Geconsolideerde versie van 01/06/2015": "niet onder 17 04 10 vallende kabels"
    },
    {
      "__EMPTY": "17 05 03*",
      "Geconsolideerde versie van 01/06/2015": "grond en stenen die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "17 05 04",
      "Geconsolideerde versie van 01/06/2015": "niet onder 17 05 03 vallende grond en stenen"
    },
    {
      "__EMPTY": "17 05 05*",
      "Geconsolideerde versie van 01/06/2015": "baggerspecie die gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "17 05 06",
      "Geconsolideerde versie van 01/06/2015": "niet onder 17 05 05 vallende baggerspecie"
    },
    {
      "__EMPTY": "17 05 07*",
      "Geconsolideerde versie van 01/06/2015": "spoorwegballast die gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "17 05 08",
      "Geconsolideerde versie van 01/06/2015": "niet onder 17 05 07 vallende spoorwegballast"
    },
    {
      "__EMPTY": "17 06 01*",
      "Geconsolideerde versie van 01/06/2015": "asbesthoudend isolatiemateriaal"
    },
    {
      "__EMPTY": "17 06 03*",
      "Geconsolideerde versie van 01/06/2015": "overig isolatiemateriaal dat uit gevaarlijke stoffen bestaat of dergelijke stoffen bevat"
    },
    {
      "__EMPTY": "17 06 04",
      "Geconsolideerde versie van 01/06/2015": "niet onder 17 06 01 en 17 06 03 vallend isolatiemateriaal"
    },
    {
      "__EMPTY": "17 06 05*",
      "Geconsolideerde versie van 01/06/2015": "asbesthoudende bouwmaterialen"
    },
    {
      "__EMPTY": "17 08 01*",
      "Geconsolideerde versie van 01/06/2015": "gipshoudend bouwmateriaal dat met gevaarlijke stoffen is verontreinigd"
    },
    {
      "__EMPTY": "17 08 02",
      "Geconsolideerde versie van 01/06/2015": "niet onder 17 08 01 vallend gipshoudend bouwmateriaal"
    },
    {
      "__EMPTY": "17 09 01*",
      "Geconsolideerde versie van 01/06/2015": "bouw- en sloopafval dat kwik bevat"
    },
    {
      "__EMPTY": "17 09 02*",
      "Geconsolideerde versie van 01/06/2015": "bouw- en sloopafval dat pcb's bevat (bv. pcb-houdende kit, vloerbedekkingen waarin pcb-houdende hars is verwerkt, isolerende beglazing met pcb-houdende afdichting, pcb-houdende condensatoren)"
    },
    {
      "__EMPTY": "17 09 03*",
      "Geconsolideerde versie van 01/06/2015": "overig bouw- en sloopafval (inclusief gemengd afval) dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "17 09 04",
      "Geconsolideerde versie van 01/06/2015": "niet onder 17 09 01, 17 09 02 en 17 09 03 vallend gemengd bouw- en sloopafval"
    },
    {
      "__EMPTY": "18 01 01",
      "Geconsolideerde versie van 01/06/2015": "scherpe voorwerpen (exclusief 18 01 03)"
    },
    {
      "__EMPTY": "18 01 02",
      "Geconsolideerde versie van 01/06/2015": "lichaamsdelen en organen, inclusief bloedzakjes en geconserveerd bloed (exclusief 18 01 03)"
    },
    {
      "__EMPTY": "18 01 03*",
      "Geconsolideerde versie van 01/06/2015": "afval waarvan de inzameling en verwijdering zijn onderworpen aan speciale richtlijnen teneinde infectie te voorkomen"
    },
    {
      "__EMPTY": "18 01 04",
      "Geconsolideerde versie van 01/06/2015": "afval waarvan de inzameling en verwijdering niet zijn onderworpen aan speciale richtlijnen teneinde infectie te voorkomen (bv. verband, gipsverband, linnengoed, wegwerpkleding, luiers)"
    },
    {
      "__EMPTY": "18 01 06*",
      "Geconsolideerde versie van 01/06/2015": "chemicaliën die uit gevaarlijke stoffen bestaan of deze bevatten"
    },
    {
      "__EMPTY": "18 01 07",
      "Geconsolideerde versie van 01/06/2015": "niet onder 18 01 06 vallende chemicaliën"
    },
    {
      "__EMPTY": "18 01 08*",
      "Geconsolideerde versie van 01/06/2015": "cytotoxische en cytostatische geneesmiddelen"
    },
    {
      "__EMPTY": "18 01 09",
      "Geconsolideerde versie van 01/06/2015": "niet onder 18 01 08 vallende geneesmiddelen"
    },
    {
      "__EMPTY": "18 01 10*",
      "Geconsolideerde versie van 01/06/2015": "amalgaamafval uit de tandheelkunde"
    },
    {
      "__EMPTY": "18 02 01",
      "Geconsolideerde versie van 01/06/2015": "scherpe voorwerpen (exclusief 18 02 02)"
    },
    {
      "__EMPTY": "18 02 02*",
      "Geconsolideerde versie van 01/06/2015": "afval waarvan de inzameling en verwijdering zijn onderworpen aan speciale richtlijnen teneinde infectie te voorkomen"
    },
    {
      "__EMPTY": "18 02 03",
      "Geconsolideerde versie van 01/06/2015": "afval waarvan de inzameling en verwijdering niet zijn onderworpen aan speciale richtlijnen teneinde infectie te voorkomen"
    },
    {
      "__EMPTY": "18 02 05*",
      "Geconsolideerde versie van 01/06/2015": "chemicaliën die uit gevaarlijke stoffen bestaan of deze bevatten"
    },
    {
      "__EMPTY": "18 02 06",
      "Geconsolideerde versie van 01/06/2015": "niet onder 18 02 05 vallende chemicaliën"
    },
    {
      "__EMPTY": "18 02 07*",
      "Geconsolideerde versie van 01/06/2015": "cytotoxische en cytostatische geneesmiddelen"
    },
    {
      "__EMPTY": "18 02 08",
      "Geconsolideerde versie van 01/06/2015": "niet onder 18 02 07 vallende geneesmiddelen"
    },
    {
      "__EMPTY": "19 01 02",
      "Geconsolideerde versie van 01/06/2015": "uit bodemas verwijderde ferromaterialen"
    },
    {
      "__EMPTY": "19 01 05*",
      "Geconsolideerde versie van 01/06/2015": "filterkoek van gasreiniging"
    },
    {
      "__EMPTY": "19 01 06*",
      "Geconsolideerde versie van 01/06/2015": "waterig vloeibaar afval van gasreiniging en ander waterig vloeibaar afval"
    },
    {
      "__EMPTY": "19 01 07*",
      "Geconsolideerde versie van 01/06/2015": "vast afval van gasreiniging"
    },
    {
      "__EMPTY": "19 01 10*",
      "Geconsolideerde versie van 01/06/2015": "afgewerkte actieve kool van rookgasreiniging"
    },
    {
      "__EMPTY": "19 01 11*",
      "Geconsolideerde versie van 01/06/2015": "bodemas en slakken die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "19 01 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 01 11 vallende bodemas en slakken"
    },
    {
      "__EMPTY": "19 01 13*",
      "Geconsolideerde versie van 01/06/2015": "vliegas dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "19 01 14",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 01 13 vallende vliegas"
    },
    {
      "__EMPTY": "19 01 15*",
      "Geconsolideerde versie van 01/06/2015": "ketelas die gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "19 01 16",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 01 15 vallende ketelas"
    },
    {
      "__EMPTY": "19 01 17*",
      "Geconsolideerde versie van 01/06/2015": "afval van pyrolyse dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "19 01 18",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 01 17 vallend afval van pyrolyse"
    },
    {
      "__EMPTY": "19 01 19",
      "Geconsolideerde versie van 01/06/2015": "wervelbedzand"
    },
    {
      "__EMPTY": "19 01 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "19 02 03",
      "Geconsolideerde versie van 01/06/2015": "voorgemengd afval dat uitsluitend bestaat uit niet-gevaarlijke afvalstoffen"
    },
    {
      "__EMPTY": "19 02 04*",
      "Geconsolideerde versie van 01/06/2015": "voorgemengd afval dat ten minste één gevaarlijke afvalstof bevat"
    },
    {
      "__EMPTY": "19 02 05*",
      "Geconsolideerde versie van 01/06/2015": "slib van fysisch-chemische behandeling dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "19 02 06",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 02 05 vallend slib van fysisch-chemische behandeling"
    },
    {
      "__EMPTY": "19 02 07*",
      "Geconsolideerde versie van 01/06/2015": "door afscheiding verkregen oliën en concentraten"
    },
    {
      "__EMPTY": "19 02 08*",
      "Geconsolideerde versie van 01/06/2015": "vloeibaar brandbaar afval dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "19 02 09*",
      "Geconsolideerde versie van 01/06/2015": "vast brandbaar afval dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "19 02 10",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 02 08 en 19 02 09 vallend brandbaar afval"
    },
    {
      "__EMPTY": "19 02 11*",
      "Geconsolideerde versie van 01/06/2015": "overig afval dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "19 02 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "19 03 04*",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 03 08 vallend als gevaarlijk ingedeeld, gedeeltelijk gestabiliseerd afval"
    },
    {
      "__EMPTY": "19 03 05",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 03 04 vallend gestabiliseerd afval"
    },
    {
      "__EMPTY": "19 03 06*",
      "Geconsolideerde versie van 01/06/2015": "als gevaarlijk ingedeeld afval dat verhard is"
    },
    {
      "__EMPTY": "19 03 07",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 03 06 vallend verhard afval"
    },
    {
      "__EMPTY": "19 03 08*",
      "Geconsolideerde versie van 01/06/2015": "gedeeltelijk gestabiliseerd kwik"
    },
    {
      "__EMPTY": "19 04 01",
      "Geconsolideerde versie van 01/06/2015": "verglaasd afval"
    },
    {
      "__EMPTY": "19 04 02*",
      "Geconsolideerde versie van 01/06/2015": "vliegas en ander rookgasreinigingsafval"
    },
    {
      "__EMPTY": "19 04 03*",
      "Geconsolideerde versie van 01/06/2015": "niet-verglaasde vaste fase"
    },
    {
      "__EMPTY": "19 04 04",
      "Geconsolideerde versie van 01/06/2015": "waterig vloeibaar afval van het ontlaten van verglaasd afval"
    },
    {
      "__EMPTY": "19 05 01",
      "Geconsolideerde versie van 01/06/2015": "niet-gecomposteerde fractie van huishoudelijk en soortgelijk afval"
    },
    {
      "__EMPTY": "19 05 02",
      "Geconsolideerde versie van 01/06/2015": "niet-gecomposteerde fractie van dierlijk en plantaardig afval"
    },
    {
      "__EMPTY": "19 05 03",
      "Geconsolideerde versie van 01/06/2015": "afgekeurde compost"
    },
    {
      "__EMPTY": "19 05 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "19 06 03",
      "Geconsolideerde versie van 01/06/2015": "vloeistof verkregen bij de anaerobe behandeling van stedelijk afval"
    },
    {
      "__EMPTY": "19 06 04",
      "Geconsolideerde versie van 01/06/2015": "digestaat van de anaerobe behandeling van stedelijk afval"
    },
    {
      "__EMPTY": "19 06 05",
      "Geconsolideerde versie van 01/06/2015": "vloeistof verkregen bij de anaerobe behandeling van dierlijk en plantaardig afval"
    },
    {
      "__EMPTY": "19 06 06",
      "Geconsolideerde versie van 01/06/2015": "digestaat van de anaerobe behandeling van dierlijk en plantaardig afval"
    },
    {
      "__EMPTY": "19 06 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "19 07 02*",
      "Geconsolideerde versie van 01/06/2015": "percolatiewater van stortplaatsen dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "19 07 03",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 07 02 vallend percolatiewater van stortplaatsen"
    },
    {
      "__EMPTY": "19 08 01",
      "Geconsolideerde versie van 01/06/2015": "roostergoed"
    },
    {
      "__EMPTY": "19 08 02",
      "Geconsolideerde versie van 01/06/2015": "afval van zandvang"
    },
    {
      "__EMPTY": "19 08 05",
      "Geconsolideerde versie van 01/06/2015": "slib van de behandeling van stedelijk afvalwater"
    },
    {
      "__EMPTY": "19 08 06*",
      "Geconsolideerde versie van 01/06/2015": "verzadigde of afgewerkte ionenwisselaarharsen"
    },
    {
      "__EMPTY": "19 08 07*",
      "Geconsolideerde versie van 01/06/2015": "oplossingen en slib van de regeneratie van ionenwisselaars"
    },
    {
      "__EMPTY": "19 08 08*",
      "Geconsolideerde versie van 01/06/2015": "afval van membraansystemen dat zware metalen bevat"
    },
    {
      "__EMPTY": "19 08 09",
      "Geconsolideerde versie van 01/06/2015": "vet- en oliemengsels uit olie/waterscheiders die uitsluitend spijsolie en -vetten bevatten"
    },
    {
      "__EMPTY": "19 08 10*",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 08 09 vallende vet- en oliemengsels uit olie/waterscheiders"
    },
    {
      "__EMPTY": "19 08 11*",
      "Geconsolideerde versie van 01/06/2015": "slib van de biologische zuivering van industrieel afvalwater dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "19 08 12",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 08 11 vallend slib van de biologische zuivering van industrieel afvalwater"
    },
    {
      "__EMPTY": "19 08 13*",
      "Geconsolideerde versie van 01/06/2015": "slib van andere behandelingen van industrieel afvalwater dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "19 08 14",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 08 13 vallend slib van andere behandelingen van industrieel afvalwater"
    },
    {
      "__EMPTY": "19 08 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "19 09 01",
      "Geconsolideerde versie van 01/06/2015": "vast afval van primaire filtratie en roostergoed"
    },
    {
      "__EMPTY": "19 09 02",
      "Geconsolideerde versie van 01/06/2015": "waterzuiveringsslib"
    },
    {
      "__EMPTY": "19 09 03",
      "Geconsolideerde versie van 01/06/2015": "onthardingsslib"
    },
    {
      "__EMPTY": "19 09 04",
      "Geconsolideerde versie van 01/06/2015": "afgewerkte actieve kool"
    },
    {
      "__EMPTY": "19 09 05",
      "Geconsolideerde versie van 01/06/2015": "verzadigde of afgewerkte ionenwisselaarharsen"
    },
    {
      "__EMPTY": "19 09 06",
      "Geconsolideerde versie van 01/06/2015": "oplossingen en slib van de regeneratie van ionenwisselaars"
    },
    {
      "__EMPTY": "19 09 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "19 10 01",
      "Geconsolideerde versie van 01/06/2015": "ijzer- en staalafval"
    },
    {
      "__EMPTY": "19 10 02",
      "Geconsolideerde versie van 01/06/2015": "non-ferroafval"
    },
    {
      "__EMPTY": "19 10 03*",
      "Geconsolideerde versie van 01/06/2015": "lichte fractie en stof dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "19 10 04",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 10 03 vallende lichte fracties en stof"
    },
    {
      "__EMPTY": "19 10 05*",
      "Geconsolideerde versie van 01/06/2015": "andere fracties die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "19 10 06",
      "Geconsolideerde versie van 01/06/2015": "andere, niet onder 19 10 05 vallende fracties"
    },
    {
      "__EMPTY": "19 11 01*",
      "Geconsolideerde versie van 01/06/2015": "afgewerkte bleekaarde"
    },
    {
      "__EMPTY": "19 11 02*",
      "Geconsolideerde versie van 01/06/2015": "Zuurteer"
    },
    {
      "__EMPTY": "19 11 03*",
      "Geconsolideerde versie van 01/06/2015": "waterig vloeibaar afval"
    },
    {
      "__EMPTY": "19 11 04*",
      "Geconsolideerde versie van 01/06/2015": "afval van brandstofzuivering met behulp van basen"
    },
    {
      "__EMPTY": "19 11 05*",
      "Geconsolideerde versie van 01/06/2015": "slib van afvalwaterbehandeling ter plaatse dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "19 11 06",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 11 05 vallend slib van afvalwaterbehandeling ter plaatse"
    },
    {
      "__EMPTY": "19 11 07*",
      "Geconsolideerde versie van 01/06/2015": "afval van rookgasreiniging"
    },
    {
      "__EMPTY": "19 11 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd afval"
    },
    {
      "__EMPTY": "19 12 01",
      "Geconsolideerde versie van 01/06/2015": "papier en karton"
    },
    {
      "__EMPTY": "19 12 02",
      "Geconsolideerde versie van 01/06/2015": "ferrometalen"
    },
    {
      "__EMPTY": "19 12 03",
      "Geconsolideerde versie van 01/06/2015": "non-ferrometalen"
    },
    {
      "__EMPTY": "19 12 04",
      "Geconsolideerde versie van 01/06/2015": "kunststoffen en rubber"
    },
    {
      "__EMPTY": "19 12 05",
      "Geconsolideerde versie van 01/06/2015": "glas"
    },
    {
      "__EMPTY": "19 12 06*",
      "Geconsolideerde versie van 01/06/2015": "hout dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "19 12 07",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 12 06 vallend hout"
    },
    {
      "__EMPTY": "19 12 08",
      "Geconsolideerde versie van 01/06/2015": "textiel"
    },
    {
      "__EMPTY": "19 12 09",
      "Geconsolideerde versie van 01/06/2015": "minerale stoffen (bijvoorbeeld zand, steen)"
    },
    {
      "__EMPTY": "19 12 10",
      "Geconsolideerde versie van 01/06/2015": "brandbaar afval (RDF)"
    },
    {
      "__EMPTY": "19 12 11*",
      "Geconsolideerde versie van 01/06/2015": "overig afval (inclusief mengsels van materialen) van mechanische afvalverwerking dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "19 12 12",
      "Geconsolideerde versie van 01/06/2015": "overig, niet onder 19 12 11 vallend afval (inclusief mengsels van materialen) van mechanische afvalverwerking"
    },
    {
      "__EMPTY": "19 12 64",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 12 12 vallend, sorteer(zeef)residu van bedrijfsrestafval oorspronkelijk ingezameld als 20 03 01;"
    },
    {
      "__EMPTY": "19 12 65",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 12 12 vallend, sorteer(zeef)residu van bouw- en sloopafval oorspronkelijk ingezameld als 17 09 04;"
    },
    {
      "__EMPTY": "19 13 01*",
      "Geconsolideerde versie van 01/06/2015": "vast afval van bodemsanering dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "19 13 02",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 13 01 vallend vast afval van bodemsanering"
    },
    {
      "__EMPTY": "19 13 03*",
      "Geconsolideerde versie van 01/06/2015": "slib van bodemsanering dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "19 13 04",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 13 03 vallend slib van bodemsanering"
    },
    {
      "__EMPTY": "19 13 05*",
      "Geconsolideerde versie van 01/06/2015": "slib van grondwatersanering dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "19 13 06",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 13 05 vallend slib van grondwatersanering"
    },
    {
      "__EMPTY": "19 13 07*",
      "Geconsolideerde versie van 01/06/2015": "waterig vloeibaar afval en waterige concentraten van grondwatersanering die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "19 13 08",
      "Geconsolideerde versie van 01/06/2015": "niet onder 19 13 07 vallend waterig vloeibaar afval en waterige concentraten van grondwatersanering"
    },
    {
      "__EMPTY": "20 01 01",
      "Geconsolideerde versie van 01/06/2015": "papier en karton"
    },
    {
      "__EMPTY": "20 01 02",
      "Geconsolideerde versie van 01/06/2015": "glas"
    },
    {
      "__EMPTY": "20 01 08",
      "Geconsolideerde versie van 01/06/2015": "biologisch afbreekbaar keuken- en kantineafval"
    },
    {
      "__EMPTY": "20 01 10",
      "Geconsolideerde versie van 01/06/2015": "kleding"
    },
    {
      "__EMPTY": "20 01 11",
      "Geconsolideerde versie van 01/06/2015": "textiel"
    },
    {
      "__EMPTY": "20 01 13*",
      "Geconsolideerde versie van 01/06/2015": "oplosmiddelen"
    },
    {
      "__EMPTY": "20 01 14*",
      "Geconsolideerde versie van 01/06/2015": "zuren"
    },
    {
      "__EMPTY": "20 01 15*",
      "Geconsolideerde versie van 01/06/2015": "basisch afval"
    },
    {
      "__EMPTY": "20 01 17*",
      "Geconsolideerde versie van 01/06/2015": "fotochemicaliën"
    },
    {
      "__EMPTY": "20 01 19*",
      "Geconsolideerde versie van 01/06/2015": "pesticiden"
    },
    {
      "__EMPTY": "20 01 21*",
      "Geconsolideerde versie van 01/06/2015": "tl-buizen en ander kwikhoudend afval"
    },
    {
      "__EMPTY": "20 01 23*",
      "Geconsolideerde versie van 01/06/2015": "afgedankte apparatuur die chloorfluorkoolstoffen bevat"
    },
    {
      "__EMPTY": "20 01 25",
      "Geconsolideerde versie van 01/06/2015": "spijsolie en -vetten"
    },
    {
      "__EMPTY": "20 01 26*",
      "Geconsolideerde versie van 01/06/2015": "niet onder 20 01 25 vallende oliën en vetten"
    },
    {
      "__EMPTY": "20 01 27*",
      "Geconsolideerde versie van 01/06/2015": "verf, inkt, lijm en hars die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "20 01 28",
      "Geconsolideerde versie van 01/06/2015": "niet onder 20 01 27 vallende verf, inkt, lijm en hars"
    },
    {
      "__EMPTY": "20 01 29*",
      "Geconsolideerde versie van 01/06/2015": "detergenten die gevaarlijke stoffen bevatten"
    },
    {
      "__EMPTY": "20 01 30",
      "Geconsolideerde versie van 01/06/2015": "niet onder 20 01 29 vallende detergenten"
    },
    {
      "__EMPTY": "20 01 31*",
      "Geconsolideerde versie van 01/06/2015": "cytotoxische en cytostatische geneesmiddelen"
    },
    {
      "__EMPTY": "20 01 32",
      "Geconsolideerde versie van 01/06/2015": "niet onder 20 01 31 vallende geneesmiddelen"
    },
    {
      "__EMPTY": "20 01 33*",
      "Geconsolideerde versie van 01/06/2015": "onder 16 06 01, 16 06 02 of 16 06 03 vermelde batterijen en accu's alsmede ongesorteerde mengsels van batterijen en accu's die dergelijke batterijen en accu's bevatten"
    },
    {
      "__EMPTY": "20 01 34",
      "Geconsolideerde versie van 01/06/2015": "niet onder 20 01 33 vallende batterijen en accu's"
    },
    {
      "__EMPTY": "20 01 35*",
      "Geconsolideerde versie van 01/06/2015": "niet onder 20 01 21 en 20 01 23 vallende afgedankte elektrische en elektronische apparatuur die gevaarlijke onderdelen (1) bevat"
    },
    {
      "__EMPTY": "20 01 36",
      "Geconsolideerde versie van 01/06/2015": "niet onder 20 01 21, 20 01 23 en 20 01 35 vallende afgedankte elektrische en elektronische apparatuur"
    },
    {
      "__EMPTY": "20 01 37*",
      "Geconsolideerde versie van 01/06/2015": "hout dat gevaarlijke stoffen bevat"
    },
    {
      "__EMPTY": "20 01 38",
      "Geconsolideerde versie van 01/06/2015": "niet onder 20 01 37 vallend hout"
    },
    {
      "__EMPTY": "20 01 39",
      "Geconsolideerde versie van 01/06/2015": "kunststoffen"
    },
    {
      "__EMPTY": "20 01 40",
      "Geconsolideerde versie van 01/06/2015": "metalen"
    },
    {
      "__EMPTY": "20 01 41",
      "Geconsolideerde versie van 01/06/2015": "afval van het vegen van schoorstenen"
    },
    {
      "__EMPTY": "20 01 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemde fracties"
    },
    {
      "__EMPTY": "20 02 01",
      "Geconsolideerde versie van 01/06/2015": "biologisch afbreekbaar afval"
    },
    {
      "__EMPTY": "20 02 02",
      "Geconsolideerde versie van 01/06/2015": "grond en stenen"
    },
    {
      "__EMPTY": "20 02 03",
      "Geconsolideerde versie van 01/06/2015": "overig niet biologisch afbreekbaar afval"
    },
    {
      "__EMPTY": "20 03 01",
      "Geconsolideerde versie van 01/06/2015": "gemengd stedelijk afval"
    },
    {
      "__EMPTY": "20 03 02",
      "Geconsolideerde versie van 01/06/2015": "marktafval"
    },
    {
      "__EMPTY": "20 03 03",
      "Geconsolideerde versie van 01/06/2015": "veegvuil"
    },
    {
      "__EMPTY": "20 03 04",
      "Geconsolideerde versie van 01/06/2015": "slib van septische tanks"
    },
    {
      "__EMPTY": "20 03 06",
      "Geconsolideerde versie van 01/06/2015": "afval van het reinigen van riolen"
    },
    {
      "__EMPTY": "20 03 07",
      "Geconsolideerde versie van 01/06/2015": "grofvuil"
    },
    {
      "__EMPTY": "20 03 70",
      "Geconsolideerde versie van 01/06/2015": "droog niet-gevaarlijk recycleerbaar bedrijfsafval dat gemengd werd ingezameld en voldoet aan de voorwaarden zoals vastgelegd in het Vlarema."
    },
    {
      "__EMPTY": "20 03 71*",
      "Geconsolideerde versie van 01/06/2015": "gemengd afval van de zeevaart dat gevaarlijke afvalstoffen kan bevatten"
    },
    {
      "__EMPTY": "20 03 99",
      "Geconsolideerde versie van 01/06/2015": "niet elders genoemd stedelijk afval"
    }
  ];
  constructor() {}

}
