<nb-spinner *ngIf="isSaving" class="opslaan-spinner" size="giant" [message]="'Opslaan..'" status="info"></nb-spinner>
<div style="display:flex;flex-direction: row;justify-content: flex-start;align-items: center; padding-bottom: 7px">
  <h1 class="custom-title"><i class="fa-solid fa-calendar-check bigFaIcon"></i>Werfplanning/personeelstoewijzing</h1>
  <div *ngIf="isViewingDay">
    <h2 class="dagPlanningTitle">Dagplanning: {{convertDate(selectedDate)}}</h2>
  </div>
  <div  class="shorterFlex">
    <p class="sortText">Datum</p>
    <div class="colFlex">
      <nb-select [disabled]="isViewingDay" class="bigSelect" (selectedChange)="checkMaand($event)" [ngModel]="maand">
        <nb-option *ngFor="let maand of maanden" value="{{ maand }}">{{ maand }}
        </nb-option>
      </nb-select>
      <nb-select [disabled]="isViewingDay" class="jaarSelect" (selectedChange)="checkJaar($event)" [ngModel]="jaar">
        <nb-option *ngFor="let jaar of jaren" value="{{ jaar }}">{{ jaar }}
        </nb-option>
      </nb-select>
    </div>
  </div>
  <button  mat-raised-button class="nav-button-black" (click)="goToEditPloegen()">Ploegen instellen</button>
  <button *ngIf="isViewingDay" mat-raised-button class="nav-button-black" (click)="bekijk(null)">Sluiten</button>
</div>

<ng-container *ngIf="isLoaded">

  <div   *ngIf="!isViewingDay" class="calendar-header">
    <div class="day-header">Maandag</div>
    <div class="day-header">Dinsdag</div>
    <div class="day-header">Woensdag</div>
    <div class="day-header">Donderdag</div>
    <div class="day-header">Vrijdag</div>
  </div>
  <div  *ngIf="!isViewingDay" class="calendar-grid">
    <ng-container  *ngFor="let day of daysInMonth$ | async;">
      <div  (click)="bekijk(day)" *ngIf="!day.isEmpty; else emptyBlock"
            [ngClass]="day.soort !== 'vroegereDatum' && day.soort !== 'latereDatum' && day.soort !== 'feestdag' && day.soort !== 'verlofdag'? 'kanOpslaan': day.soort"
            class="day-container">
        <div class="topRowBox">
          <p class="datumText"><strong>{{day.date.getDate() + ' ' + maanden[day.date.getMonth()] }}</strong></p>
        </div>
        <p class="soortText" >{{day.soort.toUpperCase()}}</p>
        <div *ngIf="day.day === 'Vrijdag'" class="break"></div>
        <p *ngIf="day.werfplanningId" class="arbeidersText">{{day.totalSelectedArbeiders}} arbeiders</p>
        <p *ngIf="day.werfplanningId"  class="arbeidersText">{{day.verlofArbeiders.length}} verlof</p>
      </div>
      <ng-template #emptyBlock><div  class="day-container empty"></div></ng-template>
    </ng-container>
  </div>
  <div class="outDivDayView" *ngIf="isViewingDay">
    <button *ngIf="_id != null" [disabled]="isSaving"  (click)="saveDayWerfplanning()" mat-raised-button class="form-button-blue" color="primary">
      <div class="divInsideButton">
        <fa-icon class="normalIcon" [icon]="faFloppyDisk"></fa-icon>
        <span class="button-text">Opslaan</span></div>
    </button>
    <button *ngIf="_id == null" [disabled]="isSaving"  (click)="createDayWerfPlanning()" mat-raised-button class="form-button-blue" color="primary">
      <div class="divInsideButton">
        <fa-icon class="normalIcon" [icon]="faCirclePlus"></fa-icon>
        <span class="button-text">Aanmaken</span></div>
    </button>
    <div class="container">

      <div cdkDropListGroup>
        <div class="static-section">
          <h3 class="header-beschikbare-medewerkers">
            <span class="title">Beschikbare arbeiders</span>
            <mat-button-toggle-group   [(ngModel)]="sortChoiceBeschikbareArbeiders" name="sorteerOpties" aria-label="Sorteer opties">
              <mat-button-toggle (click)="changeSortChoiceBeschikbareArbeiders()" class="sorteerOpties" value="naam">Sorteer op naam</mat-button-toggle>
              <mat-button-toggle (click)="changeSortChoiceBeschikbareArbeiders()" class="sorteerOpties" value="functie">Groeperen per functie</mat-button-toggle>
              <mat-button-toggle (click)="changeSortChoiceBeschikbareArbeiders()" class="sorteerOpties" value="ploegen">Groeperen per ploeg/voorkeurarbeiders<br><small>*In ontwikkeling</small></mat-button-toggle>
            </mat-button-toggle-group>
          </h3>

          <div *ngIf="sortChoiceBeschikbareArbeiders === 'naam'"
               class="example-list"
               cdkDropList
               [cdkDropListData]="unassignedArbeiders"
               (cdkDropListDropped)="dropArbeider($event)">
            <div class="example-box"
                 *ngFor="let arbeider of unassignedArbeiders"
                 cdkDrag
                 [cdkDragData]="arbeider"
                 (click)="toggleSelection(arbeider, $event)"
                 [class.selected]="isSelected(arbeider)">
              {{arbeider.name}}
            </div>
          </div>

          <div  *ngIf="sortChoiceBeschikbareArbeiders === 'ploegen'" class="ploegen-list"
               cdkDropList
               [cdkDropListData]="ploegen"
               (cdkDropListDropped)="dropPloeg($event)">
            <div class="user-box extraSpecsPloeg"
                 *ngFor="let ploeg of ploegen"
                 cdkDrag
                 [cdkDragData]="ploeg"
                 (click)="toggleSelection(ploeg, $event)"
                 [class.selected]="isSelected(ploeg)">
              <ng-container *ngIf="ploeg.ploegbaas?._id">
                <div class="ploeg-header">
                  <p style="color:#3c3cff;margin-bottom: 0" class="text-lg font-large"><i class="fa fa-users-line" style="font-size: 16px;margin-right: 1px;color:#3c3cff;"></i>  {{ploeg.ploegbaas.name}}</p>
                  <button  class="closeButton" nbButton status="warning" >
                    <nb-icon style="font-size: 16px" pack="fa" icon="xmark"></nb-icon>
                  </button>
                </div>
                <div *ngFor="let member of ploeg.voorkeurArbeiders;let j = index" cdkDrag class="user-box" >
                  {{member.name}}
                </div>
              </ng-container>
              <ng-container *ngIf="ploeg.enkeleArbeider">
                {{ploeg.enkeleArbeider.name}}
              </ng-container>
            </div>
          </div>
        </div>

        <div class="scrollable-section">
          <div *ngFor="let werfplanning of werfPlanningen; let i = index">
            <div [ngClass]="werfplanning.werf?._id === 'verlof'? 'verlofWerf': 'werfTitle'" class="werfHeader">
              <span >{{werfplanning.werf.naam}}</span>
              <nb-checkbox  *ngIf="werfplanning.werf?._id !== 'verlof'" [checked]="werfplanning.werf.isActiefOpWerfPlanning" (change)="changeIsWerfActive(werfplanning)">Actief?</nb-checkbox>
            </div>
            <div *ngIf="sortChoiceBeschikbareArbeiders === 'naam'" class="example-list werf-list"
                 cdkDropList id="werfplanning-{{i}}"
                 [cdkDropListData]="werfplanning.selectedArbeiders"
                 (cdkDropListDropped)="dropInWerfplanning($event)">
              <div class="example-box inWerfLightBlueBackGround"
                   *ngFor="let arbeider of werfplanning.selectedArbeiders"
                   cdkDrag
                   [cdkDragData]="arbeider"
                   (click)="toggleSelection(arbeider, $event)"
                   [class.selected]="isSelected(arbeider)">
                {{arbeider.name}}
              </div>
            </div>
            <div *ngIf="sortChoiceBeschikbareArbeiders === 'ploegen'" class="example-list werf-list"
                 cdkDropList id="werfplanning-{{i}}"
                 [cdkDropListData]="werfplanning"
                 (cdkDropListDropped)="dropInWerfplanning($event)">
              <div class="user-box extraSpecsPloeg"
                    *ngFor="let ploeg of werfplanning.selectedPloegen"
                    cdkDrag
                    [cdkDragData]="ploeg"
                    (click)="toggleSelection(ploeg, $event)"
                    [class.selected]="isSelected(ploeg)">

                <ng-container *ngIf="ploeg.ploegbaas?._id">
                  <div class="ploeg-header">
                    <p style="color:#3c3cff;margin-bottom: 0" class="text-lg font-large"><i class="fa fa-users-line" style="font-size: 16px;margin-right: 1px;color:#3c3cff;"></i>  {{ploeg.ploegbaas.name}}</p>
                    <button  class="closeButton" nbButton status="warning" >
                      <nb-icon style="font-size: 16px" pack="fa" icon="xmark"></nb-icon>
                    </button>
                  </div>
                  <div *ngFor="let member of ploeg.voorkeurArbeiders;let j = index" cdkDrag class="user-box" >
                    {{member.name}}
                  </div>
                </ng-container>
                <ng-container *ngIf="ploeg.enkeleArbeider">
                  {{ploeg.enkeleArbeider.name}}
                </ng-container>
              </div>
            </div>

          </div>
      </div>
      </div>
    </div>
  </div>

</ng-container>
