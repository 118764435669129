
<h2 mat-dialog-title style=" display: flex;
      align-items: center;
      color: #f44336;
      margin-bottom: 8px;">
  <mat-icon style="margin-right: 8px" color="warn">warning</mat-icon>
  Attentie!
</h2>
<mat-dialog-content>
  <p style=" font-size: 16px; margin-bottom: 16px;"> Wilt u deze <b>staat der verlet verwijderen?</b><br>
    Na verwijdering kan u de dagboeken van deze maand opnieuw bewerken.</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button [mat-dialog-close]="false">Annuleren</button>
  <button style="margin-right: 5px;margin-left: 15px" mat-raised-button color="warn" [mat-dialog-close]="true">
    <mat-icon>delete</mat-icon>
    Verwijderen
  </button>
</mat-dialog-actions>
