import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-extra-info-weerverlet-bepaling',
  template: `
    <div class="info-popup">
      <h2 mat-dialog-title>Weerverlet Informatie</h2>
      <mat-dialog-content>
        <p>Wij bepalen weerverletdagen aan de hand van locatie en onder deze voorwaarden:</p>
        <ul>
          <li>De temperatuur om <span class="blue">7 uur 's ochtends 0 °C</span> bedraagt <span class="blue">of kouder.</span></li>
          <li>Er minimaal <span class="blue">4 uurvakken met neerslag</span> zijn voorspeld overdag. (meer of gelijk aan 0.1mm = 1 vak)</li>
          <li>De neerslag die valt <span class="blue">meer dan 5 mm per vierkante meter per dag</span> bedraagt.</li>
          <li>Er <span class="blue">rukwinden van 70 km/uur of meer</span> zijn voorspeld.</li>
        </ul>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button mat-button (click)="closeDialog()">Sluiten</button>
      </mat-dialog-actions>
    </div>
  `,
  styles: [`
    .info-popup {
      font-family: Arial, sans-serif;
      color: #333;
    }
    h2 {
      color: #007bff;
      border-bottom: 2px solid #007bff;
      padding-bottom: 10px;
    }
    ul {
      padding-left: 20px;
    }
    li {
      margin-bottom: 10px;
      font-weight: bold;
    }
    button {
      background-color: #6f87f5;
      color: white !important;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      margin-left: 12px;
      cursor: pointer;
      transition: background-color 0.3s;
    }
    button:hover {
      background-color: #0056b3;
    }
    .blue {
        color:blue;
    }
  `]
})
export class ExtraInfoWeerverletBepaling {
  constructor(public dialogRef: MatDialogRef<ExtraInfoWeerverletBepaling>) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
