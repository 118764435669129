import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import {ApiService} from "../../services/api.service";
import {Company} from "../../models/company";
import {FormService} from "../../services/form.service";
import {Verlof} from "../../models/verlof";
import {Machine} from "../../models/machine";
import {RejectDialogComponent} from "./reject-dialog.component";
import {NieuweCountService} from "../../services/NieuweCountService";
import * as html2pdf from 'html2pdf.js';
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'ngx-verlofs',
  templateUrl: './verlofs.component.html',
  styleUrls: ['./verlofs.component.scss', '../../styles/item-table.scss']
})
export class VerlofsComponent implements OnInit {
  public verlofItem: Verlof = new Verlof('','');
  public verlofs: Array<Verlof> = [];
  public isOn: Boolean;
  searchForm = new UntypedFormControl();
  filteredVerlofs: Verlof[];
  company: Company;
  days: string[] = ['Zondag','Maandag','Dinsdag','Woensdag','Donderdag','Vrijdag','Zaterdag'];
  isLoaded: boolean;
  isPrint: boolean;
  printVerlofDagen: Verlof[] = [];

  constructor(
    private apiService: ApiService,
    private router: Router,
    private formService: FormService,
    public dialog: MatDialog,
    private countService: NieuweCountService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoaded = false;
    this.isPrint = false;

    this.company = this.apiService.thisCompany;
    while(this.company == null){
      await this.delay(50);
      this.company = this.apiService.thisCompany;
    }
    await this.apiService.getVerlofs().subscribe(x => {
      this.verlofs = x as Verlof[];
      this.verlofs.forEach(verlof => {verlof.aanvraagDatum = new Date(verlof.aanvraagDatum)});
      this.verlofs.forEach(verlof => {verlof.singleDatum = new Date(verlof.singleDatum)});
      this.verlofs.forEach(verlof => {verlof.periodeStartDatum = new Date(verlof.periodeStartDatum)});
      this.verlofs.forEach(verlof => {verlof.periodeEindDatum = new Date(verlof.periodeEindDatum)});
      this.filteredVerlofs = Object.assign([], this.verlofs);

      this.isLoaded = true;
    });

  }

  private _filter(name: string): Verlof[] {
    const filterValue = name.toLowerCase();

    return this.verlofs.filter(
      (item) => item.naam.toLowerCase().indexOf(filterValue) === 0
    );
  }
  delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }
  onSearch(_id: string) {
    //filter users?
  }
  convertVerlofToRedenText(verlof: Verlof) {
    if(verlof.soort === 'single' || verlof.soort === 'periode') {
      return 'normaal';
    } else if(verlof.specialeSoort === 'ziekenhuis') {
      return 'Ziekenhuis/specialist';
    } else if(verlof.specialeSoort === 'kleinVerlet') {
      return 'Klein verlet (Huwelijk, geboorte, overlijden)';
    } else if(verlof.specialeSoort === 'andere') {
      return verlof.andereReden;
    }
  }
  async printVerlofs(){
    this.isPrint = true;
    await this.delay(50);
    this.toggleDisplay();
    let currentDate = new Date();
    let threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
    this.printVerlofDagen = this.verlofs.filter(x => x.aanvraagDatum > threeMonthsAgo);
    let fileName =  'verlofaanvragen' + ("0"+(threeMonthsAgo.getDate())).slice(-2) + "/" + ("0"+(threeMonthsAgo.getMonth() + 1)).slice(-2) +  "/" + ("0"+(threeMonthsAgo.getFullYear())).slice(-2)  +
      '-' +  ("0"+(currentDate.getDate())).slice(-2) + "/" + ("0"+(currentDate.getMonth() + 1)).slice(-2) +  "/" + ("0"+(currentDate.getFullYear())).slice(-2) +
      '.pdf';
    let element = document.getElementById('printContainer');
    await this.delay(500);
    let options = {
      filename:
      fileName,
      image: {type: 'png'},
      html2canvas: {useCORS: true},
      jsPDF: {orientation: 'portrait', format: 'a4', compressPdf: true},
      margin: [3, 2, 2, 2],
      pagebreak: { mode: 'avoid-all', avoid:  '.pageBreak'}
    };


    await html2pdf().from(element).set(options).toPdf().get('pdf').then(function(pdf) {
      let totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);
        pdf.setTextColor(100);
        pdf.text('Pagina ' + i + ' van ' + totalPages, (pdf.internal.pageSize.getWidth() / 2.3), (pdf.internal.pageSize.getHeight() - 0.8));
      }
    }).save();
    this.toggleDisplay();
    this.isPrint = false;
  }
  toggleDisplay() {
    const element = document.getElementById('printContainer');
    if (element.style.display == 'block') {
      element.style.display = 'none';
    } else {
      element.style.display = 'block';
    }
  }
  onSearchEnter(event) {
    const input = event.target.value;
    if(input != null && input !== ''){
      this.filteredVerlofs = this.verlofs.filter(x => x.verlofOntvanger?.name?.toLowerCase().includes(input.toLowerCase()));
    } else {
      this.filteredVerlofs = this.verlofs;
    }
  }

  rejectVerlof(verlof: Verlof) {
    const dialogRef = this.dialog.open(RejectDialogComponent, {
      width: '450px',
      height: '320px',
      data: {reason: ''},
      panelClass: 'mat-dialog-padding'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != null) {
        if(!verlof.isApproved && !verlof.isRejected){
          let countData = this.countService.getCurrentCounts();
          countData.verlofAanvragen--;
          this.countService.updateCounts(countData);
        }
        verlof.isRejected = true;
        verlof.isApproved = false;
        verlof.afwijsReden = result;
        this.apiService.updateVerlof(verlof).subscribe(x => {
          this.verlofs[this.verlofs.indexOf(verlof)] = verlof;
        });
      }
    });

  }

  acceptVerlof(verlof: Verlof) {
    if(!verlof.isApproved && !verlof.isRejected){
      let countData = this.countService.getCurrentCounts();
      countData.verlofAanvragen--;
      this.countService.updateCounts(countData);
    }
    verlof.isApproved = true;
    verlof.isRejected = false;
    this.apiService.updateVerlof(verlof).subscribe(x => {
      this.verlofs[this.verlofs.indexOf(verlof)] = verlof;
    });
  }

}
