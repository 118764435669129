<app-download-popup [showPopup]="isGeneratingPidpaPdf || formService.isGeneratingStaatDerVerlet"></app-download-popup>

<div  class="topRow">
   <h1 style="min-width:160px !important;margin-left: 5px !important;" class="custom-title"><i class="fa-solid fa-book-open bigFaIcon"></i>Dagboek<br>der werken</h1>
  <button class="showWervenButton" nbButton ghost (click)="showWervenOfWerfleider = !showWervenOfWerfleider">
    <nb-icon pack="fa" [icon]="showWervenOfWerfleider ? 'chevron-up' : 'chevron-down'"></nb-icon>
  </button>
   <div style="display:flex;flex-direction:column;align-items: center;justify-content: center;margin-left: 35px">
     <div class="shorterFlex">
       <p class="sortText">Werf</p>
       <div class="colFlex">
         <input [disabled]="isViewingDay" #autoInput [status]="'primary'"
                nbInput
                type="text"
                (input)="onChange()"
                placeholder="Zoek op werf.."
                [nbAutocomplete]="auto" class="autoComplete" />
         <nb-autocomplete (selectedChange)="clickAutoCompleteWerf($event)" #auto>
           <nb-option *ngFor="let option of observableFilteredItems$ | async" [value]="option">
             {{ option }}
           </nb-option>
         </nb-autocomplete>

         <button [disabled]="isViewingDay" class="closeButton" [ngClass]="autoInput.value == null || autoInput.value === '' ? 'hideButton' : ''"
                 nbButton status="warning" (click)="clearAutocomplete()">
           <nb-icon style="font-size: 22px" pack="fa" icon="xmark"></nb-icon>
         </button>
       </div>
     </div>
   </div>
  <div class="shorterFlex">
    <p class="sortText">Datum</p>
    <div class="colFlex">
      <div class="monthSelector">
        <button [disabled]="currentWerf == null || isViewingDay" (click)="previousMonth()" class="arrow-button"><i style="font-size: 23px" class="fa fa-arrow-left-long"></i></button>
        <nb-select [status]="'primary'" [disabled]="currentWerf == null || isViewingDay" class="bigSelect" (selectedChange)="checkMaand($event)" [ngModel]="maand">
          <nb-option *ngFor="let maand of maanden" [value]="maand">{{ maand }}</nb-option>
        </nb-select>
        <button [disabled]="currentWerf == null || isViewingDay" (click)="nextMonth()" class="arrow-button"><i style="font-size: 23px" class="fa fa-arrow-right-long"></i></button>
      </div>
      <nb-select [disabled]="currentWerf == null || isViewingDay" class="jaarSelect" (selectedChange)="checkJaar($event)" [ngModel]="jaar">
        <nb-option *ngFor="let jaar of jaren" [value]="jaar">{{ jaar }}</nb-option>
      </nb-select>
    </div>
  </div>
  <button (click)="verwijderDagboeken()" *ngIf="canDeleteDagboeken" mat-raised-button class="dagboek-delete-button" color="warn" type="button">
    <div class="divInsideButton">Dagboeken verwijderen<i style="margin-left:1px !important;" class="fa-solid fa-trash-can"></i></div></button>
  <button *ngIf="currentWerf != null && !canDeleteDagboeken && !isGeneratingPidpaPdf" mat-raised-button (click)="printPidpaDagboeken()" class="add-button-pidpa" [disabled]="currentWerf == null || isViewingDay">
    <div class="divInsideButton"><i style="font-size: 27px;margin-right: 12px" class="fa fa-file-pdf"></i>Download<br>dagboek</div></button>
  <div style="display:flex;flex-direction:column;align-items: center;justify-content: center; gap:8px 0">
    <button *ngIf="currentWerf != null && !formService.isGeneratingStaatDerVerlet && !canDeleteDagboeken && !isGeneratingPidpaPdf" mat-raised-button (click)="generateStaatDerVerletdagen()" color="green" class="add-button-staat" [disabled]="currentWerf == null || isViewingDay">
      <div class="divInsideButton"><i style="font-size: 27px;margin-right: 12px" class="fa fa-file-excel"></i>Staat der<br>verlet</div></button>
    <button (click)="goToEditWerf()" *ngIf="currentWerf != null && !canDeleteDagboeken && !isGeneratingPidpaPdf" nbButton class="werfInstellingButton"><fa-icon class="normalIcon" [icon]="faGear"></fa-icon> Werfinstellingen</button>
  </div>
</div>
<h2 class="geenWerfText" *ngIf="currentWerf == null && !showWervenOfWerfleider && actieveWerven != null && actieveWerven.length !== 0">
  <i style="height:10px;width:10px; margin-right:15px" class="fas fa-info-circle"></i>
  Kies hierboven een werf
  <i class="fas fa-arrow-up"></i>
</h2>
<h3 *ngIf="currentWerf == null && showWervenOfWerfleider && actieveWerven != null && actieveWerven.length === 0 && wervenOfThisWerfleider != null &&  wervenOfThisWerfleider.length === 0" class="no-werf-leader">
  <b>U heeft nog geen actieve werven.<br> Maak een werf actief door het bevel van aanvang, uitvoeringstermijn en adres in te stellen op de werf.</b><br>
  <button (click)="goToWerfList()" nbButton class="werfInstellingButton2">Ga naar werven <fa-icon class="normalIcon" style="font-size: 17px;margin-left: 10px;" [icon]="faArrowRightLong"></fa-icon></button>
</h3>
<h3 *ngIf="currentWerf == null && showWervenOfWerfleider && wervenOfThisWerfleider != null &&  wervenOfThisWerfleider.length === 0" class="no-werf-leader">
  U bent nog geen werfleider van een bepaalde werf.<br>
  <b>U kan uzelf als werfleider toevoegen bij de werfinstellingen.</b><br>
  <button (click)="goToWerfList()" nbButton class="werfInstellingButton2">Ga naar werven <fa-icon class="normalIcon" style="font-size: 17px;margin-left: 10px;" [icon]="faArrowRightLong"></fa-icon></button>
</h3>
<div *ngIf="currentWerf == null  && showWervenOfWerfleider && actieveWerven != null && actieveWerven.length !== 0"  style="max-width: 1350px !important;margin-top: -15px;" class="container">

    <div *ngIf="!isViewingWeerVoorspelling" class="weather-buttons-container">
      <h2  class="infoText8">
        Actieve werven
      </h2>
      <button nbButton status="info" size="medium" (click)="getWeatherForecastOfAllActiveWerven('today')" class="weather-button" nbTooltip="Bekijk de weervoorspelling voor vandaag voor alle actieve werven" nbTooltipPlacement="top">
        <fa-icon [icon]="faCloudSun" class="normal-icon"></fa-icon><span>Vandaag</span><span class="date-label">{{days[todayDate.getDay()]}} {{todayDate.toLocaleDateString()}}</span>
      </button>

      <button *ngIf="tomorrowDate.getDay() !== 6 && tomorrowDate.getDay() !== 0"  nbButton status="info" size="medium"
        (click)="getWeatherForecastOfAllActiveWerven('tomorrow')" class="weather-button" nbTooltip="Bekijk de weervoorspelling voor morgen voor alle actieve werven" nbTooltipPlacement="top">
        <fa-icon [icon]="faCloudSun" class="normal-icon"></fa-icon><span>Morgen</span><span class="date-label">{{days[tomorrowDate.getDay()]}} {{tomorrowDate.toLocaleDateString()}}</span>
      </button>
    </div>

  <div *ngIf="!isViewingWeerVoorspelling" class="werven-container">
    <div (click)="selectWerfOfWerfleider(werf)"
         *ngFor="let werf of actieveWerven"
         [ngClass]="{'greyBackg': werf.isNogNietGestart, 'greenBackg': werf.isWerfleiderOfThisWerf}"
         class="werf-box">

      <div class="werf-header">
        <h3 class="werf-title">{{werf.gemeente && werf.gemeente !== '' ? werf.gemeente + ' - ' : ''}}{{ werf.naam }}</h3>
        <div *ngIf="!werf.isWerfleiderOfThisWerf && werf.werfleiders?.length" class="werf-leaders">
          <i class="fa-solid fa-circle-user"></i>
          {{showWerfleiderNames(werf.werfleiders)}}
        </div>
      </div>

      <div class="werf-info">
        <div *ngIf="werf.uitvoeringsTermijn" class="info-row">
          <fa-icon [icon]="faCalendarClock" class="icon purple"></fa-icon>
          <span class="label">Termijn:</span>
          <span class="value">
        {{werf.latestDagboek?.werkDagNummmer == null ?
            werf.uitvoeringsTermijn :
            werf.uitvoeringsTermijn - werf.latestDagboek.werkDagNummmer}}/{{werf.uitvoeringsTermijn}}
      </span>
        </div>

        <div *ngIf="werf.startDatum" class="info-row">
          <i class="fa-solid fa-calendar-days icon"></i>
          <span class="date-range">
        {{ dateToString(werf.startDatum)}}
            <span *ngIf="werf.dagboekenAangevuldTot">
          - {{ dateToString(werf.dagboekenAangevuldTot) }}
        </span>
      </span>
        </div>
      </div>

      <div *ngIf="werf.latestDagboek" class="stats-grid">
        <div class="stat-item">
          <fa-icon [icon]="faHashtag" class="icon blue"></fa-icon>
          <span>{{werf.latestDagboek.bladNummer}}</span>
        </div>
        <div class="stat-item">
          <fa-icon [icon]="faPersonDigging" class="icon dark"></fa-icon>
          <span>{{werf.latestDagboek.werkDagNummmer}}</span>
        </div>
        <div class="stat-item">
          <fa-icon [icon]="faCloudHailMixed" class="icon sky-blue"></fa-icon>
          <span>{{werf.latestDagboek.weerverletNummer}}</span>
        </div>
        <div class="stat-item">
          <fa-icon [icon]="faRoadBarrier" class="icon orange"></fa-icon>
          <span>{{werf.latestDagboek.ontoegankelijkheidNummer}}</span>
        </div>
        <div class="stat-item">
          <fa-icon [icon]="faPause" class="icon blue"></fa-icon>
          <span>{{werf.latestDagboek.schorsingNummer}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="currentWerf == null  && isViewingWeerVoorspelling" class="weerVoorspellingBox">
  <!-- WEERVOORSPELLINGEN TONEN -->
  <div style="margin-top: 15px;margin-bottom: 10px" class="rowFlex5">
    <h2 style="margin:0 45px 0px 25px">Weervoorspellingen {{days[currentDate.getDay()]}} {{currentDate.toLocaleDateString()}}</h2>
    <button nbButton status="info" size="medium" (click)="closeWeerVoorspelling()" class="weather-button" >
      <fa-icon [icon]="faArrowLeftFromBracket" class="normal-icon"></fa-icon><span>Voorspelling sluiten</span>
    </button>
  </div>

  <div *ngIf="wervenWithWeerVoorspelling == null || wervenWithWeerVoorspelling.length === 0" style="max-width: 520px !important;align-items: center; margin-top:150px;margin-right: 0px !important;margin-left: 80px;" class="flexColumn">
    <div  class="rowFlex5">
      <i style="font-size: 40px" class="fa fa-cloud-sun"></i>
      <p style="font-size: 18px;margin:0; padding:0 10px">Weervoorspellingen ophalen ..</p>
      <mat-progress-spinner [mode]="'indeterminate'" [diameter]="35" ></mat-progress-spinner>
    </div>
  </div>
    <div *ngFor="let werfMetWeerData of wervenWithWeerVoorspelling"  class="weather-content">
      <div style="max-width:960px;" *ngIf="werfMetWeerData.weatherData?.hourly" class="rainfall-info">
        <h2 style="margin-bottom: 0; font-size: 17px; padding-left: 10px; color: #2c3e50; font-weight: 600; border-bottom: 2px solid #3498db; padding-bottom: 3px;">{{werfMetWeerData.gemeente && werfMetWeerData.gemeente !== '' ? werfMetWeerData.gemeente + ' - ' : ''}}{{ werfMetWeerData.naam }}</h2>
        <div *ngIf="werfMetWeerData.extraInfoOverIsWeerverlet" style="background-color: unset !important; padding-top: 3px;" class="weerverlet-info2">
          <h3 style="margin-bottom: 0px;text-decoration: underline; width:100%;text-align: center"><u>Weerverlet?</u></h3>
          <div style="width: fit-content;display: block; margin: 0 auto 10px auto;" class="weerVerletInfoDiv">
            <p style="font-size: 17px;margin:0 !important;width: fit-content !important;" class="extraInfoWeerverlet blueColor">{{ werfMetWeerData.extraInfoOverIsWeerverlet }}</p>
          </div>
        </div>
        <div class="rainfall-bars">
          <div *ngFor="let hour of werfMetWeerData.weatherData.hourly; let i = index" [ngClass]="werfMetWeerData.weatherData?.hourly?.length === 16 ? 'smallerBar' : ''" class="rainfall-bar-container">
            <div *ngIf="hour" class="rainfall-bar"
                 [style.height.%]="getBarHeight(hour.precip)"
                 [title]="hour.precip + ' mm om ' + hour.hour + ':00'">
            </div>
          </div>
        </div>
        <div  class="rainfall-amounts">
          <ng-container *ngFor="let hour of werfMetWeerData.weatherData.hourly; let i = index" >
            <div *ngIf="hour" [ngClass]="werfMetWeerData.weatherData?.hourly?.length === 16 ? 'smallerBar' : ''" class="rainfall-amount">
                <p style="font-size: 12px;  color: #666;" [ngClass]="hour.precip > 0 && !(werfMetWeerData.weatherData?.hourly?.length === 16 && +hour.hour < 7)? 'blueColor': ''">{{ hour.precip }} mm</p>
           <p [ngClass]="{
              'blueColor': hour.precip > 0 && !(werfMetWeerData.weatherData?.hourly?.length === 16 && +hour.hour < 7),
            'zevenUur': +hour.hour === 7 && werfMetWeerData.weatherData?.hourly?.length === 16}" class="rainfall-hour">
              {{hour.hour}} uur
            </p>                <p style="color:black" class="rainfall-hour">{{ hour.temperature}}°C</p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
</div>
<div *ngIf="currentWerf != null && ( currentWerf.uitvoeringsTermijn == null || (geenStartDatumOpWerf && autoInput?.value != null && autoInput.value !== '') || !currentWerf.latCoord || !currentWerf.longCoord)" style="font-weight:bold;margin-top:10px;border:5px solid orange;border-radius:5px; display:flex;flex-direction: column;width:fit-content;padding:5px 10px 5px 5px; margin-bottom: 7px">
  <p class="missingSettingsText" *ngIf="geenStartDatumOpWerf && autoInput?.value != null && autoInput.value !== ''">
    <b><i style="margin-right: 3px" class="fa fa-warning"></i> Gelieve een <span style="color:blue !important;">Bevel van Aanvang</span> in te stellen op deze werf bij werfinstellingen.</b>
  </p>
  <p class="missingSettingsText" *ngIf="currentWerf.uitvoeringsTermijn == null" >
    <b><i style="margin-right: 3px" class="fa fa-warning"></i> Gelieve een <span style="color:blue !important;">Uitvoeringstermijn</span> in te stellen op deze werf bij werfinstellingen.</b>
  </p>
  <p class="missingSettingsText" *ngIf="!currentWerf.latCoord || !currentWerf.longCoord">
    <b><i style="margin-right: 3px" class="fa fa-warning"></i> Gelieve een <span style="color:blue !important;">Adres/Locatie</span> in te stellen op deze werf bij werfinstellingen.<br>Wij gebruiken de coördinaten van de werf om weergegevens op te halen.</b>
  </p>
  <button style="margin-top: 5px; width: fit-content;margin-left: 40px" nbButton status="primary" (click)="goToEditWerf()">
    Ga naar werfinstellingen <i style="margin-left:5px;font-size: 17px" class="fa fa-edit"></i>
  </button>
</div>
<div class="weather-header" *ngIf="isLoaded && isViewingDay && weatherData && weatherData[currentIsoDate] && staatForm.get('soortVerlet').value !== 'feestdag' && staatForm.get('soortVerlet').value !== 'verlofdag'">
  <div style="padding: 0 30px 4px 20px !important;" class="weather-content">
    <div *ngIf="extraInfoOverIsWeerverlet" style="background-color: unset !important; padding-top: 3px;" class="weerverlet-info2">
      <h3 style="margin-bottom: 0px;text-decoration: underline; width:100%;text-align: center"><u>Weerverlet?</u></h3>
      <div class="weerVerletInfoDiv">
        <p style="font-size: 17px;margin:0 !important;" class="extraInfoWeerverlet blueColor">{{ extraInfoOverIsWeerverlet }}</p>
        <p *ngIf="mmRegenOvernacht" class="night-rainfall">Neerslag 0:00 - 07:00: {{mmRegenOvernacht.toFixed(2)}} mm</p>
      </div>
    </div>

    <div class="rainfall-info">
      <h3 style="margin-bottom: 0px;"><u>Neerslag</u> <i (click)="openWeerverletBepalingPopup()" style="color:#a3a3ff;font-size: 20px;margin-left: 8px" class="fa-solid fa-circle-info"></i></h3>
      <div class="rainfall-bars">
        <div *ngFor="let hour of weatherData[currentIsoDate].hourly; let i = index" class="rainfall-bar-container">
          <div *ngIf="hour" class="rainfall-bar"
               [style.height.%]="getBarHeight(hour.precip)"
               [title]="hour.precip + ' mm om ' + hour.hour + ':00'">
          </div>
        </div>
      </div>
      <div class="rainfall-amounts">
        <ng-container *ngFor="let hour of weatherData[currentIsoDate].hourly; let i = index">
          <div *ngIf="hour" class="rainfall-amount">
            <p style="font-size: 12px;  color: #666;" [ngClass]="hour.precip > 0 ? 'blueColor': ''">{{ hour.precip }} mm</p>
            <p [ngClass]="hour.precip > 0 ? 'blueColor': ''" class="rainfall-hour">{{ hour.hour}}:00</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<button *ngIf="isLoaded && !isSavingStaatDerVerlet && !isViewingDay && hasAllDagboekenOfMonth && currentStaatDerVerlet == null"
        (click)="createStaatDerVerletOfMonth()" nbButton
         class="maandTotalenButton">
  <div class="divInsideButton">
    <fa-icon class="normalIcon" [icon]="faLock"></fa-icon>
    <span class="button-text">Beëindig maand en sla staat der verlet op</span>
  </div>
</button>
<div *ngIf="isLoaded && !isViewingDay && hasAllDagboekenOfMonth && currentStaatDerVerlet != null" class="lockedMonthDiv">
  <p class="lockedMonthText">Deze maand is beïndigd</p>
  <button *ngIf="isAllowedToDeleteStaatDerverletOfThisMonth" (click)="deleteStaatDerVerletOfMonth()"
          nbButton
          class="form-button-orange"
          matTooltip="Annuleer de huidige staat der verlet indien er zaken in de dagboeken van deze maand moeten gewijzigd worden.">
    <div class="divInsideButton">
      <fa-icon class="normalIcon" [icon]="faXmark"></fa-icon>
      <span class="button-text">Annuleren</span>
    </div>
  </button>
</div>

<div  *ngIf="isLoaded && currentWerf != null && currentStaatDerVerlet != null" style="border-radius: 15px !important;margin-bottom: 25px" class="outerInfoBox">
  <div class="maandOverzichtRow">
    <p class="infoTextTop"><b>Maandoverzicht</b>
    </p>

    <p class="infoTextTop">
      <fa-icon [icon]="faPersonDigging" style="color:black" class="iconRightMargin"></fa-icon><b>{{currentStaatDerVerlet.werkDagen}}</b><br> werkdag
    </p>
    <p class="infoTextTop">
      <fa-icon [icon]="faCloudHailMixed" style="color:deepskyblue" class="iconRightMargin"></fa-icon><b>{{ currentStaatDerVerlet.weerverletDagen }}</b><br> weerverlet
    </p>
    <p class="infoTextTop">
      <span class="minWidthIcon"><fa-icon [icon]="faRoadBarrier" style="color:orange" class="iconRightMargin"></fa-icon></span><b>{{currentStaatDerVerlet.ontoegankelijkheidDagen}}</b> ontoegank.<br>
      <span class="minWidthIcon"><fa-icon [icon]="faPause" style="color:#2a809c" class="iconRightMargin"></fa-icon></span><b>{{currentStaatDerVerlet.schorsingsDagen}}</b> schorsing
    </p>

    <p class="infoTextTop">
      <span class="minWidthIcon"><fa-icon [icon]="faUmbrellaBeach" style="color:#FF9933" class="iconRightMargin"></fa-icon></span>
      <b>{{currentStaatDerVerlet.verlofDagen}}</b> verlofdag<br>
      <span class="minWid thIcon"><fa-icon [icon]="faGift" style="color:#E31B63" class="iconRightMargin"></fa-icon></span>
      <b>{{currentStaatDerVerlet.feestDagen}}</b> feestdag
    </p>
  </div>
</div>
<div  *ngIf="isLoaded && currentWerf != null && latestDagboek != null" class="outerInfoBox">
  <div class="infoRow">
    <p class="infoTextTop"><i class="fa-solid fa-circle-user"></i> {{currentWerf.werfleiders != null && currentWerf.werfleiders.length !== 0 ?
      showWerfleiderNames(currentWerf.werfleiders) : 'Geen werfleider'}}<br>

      <span *ngIf="firstDagboek?.date"> <i class="fa-solid fa-calendar-days iconRightMargin"></i><b>{{dateToString(this.firstDagboek.date)}}</b> - <b>{{dateToString(this.latestDagboek.date)}}</b></span>
    </p>

    <p class="infoTextTop">
      <fa-icon [icon]="faCalendarClock" style="color:purple;font-size: 16px !important;" class="iconRightMargin"></fa-icon>Termijn: <strong style="font-size: 16px"><span style="color:blue;">{{currentWerf.uitvoeringsTermijn  - latestDagboek.werkDagNummmer}}</span>/{{ currentWerf.uitvoeringsTermijn }}</strong> dagen
    </p>
    <p class="infoTextTop">
      <fa-icon [icon]="faPersonDigging" style="color:black" class="iconRightMargin"></fa-icon><b>{{latestDagboek.werkDagNummmer}}</b><br> werkdag
    </p>
    <p class="infoTextTop">
      <fa-icon [icon]="faCloudHailMixed" style="color:deepskyblue" class="iconRightMargin"></fa-icon><b>{{ latestDagboek.weerverletNummer }}</b><br> weerverlet
    </p>
    <p class="infoTextTop">
      <span class="minWidthIcon"><fa-icon [icon]="faRoadBarrier" style="color:orange" class="iconRightMargin"></fa-icon></span><b>{{latestDagboek.ontoegankelijkheidNummer}}</b> ontoegank.<br>
      <span class="minWidthIcon"><fa-icon [icon]="faPause" style="color:#2a809c" class="iconRightMargin"></fa-icon></span><b>{{latestDagboek.schorsingNummer}}</b> schorsing
    </p>

    <p class="infoTextTop">
      <span class="minWidthIcon"><fa-icon [icon]="faUmbrellaBeach" style="color:#FF9933" class="iconRightMargin"></fa-icon></span>
      <b>{{latestDagboek.verlofDagNummer}}</b> verlofdag<br>
      <span class="minWid thIcon"><fa-icon [icon]="faGift" style="color:#E31B63" class="iconRightMargin"></fa-icon></span>
      <b>{{latestDagboek.feestDagNummer}}</b> feestdag
    </p>
  </div>

</div>

<div *ngIf="isLoaded"  cdkScrollable class="scroll-table-verlet stylish-table">
  <div   *ngIf="!isViewingDay" class="calendar-header">
    <div class="day-header">Maandag</div>
    <div class="day-header">Dinsdag</div>
    <div class="day-header">Woensdag</div>
    <div class="day-header">Donderdag</div>
    <div class="day-header">Vrijdag</div>
  </div>
    <div [formGroup]="deleteDagboekForm"  *ngIf="!isViewingDay" class="calendar-grid">
      <ng-container  *ngFor="let day of daysInMonth$ | async;">
        <div  (click)="bekijk(day)" *ngIf="!day.isEmpty; else emptyBlock"
             [ngClass]="!day.hasDagboek && nextDagboekWithNumbers != null && day.soort !== 'vroegereDatum' && day.soort !== 'latereDatum'? 'kanOpslaan': day.soort"
             class="day-container">
          <div class="topRowBox">
            <p class="datumText"><b>{{day.date.getDate()}}</b>  {{maanden[day.date.getMonth()] }}</p>
            <p class="soortTextSaved" [ngClass]="day.soort ? day.soort : ''" *ngIf="day.hasDagboek && day.soort !== 'latereDatum' && day.soort !== 'vroegereDatum'">{{day.soort.toUpperCase()}}</p>

            <nb-checkbox class="delete-checkbox" (click)="changeDeleteDagboek(day.isoDate)" *ngIf="day.hasDagboek && currentStaatDerVerlet == null"  [formControlName]="day.isoDate"></nb-checkbox>
          </div>
          <div  *ngIf="day.hasDagboek" class="topRowBox2">
            <p class="aantalActiviteitenText" *ngIf="day.werkenCount !== 0"><strong style="color:blue;"><i style="color:#333 !important;" class="fa fa-person-digging faIcon"></i> {{day.werkenCount}}</strong> act.</p>
            <p  *ngIf="day.werkenCount === 0" style="width:77px;" class="whiteSpace"></p>

            <p  *ngIf="day.werkUren && day.werkUren !== '0U'" class="werkUrenSpan"><span class="borderWerkuren"><i style="color:#333 !important;margin-right: 0px !important;" class="fa fa-clock faIcon"></i> {{convertToshortHourText(day.werkUren)}}</span></p>
            <p  *ngIf="!day.werkUren || day.werkUren === '0U'" class="whiteSpace"></p>

            <div class="numbersCol">
              <p class="smallText">Bladnr {{day.dagboek.bladNummer}}</p>
              <p class="smallText">Verletdag {{day.dagboek.verletDagNummer}}</p>
              <p class="smallText">Werkdag {{day.dagboek.werkDagNummmer}}</p>
            </div>
          </div>
            <p *ngIf="nextDagboekDate != null && nextDagboekDate.toISOString().split('T')[0] === day.isoDate && nextDagboekDate?.getTime() <= todayDate.getTime() " class="beginGoedkeurenText">
              GA HIER VERDER MET OPSLAAN <i style="color:white !important;" class="fa-solid fa-arrow-right-long faIcon"></i></p>
          <div  *ngIf="currentWerf.startDatum != null && currentWerf.startDatum.toISOString().split('T')[0] === day.date.toISOString().split('T')[0]" style="min-height:20px !important; margin-top:-8px !important;" class="rowFlex5">
            <p style="color:black;font-weight: bold; padding:5px; border:1px solid blue;border-radius:5px; margin:10px 0 10px 0 !important;">STARTDATUM</p>
          </div>
          <div *ngIf="!day.hasDagboek && weatherData && day.soort !== 'latereDatum' && day.soort !== 'vroegereDatum' " class="weerverlet-info-small">
            <p *ngIf="day.soort === 'weerverlet'"  class="extraInfoWeerverletTitle">WEERVERLET?</p>
            <p *ngIf="day.soort === 'weerverlet'" class="extraInfoWeerverlet">{{ day.extraInfoOverIsWeerverlet }}</p>
            <p *ngIf="day.soort === 'werkdag'" class="extraInfoWeerverletTitle werkdag ">WERKDAG?</p>
            <p *ngIf="day.soort === 'feestdag'"  class="extraInfoWeerverletTitle feestdag">FEESTDAG?</p>
            <p *ngIf="day.soort === 'verlofdag'" class="extraInfoWeerverletTitle verlofdag">VERLOFDAG?</p>
          </div>
          <div *ngIf="day.dagboek != null" class="employeeRow">
            <div *ngIf="(day.dagboek.arbeiderCount != null && day.dagboek.arbeiderCount !== 0) || (day.dagboek.machinistenCount != null && day.dagboek.machinistenCount !== 0)" class="employeeBox">
              <i class="fa fa-user miniIcon"></i>
              <p><span class="valueText">{{nullToZero(day.dagboek.arbeiderCount) + nullToZero(day.dagboek.machinistenCount)}}</span> arb</p>
            </div>
            <div *ngIf="day.dagboek.onderaannemersCount != null && day.dagboek.onderaannemersCount !== 0" class="employeeBox">
              <i class="fa fa-handshake miniIcon"></i>
              <p><span class="valueText">{{day.dagboek.onderaannemersCount}}</span> OA</p>
            </div>
            <div *ngIf="day.dagboek.chauffeursCount != null && day.dagboek.chauffeursCount !== 0" class="employeeBox">
              <i class="fa fa-truck miniIcon"></i>
              <p><span class="valueText">{{day.dagboek.chauffeursCount}}</span> chauf</p>
            </div>
            <div *ngIf="day.dagboek.machinesCount != null && day.dagboek.machinesCount !== 0" class="employeeBox">
              <i class="fa fa-snowplow miniIcon"></i>
              <p><span class="valueText">{{day.dagboek.machinesCount}}</span> mach</p>
            </div>
          </div>
        </div>
        <ng-template #emptyBlock><div  class="day-container empty"></div></ng-template>
        <div *ngIf="day.day === 'Vrijdag'" class="break"></div>
      </ng-container>
    </div>
  <div  [formGroup]="staatForm"  *ngIf="isLoaded && isViewingDay">
    <div class="header">
      <button mat-raised-button class="nav-button-black" (click)="bekijk(null)"><i class="fa fa-chevron-left"></i> Terug</button>
        <p class="headerText">{{days[currentDate.getDay()]}} - {{("0"+(currentDate.getDate())).slice(-2)}}/{{("0"+((currentDate.getMonth()) + 1) ).slice(-2)}}/{{currentDate.getUTCFullYear()}}</p>
      <div class="form-row">
        <label style="width:50px !important" for="soort">Soort:</label>
        <nb-select [ngClass]="getSoortVerletClass()" (selectedChange)="changeNumbersOfDay($event)" class="big-input" id="soort" formControlName="soortVerlet">
          <nb-option value="werkdag">WERKDAG</nb-option>
          <nb-option value="weerverlet">WEERVERLET (regen/vorst)</nb-option>
          <nb-option value="ontoegankelijk">TERREIN ONTOEGANKELIJK</nb-option>
          <nb-option value="schorsing">SCHORSING DER WERKEN</nb-option>
          <nb-option value="verlofdag">VERLOFDAG</nb-option>
          <nb-option value="feestdag">FEESTDAG</nb-option>
        </nb-select>
      </div>
      <div *ngIf="currentDagboek == null && nextDagboekDate != null && currentDate.toISOString().split('T')[0]  === nextDagboekDate.toISOString().split('T')[0] "  class="form-row">
        <p class="countText">{{currentCount}}\{{totalPossibleDayCount}}
      </div>
      <button [disabled]="isSaving"  *ngIf="currentCount === totalPossibleDayCount && currentDate.toISOString().split('T')[0]  <= nextDagboekDate.toISOString().split('T')[0]" (click)="saveDagboek()" mat-raised-button class="form-button-blue" color="primary">
        <div class="divInsideButton">
          <fa-icon class="normalIcon" [icon]="faFloppyDisk"></fa-icon>
          <span class="button-text">Opslaan</span></div>
      </button>
      <button [disabled]="isSaving"  *ngIf="nextDagboekDate != null && currentDate.toISOString().split('T')[0]  === nextDagboekDate.toISOString().split('T')[0] &&
      currentDate.toISOString().split('T')[0] !== todayDate.toISOString().split('T')[0] && lastDayOfMonth !== currentDate.getDate() && currentCount !== totalPossibleDayCount" (click)="saveDagboek(true)" mat-raised-button class="form-button-blue" color="primary">
        <div class="divInsideButton">
          <fa-icon class="normalIcon" [icon]="faFloppyDisk"></fa-icon>
          <span class="button-text">Opslaan en volgende <i style="font-size: 20px;margin-left: 8px" class="fa fa-arrow-right"></i></span></div>
      </button>
      <button *ngIf="_id != null" mat-raised-button class="delete-button" color="warn" type="button"
              (click)="onDeleteClick()"><i style="margin-left:1px !important;" class="fa-solid fa-trash-can"></i></button>
    </div>

    <div *ngIf="company.heeftKopieerDagboeken && currentDagboek == null && nextDagboekDate != null && currentDate.toISOString().split('T')[0]  <= nextDagboekDate.toISOString().split('T')[0] && this.kopieerDagboeken != null && this.kopieerDagboeken.length !== 0" class="kopieerDagrapportDiv">
      <button  (click)="kopieerDagboek()" mat-raised-button class="kopieerDagrapportButton" color="primary">Kopieer vorig dagboek</button>
      <nb-select  style="width:700px; max-width:700px !important;"  [ngModelOptions]="{standalone: true}" [(ngModel)]="kopieerDagboekSelect">
        <nb-option *ngFor="let dagboek of kopieerDagboeken" value="{{dagboek._id}}">
          <span style="min-width:185px">{{days[dagboek.date.getDay()]}} {{dagboek.date | date}} <br>
            <span [ngClass]="dagboek.soortVerlet" style="padding:2px;color: black">{{dagboek.soortVerlet.toUpperCase()}}</span> {{dagboek.werkzaamheden != null && dagboek.werkzaamheden.length !== 0? '(' + dagboek.werkzaamheden.length + ' act.) ' : ' '}}</span>
        <br>
          <div class="kopieerFlexWrapEmployees">
            <div  *ngIf="dagboek.arbeiderCount != null && dagboek.arbeiderCount !== 0" class="employeeBoxSmall">
              <i class="fa fa-user faIcon"></i>
              <p><span class="valueText">{{dagboek.arbeiderCount}}</span> arbeider{{dagboek.arbeiderCount === 1 ? ' ' : 's '}}</p>
            </div>
            <div *ngIf="dagboek.machinistenCount != null && dagboek.machinistenCount !== 0" class="employeeBoxSmall">
              <i class="fa fa-user faIcon"></i>
              <p><span class="valueText">{{dagboek.machinistenCount}}</span> machinist{{dagboek.machinistenCount === 1 ? ' ' : 'en '}}</p>
            </div>
            <div *ngIf="dagboek.onderaannemersCount != null && dagboek.onderaannemersCount !== 0" class="employeeBoxSmall">
              <i class="fa fa-handshake faIcon"></i>
              <p><span class="valueText">{{dagboek.onderaannemersCount}}</span> onderaannemer{{dagboek.onderaannemersCount === 1 ? ' ' : 's '}}</p>
            </div>
            <div *ngIf="dagboek.chauffeursCount != null && dagboek.chauffeursCount !== 0" class="employeeBoxSmall">
              <i class="fa fa-truck faIcon"></i>
              <p><span class="valueText">{{dagboek.chauffeursCount}}</span> chauffeur{{dagboek.chauffeursCount === 1 ? ' ' : 's '}}</p>
            </div>
            <div *ngIf="dagboek.machinesCount != null && dagboek.machinesCount !== 0" class="employeeBoxSmall">
              <i class="fa fa-snowplow faIcon"></i>
              <p><span class="valueText">{{dagboek.machinesCount}}</span> machine{{dagboek.machinesCount === 1 ? ' ' : 's '}}</p>
            </div>
          </div>
        </nb-option>
      </nb-select>
    </div>

    <div class="container">
      <div class="edit-form">
        <div class="firstRow">
          <div class="flexColumn">
            <div class="form-row">
              <label style="width:83px !important;" for="bladnummer">Bladnummer</label>
              <input style="background-color: #eeeeee !important;" [readOnly]="true" formControlName="bladNummer" id="bladnummer" nbInput type="number" class="short-input">
            </div>

            <div class="form-row">
              <label style="width:83px !important;" for="werkdag-nr">Werkdag</label>
              <input style="background-color: #eeeeee !important;" [readOnly]="true" formControlName="werkDagNummmer" id="werkdag-nr" nbInput type="number" class="short-input">
            </div>

            <div class="form-row">
              <label style="width:83px !important;" for="verletdag">Verletdag</label>
              <input style="background-color: #eeeeee !important;" [readOnly]="true" formControlName="verletDagNummer" id="verletdag" nbInput type="number" class="short-input">
            </div>
          </div>
          <div class="flexColumn">


            <div class="form-row">
              <i style="width:75px;padding-left: 45px" class="fas fa-clock smallIcon"></i>
              <input nbInput class="medium-input" style="width: 100px !important;" placeholder="Uren.." formControlName="werkuren" [nbTimepicker]="werkurenPicker">
              <nb-timepicker (onSelectTime)="changeWerkUren($event)"  [timeFormat]="'H\'u\' m\'min\''"   [step]="15"   [singleColumn]="true" [applyButtonText]="'Opslaan'" #werkurenPicker></nb-timepicker>
            </div>
            <div class="form-row">
              <p style="padding-left: 40px" class="uurText">Van</p>
                  <input  nbInput class="medium-input"
                          name="selected_time_B"
                          formControlName="startUur"
                          [min]="minTime" [max]="maxTime"  [format]="24"
                          [ngxMatTimepicker]="pickerStart"
                          placeholder=""
                          readonly />
              <ngx-mat-timepicker  (closed)="changeStartOfEinduur()"  [minutesGap]="15" color="accent"
                                    #pickerStart></ngx-mat-timepicker>
            </div>
            <div class="form-row">
              <p style="padding-left: 40px" class="uurText">Tot</p>
                  <input  nbInput class="medium-input"
                         name="selected_time_eind"
                         formControlName="eindUur"
                         [min]="minTime" [max]="maxTime"  [format]="24"
                         [ngxMatTimepicker]="pickerEind"
                         placeholder=""
                         readonly />

              <ngx-mat-timepicker  (closed)="changeStartOfEinduur()"  [minutesGap]="15" color="accent"
                                   #pickerEind></ngx-mat-timepicker>
            </div>
          </div>
          <div *ngIf="!isLoadingWeather"  style="margin-left:8px;" class="flexColumn">
            <div class="temp-row">
              <label style="width:28px !important;"><i style="font-size: 20px" class="fas fa-thermometer-half smallIcon"></i></label>
              8u<input  formControlName="temp8h" nbInput type="number" class="tiny-input">°C
              <span style="margin-left:6px !important;">13u</span><input formControlName="temp13h" nbInput type="number" class="tiny-input">°C
            </div>
            <div class="form-row">
              <label style="width:26px !important;" for="regen"><i class="fas fa-tint smallIcon"></i></label>
              <input formControlName="regenGedurendeUren" id="regen" nbInput type="number" class="tiny-input">
              <span style="margin-left:6px !important;">uren (7u-16u)</span>
            </div>
            <div class="form-row">
              <label style="width:22px !important;" for="weergesteldheid"><i class="fa-solid fa-cloud-rain smallIcon"></i></label>
              <nb-select  class="weer-input" id="weergesteldheid" formControlName="weergesteldheid">
                <nb-option *ngFor="let weertoestand of simpeleWeersOmstandigheden" value="{{weertoestand}}">{{weertoestand}}</nb-option>
              </nb-select>
            </div>
          </div>
          <div *ngIf="!isLoadingWeather" style="margin-right: 0 !important;" class="flexColumn">

            <div class="form-row">
              <label style="width:97px !important;" for="voormiddag">Voormiddag</label>
              <nb-select class="weer-input" id="voormiddag" formControlName="toestandVoormiddag">
                <nb-option *ngFor="let weertoestand of simpeleWeersOmstandigheden" value="{{weertoestand}}">{{weertoestand}}</nb-option>
              </nb-select>
            </div>

            <div class="form-row">
              <label style="width:97px !important;"  for="namiddag">Namiddag</label>
              <nb-select class="weer-input" id="namiddag" formControlName="toestandNamiddag">
                <nb-option *ngFor="let weertoestand of simpeleWeersOmstandigheden" value="{{weertoestand}}">{{weertoestand}}</nb-option>
              </nb-select>
            </div>
            <div class="form-row">
              <label style="width:97px !important;"  for="vorige-nacht">Vorige nacht/weekend</label>
              <nb-select class="weer-input" id="vorige-nacht" formControlName="toestandVorigeNacht">
                <nb-option *ngFor="let weertoestand of simpeleWeersOmstandigheden" value="{{weertoestand}}">{{weertoestand}}</nb-option>
              </nb-select>
            </div>
          </div>
          <div *ngIf="isLoadingWeather" style="max-width: 520px !important;align-items: center; margin-right: 0px !important;padding-left: 40px;" class="flexColumn">
            <div  class="rowFlex5">
              <i style="font-size: 40px" class="fa fa-cloud-sun"></i>
              <p style="font-size: 18px;margin:0; padding:0 10px">Weerdata ophalen..</p>
              <mat-progress-spinner [mode]="'indeterminate'" [diameter]="35" ></mat-progress-spinner>
            </div>
            <p style="width: 100%;margin-top: 30px" >Indien dit blijft laden, gelieve het ADRES van deze werf in stellen bij<br> 'Materieel & werven' -> 'Werf bewerken' -> 'Adres/locatie'</p>
          </div>


          </div>


      <div style="width:1050px !important;" class="summary-container" formArrayName="werkzaamheden">
          <h3 style="width:100%;font-weight:500;margin-bottom:2px !important;margin-top:-10px;margin-left: 10px"><i class="fa fa-person-digging faIconBigger"></i> Uitgevoerde activiteiten</h3>
          <div class="werkzaamheidRow" *ngFor="let control of werkzaamhedenControls; let i = index">
                <i  (click)="removeWerkzaamheid(i)" *ngIf="werkzaamhedenControls.length - 1 !== i" class="fas fa-circle-minus minus-icon"></i>
            <div class="emptyWerkDiv" *ngIf="werkzaamhedenControls.length - 1 === i"></div>
            <input nbInput
                   [formControl]="control"
                   style="width: 700px !important;max-width:700px !important; height: auto;" />
          </div>
        <button type="button" (click)="addWerkzaamheid()" mat-raised-button color="primary" class="addWerkButton btn btn-primary">
          <i class="fas fa-plus"></i> Voeg extra activiteit toe
        </button>
      </div>

      <div style="padding:4px !important;" *ngIf="photoControls.length !== 0" class="summary-container" >
        <div class="fotoContainer">
            <p class="section-title">Extra foto's <small style="font-size: 10px;">*Komen voorlopig nog niet op de PDF bij selectie, alleen in excel (niet nuttig)</small></p>
            <div class="fotoRow" formArrayName="photos">
              <div  *ngFor="let photo of photoControls;let i = index" [formGroupName]="i">
                <div class="fotoColumn">
                  <div class="innerColumn" >
                    <div class="addFotoDiv">
                      <label class="addFotoLabel" for="file">Voeg foto toe aan dagboek</label>
                      <nb-checkbox class="checkbox" id=file style="margin: 17px 0 0 10px;" formControlName="addedFoto"></nb-checkbox>
                    </div>
                    <input nbInput class="fotoInput" id="name" type="text" placeholder="Extra foto" formControlName="fotoNaam">

                    <img *ngIf="photo?.value?.foto != null" src="{{photo.value.foto}}" class="photo" name="photo" (click)="imagePopUp(photo.value.foto)">
                    <p style="margin-bottom: 30%;font-size: 18px;font-weight: 600" *ngIf="photo?.value?.foto == null">Geen foto</p>
                  </div>
                </div>
              </div>
            </div>
      </div>
      </div>
      <div class="names-container">
        <div class="names-section">
          <p class="names-title"><i class="fa fa-user faIconBig"></i>Arbeiders<input id="werkdag-nr" formControlName="arbeiderCount" nbInput type="number" class="person-short-input"> pers</p>
          <ul>
            <li  style="padding:6px 10px !important;" *ngFor="let arbeider of arbeiders">{{ arbeider.name }}</li>
          </ul>
        </div>
        <div class="names-section">
          <p class="names-title"><i class="fa fa-user faIconBig"></i>Machinisten  <input   formControlName="machinistenCount" nbInput type="number" class="person-short-input"> pers</p>
          <ul>
            <li  style="padding:6px 10px !important;" *ngFor="let machinist of machinisten">{{ machinist.name }}</li>
          </ul>
        </div>
        <div class="names-section">
          <p class="names-title"><i class="fa fa-truck faIconBig"></i>Chauffeurs  <input formControlName="chauffeursCount"  nbInput type="number" class="person-short-input"> pers</p>
          <ul>
            <li  style="padding:6px 10px !important;" *ngFor="let chauffeur of chauffeurs">{{ chauffeur?.name }}</li>
          </ul>
        </div>
        <div formArrayName="machines" class="vast-materiaal-section">
          <p class="names-title"><i class="fa fa-snowplow faIconBig"></i>Machines (<strong>{{machinesGetter().controls?.length}}</strong>)</p>
          <span (click)="addMachine()" *ngIf="machinesGetter().controls.length === 0" class="custom-icon">
                <i class="fas fa-plus plus-icon"></i>
               </span>
          <ul class="big-input-3">
            <li *ngFor="let machine of machinesGetter().controls; let i=index" [formGroupName]="i">
              <i (click)="removeMachine(i)" class="fas fa-minus-circle minus-icon"></i>
              <input nbInput type="text" placeholder="Naam.." (input)="onChangeMachine($event,i)" formControlName="naam" #machineAutoInput [nbAutocomplete]="auto1" class="machineInput" />
              <nb-autocomplete  (selectedChange)="clickAutoCompletemachine($event,i)" #auto1>
                <nb-option *ngFor="let option of observableFilteredMachines$ | async" [value]="option">
                  {{ option }}
                </nb-option>
              </nb-autocomplete>
              <input formControlName="aantal" nbInput type="number" class="person-short-input">
            </li>
          </ul>
          <span (click)="addMachine()" class="custom-icon">
                <i class="fas fa-plus plus-icon"></i>
               </span>
        </div>
        <div *ngIf="opmerkingen != null && opmerkingen.length !== 0"  class="materialen-section">
          <p class="names-title"><i class="fa fa-comment faIconBig"></i>Opmerkingen door arbeiders</p>
          <ul *ngFor="let opmerking of opmerkingen">
            <li>
              {{opmerking.arbeiderNaam}}:<strong> {{opmerking.opmerking}}</strong>
              <button style="margin-left: 10px"
                class="btn btn-primary mt-2"
                (click)="voegOpmerkingToeAanDagboek(opmerking.opmerking)">
                <i class="fa fa-plus-circle"></i>
                Toevoegen aan dagboek
              </button>
            </li>
          </ul>
        </div>

        <div class="names-section" >
          <p class="names-title"><i class="fa fa-handshake faIconBig"></i>Onderaannemers</p>
          <ng-container formArrayName="onderaannemerFirmas">
            <ul *ngFor="let onderaanneemFirma of onderaannemerFirmasControls;let i = index" [formGroupName]="i">
              <li style="padding: 6px 10px !important;">
                <i (click)="removeOnderaannemerFirma(i)" class="fas fa-minus-circle smaller-minus-icon"></i>
                <strong>Firma: {{ onderaanneemFirma.value.firmaNaam }}</strong>
              </li>
              <li style="padding: 6px 10px !important;"><input formControlName="arbeiderCount" nbInput type="number" class="person-short-input"> pers</li>
              <ng-container *ngIf="onderaanneemFirma.value.arbeiders != null && onderaanneemFirma.value.arbeiders.length !== 0">
                <li style="padding: 6px 10px !important;" *ngFor="let arbeider of onderaanneemFirma.value.arbeiders">
                  {{ arbeider.name }}
                </li>
              </ng-container>
            </ul>
          </ng-container>
          <ul>
            <li>
              Extra onderaannemer firma<span (click)="addOnderaannemerFirma()" style="position:initial !important;"  class="custom-icon">
                <i class="fas fa-plus plus-icon"></i>
               </span>
              <input placeholder="Naam.." formControlName="extraOnderaannemerFirma" nbInput type="text" class="onderaannemerInput">
            </li>
          </ul>
        </div>
        <div class="vast-materiaal-section" formArrayName="materialen">
          <p class="names-title"><i class="fa fa-cube faIconBig"></i>Materialen (<strong>{{materialenControls.length}}</strong>)</p>
          <ul>
            <li [formGroupName]="i" class="big-input-3" *ngFor="let control of materialenControls; let i = index">
              <i (click)="removeMateriaal(i)"   class="fas fa-minus-circle minus-icon"></i>
              <input formControlName="naam" placeholder="Naam.."  nbInput type="text" class="materiaal-input">
              <input formControlName="hoeveelheid" placeholder="Hoeveelh.." nbInput type="number" class="medium-input">
            </li>
          </ul>
          <span (click)="addMateriaal()" class="custom-icon">
                <i class="fas fa-plus plus-icon"></i>
               </span>
        </div>
        <div class="vast-materiaal-section" formArrayName="vastMaterieelOpWerf">
          <p class="names-title">Permanent materieel op de werf<small> (bv. een elektriciteitskast) Wordt automatisch overgenomen in het volgende dagboek</small> (<strong>{{vasteMaterieelOpWerfControls?.length }}</strong>)</p>
          <ul>
            <li class="big-input-3" *ngFor="let control of vasteMaterieelOpWerfControls;let i = index" [formGroupName]="i">
                <i (click)="removeVastMateriaalOpWerf(i)" *ngIf="vasteMaterieelOpWerfControls.length >= 1"  class="fas fa-minus-circle minus-icon"></i>
              <input formControlName="naam"  placeholder="Naam.." nbInput type="text" class="machineInput">
              <input formControlName="aantal" placeholder="Aantal.."  nbInput type="number" class="person-short-input">
            </li>
          </ul>
          <div (click)="addVastMaterieelOpWerf()" class="custom-icon">
                <i class="fas fa-plus plus-icon"></i>
          </div>
        </div>
      </div>
      <div class="extraTextOuterDiv">
        <div class="extraTextRow">
          <p class="extraText"><i class="fa fa-comment faIconBig"></i>Opmerkingen</p>
          <textarea nbInput (input)="limitLines($event)" formControlName="opmerkingen"  class="opmerkingInput"></textarea>
        </div>
        <div class="extraTextRow">
          <p class="extraText"><i class="fa fa-flask-vial faIconBig"></i>Monstername - proeven</p>
          <textarea nbInput (input)="limitLines($event)"  formControlName="monsternameProeven"  class="opmerkingInput"></textarea>
        </div>
        <div class="extraTextRow">
          <p class="extraText"><i class="fa fa-check-circle faIconBig"></i>Getroffen beslissingen</p>
          <textarea nbInput (input)="limitLines($event)"  formControlName="getroffenBeslissingen" class="opmerkingInput"></textarea>
        </div>
        <div class="extraTextRow">
          <p class="extraText">Bezoeken - allerlei</p>
          <textarea nbInput (input)="limitLines($event)"  formControlName="bezoekenAllerlei" class="opmerkingInput"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>

