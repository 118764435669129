import {Werf} from "./werf";


export class StaatDerVerlet {
  public _id: string;
  weerverletDagen:number;
  ontoegankelijkheidDagen: number;
  schorsingsDagen: number;
  verlofDagen: number;
  feestDagen: number;
  werkDagen: number;
  verletDagen: number;
  totaleWeerverletDagen: number;
  totaleOntoegankelijkheidDagen: number;
  totaleSchorsingsDagen: number;
  totaleVerlofDagen: number;
  totaleFeestDagen: number;
  totaleWerkDagen: number;
  totaleVerletDagen: number;
  date: Date;
  dagboekBladNummer: number;
  company_id: string;
  werf: Werf | string;
  month: number;
  year: number;
  vorderingStaatNr: number;
  isLocked: boolean;

  constructor() {
  }
}
