import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import {
  FormArray,
  FormBuilder,
  FormControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import {BehaviorSubject, firstValueFrom, forkJoin, Observable, of} from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import {Werf} from "../../models/werf";
import {ApiService} from "../../services/api.service";
import {Company} from "../../models/company";
import {FormService} from "../../services/form.service";
import {Transportdagrapport} from "../../models/transport-dagrapport";
import {User} from "../../models/user";
import {Dagrapport} from "../../models/dagrapport";
import {NbToastrService, NbTooltipModule} from "@nebular/theme";
import {Machine} from "../../models/machine";
import {DagboekExcelService} from "../../services/DagboekExcelService";
import {DagboekDerWerken} from "../../models/dagboek-der-werken";
import {DayWeatherData} from "../../models/day-weather-data";
import {Materiaal} from "../../models/materiaal";
import {StaatDerVerlet} from "../../models/staatDerVerlet";
import {OnderaannemerFirma} from "../../models/onderaannemerFirma";
import {SaveTotalenDialogComponent} from "./save-totalen-dialog/save-totalen-dialog.component";
import {PhotoPopupDialog} from "../dagrapporten/werk-edit/photo-popup/photo-popup.component";
import {DagboekPdfDialogComponent} from "./dagboek-pdf-dialog/dagboek-pdf-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {VasteVerlofdagenBouwService} from "../../services/vasteVerlofdagenBouw.service";
import {ExtraInfoWeerverletBepaling} from "./extraInfoWeerverletBepaling";
import moment from "moment";
import {
  faArrowLeftFromBracket,
  faArrowRightLong,
  faCalendarClock,
  faCloudHailMixed,
  faClouds, faCloudSun, faCross, faFloppyDisk, faGear, faGift, faHashtag, faLock, faPause,
  faPersonDigging, faRoadBarrier, faTrash, faUmbrellaBeach, faXmark
} from "@awesome.me/kit-1d20bf365f/icons/modules/classic/solid";
import {faSun} from "@awesome.me/kit-1d20bf365f/icons/modules/classic/solid";
import {faTimes} from "@awesome.me/kit-1d20bf365f/icons/modules/classic/regular";
import {
  DeleteDialogWerkzaamheid
} from "../materieel-instellen/werkzaamheden/werkzaamheden-edit/werkzaamheden-edit.component";

class DayInMonth {
  date: Date;
  day: string;
  isoDate: string;
  werkenCount: number;
  soort: string;
  hasDagboek: boolean;
  werkUren: string;
  dagboek: DagboekDerWerken;
  isEmpty: boolean;
  extraInfoOverIsWeerverlet: string;
}
interface Photo {
  fotoNaam: string;
  foto: string;
  addedFoto: boolean;
}
@Component({
  selector: 'ngx-staat-der-verletdagen',
  templateUrl: './staat-der-verletdagen.component.html',
  styleUrls: ['./staat-der-verletdagen.component.scss', '../../styles/item-table.scss']
})
export class StaatDerVerletdagenComponent implements OnInit {
  @ViewChild('breakAfterElement') breakAfterElement: ElementRef;

  @ViewChild('autoInput') input;
  @ViewChild('machineAutoInput') machineInput : ElementRef;
  nederlandseWeerBeschrijvingen = [
    "Zonnig",
    "Helder",
    "Gedeeltelijk bewolkt",
    "Bewolkt",
    "Mistig",
    "Mogelijk regenachtig",
    "Mogelijk sneeuwachtig",
    "Mogelijk ijzelachtig",
    "Mogelijk ijzelachtige motregen",
    "Mogelijk onweerachtige uitbraken",
    "Sneeuwstorm",
    "Sneeuwstorm",
    "Mist",
    "Bevriezende mist",
    "Lichte motregen mogelijk",
    "Lichte motregen",
    "IJzel",
    "Zware ijzel",
    "Mogelijk matige sneeuwval",
    "Matige sneeuwval",
    "Zware sneeuwval",
    "Ijzelkorrels",
    "Lichte regenbui",
    "Gemiddelde of zware regenbui",
    "Stortbui van regen"
  ];
  engelseWeerBeschrijving = [
    "Sunny",
    "Clear",
    "Partly cloudy",
    "Overcast",
    "Mist",
    "Patchy rain possible",
    "Patchy snow possible",
    "Patchy sleet possible",
    "Patchy freezing drizzle possible",
    "Thundery outbreaks possible",
    "Blowing snow",
    "Blizzard",
    "Fog",
    "Freezing fog",
    "Patchy light drizzle",
    "Light drizzle",
    "Freezing drizzle",
    "Heavy freezing drizzle",
    "Patchy moderate snow",
    "Moderate snow",
    "Heavy snow",
    "Ice pellets",
    "Light rain shower",
    "Moderate or heavy rain shower",
    "Torrential rain shower",
    "Light sleet showers",
    "Moderate or heavy sleet showers",
    "Light snow showers",
    "Moderate or heavy snow showers"
  ];
  public werfItem: Werf = new Werf('','');
  public werven: Array<Werf>;
  public isOn: Boolean;
  minTime: string = '06:00';
  maxTime: string = '20:00';
  searchForm = new UntypedFormControl();
  company: Company;
  filterSelect: any;
  filterItems: Werf[] = [];
  observableFilteredItems$: Observable<string[]>;
  isLoaded: boolean;
  currentDate: Date;
  maanden = ["Januari","Februari","Maart","April","Mei","Juni","Juli","Augustus","September", "Oktober","November","December"];
  jaren: number[] = [];
  maand: any;
  currentWerf: Werf;
  daysInMonth: BehaviorSubject<DayInMonth[]> = new BehaviorSubject<DayInMonth[]>(null);
  daysInMonth$: Observable<DayInMonth[]> = this.daysInMonth.asObservable();
  verletdagOpties: string[] = ['werkdag', 'weerverlet regen/vorst', 'terrein ontoegankelijk', 'schorsing der werken', 'verlofdag', 'feestdag', 'zaterdag/zondag'];
  days: string[] = ['Zondag','Maandag','Dinsdag','Woensdag','Donderdag','Vrijdag','Zaterdag'];
  private dagrapporten: Dagrapport[];
  private transportDagrapporten: Transportdagrapport[];
  isViewingDay: boolean;
  arbeiders: User[];
  chauffeurs: User[];
  onderaannemerFirmas: any[];
  machinisten: User[];
  machines: Machine[];
  staatForm: any;
  werkzaamheden: string[];
  opmerkingen: {arbeiderNaam: string, opmerking: string}[] = [];
  uploadForm: any;
  photos: any;
  weatherData: DayWeatherData[];
  _id: string;
  currentDagboek: DagboekDerWerken;
  todayDate: Date;
  tomorrowDate: Date;
  isSaving: boolean;
  allMachines: Machine[];
  allMaterialen: Materiaal[];
  currentMachineIndex: number;
  observableFilteredMachines$: Observable<string[]>;
  machineStrings: string[];
  gebruikteMaterialen: Materiaal[];
  nextDagboekDate: Date;
  nextDagboekWithNumbers: DagboekDerWerken;
  dagboekenOfMonth: DagboekDerWerken[];
  lastDayOfMonth: number;
  dataOfMonthByDay: any[];
  simpeleWeersOmstandigheden: string[] = ['Droog','Lichte regen','Regen'];
  totalPossibleDayCount: number;
  currentCount: number;
  geenStartDatumOpWerf: boolean;
  isPrint: boolean;
  dagboekenOfMonthByDay: DagboekDerWerken[];
  deleteDagboekForm: any;
  originalDagboekDeleteArray: string[];
  canDeleteDagboeken: boolean;
  isGeneratingPidpaPdf:boolean;
  jaar: any;
  isDeleteDagboek: boolean;
  protected readonly Date = Date;
  kopieerDagboekSelect: any;
  kopieerDagboeken: DagboekDerWerken[];
  firstDagboek: DagboekDerWerken;
  latestDagboek: DagboekDerWerken
  showWervenOfWerfleider: boolean;
  actieveWerven: Werf[];
  wervenOfThisWerfleider: Werf[];
  isLoadingWeather: boolean = false;
  currentIsoDate: string;
  extraInfoOverIsWeerverlet: string;
  mmRegenOvernacht: number;
  isErrorByWeatherStack: boolean = false;
  hasAllDagboekenOfMonth: boolean = false;
  currentStaatDerVerlet: StaatDerVerlet;
  isSavingStaatDerVerlet: boolean;
  isAllowedToDeleteStaatDerverletOfThisMonth: boolean;
  isViewingWeerVoorspelling: boolean;
  wervenWithWeerVoorspelling: Werf[];

  constructor(
    private apiService: ApiService,
    private router: Router,
    public formService: FormService,
    private toastrService: NbToastrService,
    private formBuilder: FormBuilder,
    private dagboekExcelService: DagboekExcelService,
    private dialog: MatDialog,
    private vasteVerlofDagenBouw: VasteVerlofdagenBouwService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoaded = false;
    this.isViewingDay = false;
    this.isSaving = false;
    this.isPrint = false;
    this.isGeneratingPidpaPdf = false;
    this.isDeleteDagboek = false;
    this.isSavingStaatDerVerlet = false;
    this.hasAllDagboekenOfMonth  = false;
    this.isViewingWeerVoorspelling = false;
    this.todayDate = new Date();
    this.tomorrowDate = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth(), this.todayDate.getDate() + 1);


    //push de volgende 5 jaren
    for(let i = 0; i < 6; i++){
      this.jaren.push(this.todayDate.getFullYear() + i - 2);
    }
    this.canDeleteDagboeken = false;
    this.currentDate = new Date(this.todayDate);
    this.getWervenWithLatestDagboek();
    this.company = this.apiService.thisCompany;
    if(this.company == null){
      this.apiService.getCompany().subscribe(x => {
        this.company = x as Company;
        if(this.company._id == null)this.company._id = this.company.id;
      })
    }

    if(this.formService.latestWerf != null){
      this.currentWerf = this.formService.latestWerf;
      if(this.formService.latestWerf.startDatum == null){
        this.geenStartDatumOpWerf = true;
      } else {
        this.currentWerf.startDatum = new Date(this.currentWerf.startDatum);
        this.geenStartDatumOpWerf = false;
      }
      this.currentDate = new Date(this.formService.latestYear, this.formService.latestMonth, 1);
      this.maand = this.maanden[this.currentDate.getMonth()];
      this.jaar = this.currentDate.getFullYear();
      await this.calculateDaysInMonth(this.currentDate.getMonth(), this.currentDate.getFullYear());
      this.isLoaded = true;
      this.input.nativeElement.value = this.currentWerf.naam;
      this.clickAutoCompleteWerf(this.currentWerf.naam);
    } else {
      //reload zonder selected werf
      this.maand = this.maanden[this.todayDate.getMonth()];
      this.jaar = this.todayDate.getFullYear();
      await this.calculateDaysInMonth(this.todayDate.getMonth(), this.todayDate.getFullYear());
      this.formService.latestMonth = this.todayDate.getMonth();
      this.formService.latestYear = this.todayDate.getFullYear();
       this.showWervenOfWerfleider = true;
    }

}

  getWervenWithLatestDagboek() {
    if(this.werven == null){
      this.apiService.getWervenWithLatestDagboek().subscribe(async x => {
        this.werven = x as Werf[];
        this.filterItems = [...this.werven,...[]];
        this.filterItems.sort((a, b) => a.naam.localeCompare(b.naam));
        this.observableFilteredItems$ = of(this.filterItems.map(x => x.naam));

        this.actieveWerven = this.werven
          .map(werf => ({
            ...werf,
            startDatum: new Date(werf.startDatum),
            dagboekenAangevuldTot: werf.latestDagboek?.date != null ? new Date(werf.latestDagboek?.date): null,
            isNogNietGestart: new Date(werf.startDatum).getTime() > this.todayDate.getTime(),
            isWerfleiderOfThisWerf: werf.werfleiders.some(werfleider => werfleider?._id === this.apiService.userId)
          }))
          .sort((a,b) => {
              if(!b.isNogNietGestart && a.isNogNietGestart) return 1;
              if(b.isNogNietGestart && !a.isNogNietGestart) return -1;
              if(!a.isWerfleiderOfThisWerf && b.isWerfleiderOfThisWerf) return 1;
              if(a.isWerfleiderOfThisWerf && !b.isWerfleiderOfThisWerf) return -1;
            if(a.gemeente == null || a.gemeente === '') return 1;
            if(b.gemeente == null || b.gemeente === '') return -1;
            return a.gemeente.localeCompare(b.gemeente);
            });
        this.wervenOfThisWerfleider = this.actieveWerven.filter(x => x.isWerfleiderOfThisWerf);
      });
    }
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.filterItems?.filter(optionValue => optionValue.naam.toLowerCase().includes(filterValue)).map(optionValue => optionValue.naam);
  }

  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onChange() {
    this.observableFilteredItems$ = this.getFilteredOptions(this.input.nativeElement.value);
  }

  clearAutocomplete() {
    this.input.nativeElement.value = '';
    this.weatherData = null;
    this.filterItems = this.werven;
    this.observableFilteredItems$ = of(this.filterItems?.map(x => x.naam));
    this.showWervenOfWerfleider = true;
    this.currentWerf = null;
    this.isErrorByWeatherStack = false;
    this.isViewingDay = false;

    this.isLoaded = false;
  }

  formatDateToHourAndMinute(date: Date){
    return ('0' + date.getHours()).slice(-2) + ':' + ('0' + (date.getMinutes())).slice(-2);
  }
 async reloadList() {
   this.canDeleteDagboeken = false;
   this.isViewingDay = false;
   this.isLoaded = false;
   this.isSaving = false;
   this.hasAllDagboekenOfMonth = false;
   this.opmerkingen = [];
   this.dagboekenOfMonth = [];
   this.weatherData = [];
   this._id = null;
   this.isErrorByWeatherStack = false;
   this.todayDate = new Date();
   this.tomorrowDate = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth(), this.todayDate.getDate() + 1);
   this.calculateDaysInMonth(this.currentDate.getMonth(), this.currentDate.getFullYear());
   forkJoin({
     totalActiviteits: this.apiService.getTotalActiviteitsOfDaysOfMonth(this.currentWerf._id, this.currentDate.getMonth(), this.currentDate.getFullYear()),
     dagboeken: this.apiService.getDagboekenOfMonth(this.currentWerf._id, this.currentDate.getMonth(), this.currentDate.getFullYear()),
     staatDerVerletten: this.apiService.getStaatDerVerletOfMonthAndNextMonth(this.currentWerf._id, this.currentDate.getMonth(), this.currentDate.getFullYear())
   }).subscribe(async ({ totalActiviteits, dagboeken, staatDerVerletten }) => {
     let dagrapporten = totalActiviteits as Dagrapport[];
     let tempDaysInMonth = this.daysInMonth.getValue();
     if(staatDerVerletten != null && staatDerVerletten[0]?._id != null){
       this.currentStaatDerVerlet = staatDerVerletten[0] as StaatDerVerlet
       if(staatDerVerletten.length === 2){
         let volgendeStaatDerVerlet = staatDerVerletten[1] as StaatDerVerlet;
         if(volgendeStaatDerVerlet != null){
           this.isAllowedToDeleteStaatDerverletOfThisMonth = false;
         }
         this.currentStaatDerVerlet = staatDerVerletten[0] as StaatDerVerlet;
       } else {
         this.isAllowedToDeleteStaatDerverletOfThisMonth = true;
       }
     } else {
       this.isAllowedToDeleteStaatDerverletOfThisMonth = false;
        this.currentStaatDerVerlet = null;
     }
     for(let dagrapport of dagrapporten) {
       dagrapport.date = new Date(dagrapport.date);
       dagrapport.date.setHours(4);
       dagrapport.werken = dagrapport.werken.filter(x => x.werkzaamheid?.naam != null && x.deleted === false);
       let isoDate = dagrapport.date.toISOString().split('T')[0];
       let daysIndex = tempDaysInMonth.findIndex(x => x.isoDate === isoDate);
       if (daysIndex !== -1) {
         tempDaysInMonth[daysIndex].werkenCount = dagrapport.werken != null ? dagrapport.werken.length : 0;
         if(dagrapport.startDate != null && dagrapport.endDate != null){
           dagrapport.startDate = new Date(dagrapport.startDate);
           dagrapport.endDate = new Date(dagrapport.endDate);
           tempDaysInMonth[daysIndex].werkUren = this.calculateWorkHours(dagrapport.startDate, dagrapport.endDate);
         }
       }
     }
     let realDagboeken = dagboeken as DagboekDerWerken[];
     for(let dagboek of realDagboeken) {
       dagboek.date = new Date(dagboek.date);
       dagboek.date.setHours(4);
       let isoDate = dagboek.date.toISOString().split('T')[0];
       let daysIndex = tempDaysInMonth.findIndex(x => x.isoDate === isoDate);
       if (daysIndex !== -1) {
         tempDaysInMonth[daysIndex].hasDagboek = true;
         tempDaysInMonth[daysIndex].werkenCount = dagboek.werkzaamheden.length;
          tempDaysInMonth[daysIndex].soort = dagboek.soortVerlet;
         tempDaysInMonth[daysIndex].werkUren = dagboek.werkuren;
          tempDaysInMonth[daysIndex].dagboek = dagboek;

          //tijdelijke code 16/06/2024 voor opslag van onderaannemersCount en machinesCount op het dagboek. mag weg vanaf 2025
          if(tempDaysInMonth[daysIndex].dagboek.onderaannemersCount == null){
            tempDaysInMonth[daysIndex].dagboek.onderaannemersCount = 0;
            let onderaannemerFirmas = tempDaysInMonth[daysIndex].dagboek.onderaannemerFirmas;
            if(onderaannemerFirmas != null && onderaannemerFirmas.length > 0){
              for(let onderaannemerFirma of onderaannemerFirmas){
                if(onderaannemerFirma != null){
                  tempDaysInMonth[daysIndex].dagboek.onderaannemersCount += onderaannemerFirma.arbeiderCount;
                }
              }
            }
          }
          if(tempDaysInMonth[daysIndex].dagboek.machinesCount == null){
            tempDaysInMonth[daysIndex].dagboek.machinesCount = 0;
            let machines = tempDaysInMonth[daysIndex].dagboek.machines;
            if(machines != null && machines.length > 0){
              for(let machine of machines){
                if(machine != null){
                  tempDaysInMonth[daysIndex].dagboek.machinesCount += machine.aantal;
                }
              }
            }
          }
       }
     }
    if(realDagboeken.length > 0){
      this.dagboekenOfMonth = realDagboeken;
    }

    if(tempDaysInMonth != null && tempDaysInMonth[tempDaysInMonth.length - 1].hasDagboek){
      this.hasAllDagboekenOfMonth = true;
    } else {
      this.hasAllDagboekenOfMonth = false;
    }

    this.apiService.getLatestDagboekOfWerf(this.currentWerf._id).subscribe( x => {
        this.nextDagboekWithNumbers = x as DagboekDerWerken;
        if(this.nextDagboekWithNumbers?.toString() === 'Geen dagboek gevonden'){
          let startDatum = new Date(this.currentWerf.startDatum);
          let lastDayDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0,2);
          if(this.currentWerf.startDatum != null && startDatum.toISOString().split('T')[0] <= lastDayDate.toISOString().split('T')[0]) {
            this.nextDagboekDate = startDatum;
          } else {
            this.nextDagboekDate = null;
          }
            for(let tempDay of tempDaysInMonth){
              if(tempDay.date.getTime() < this.currentWerf.startDatum?.getTime()){
                tempDay.soort = 'vroegereDatum';
              } else if(tempDay.date.getTime() > this.todayDate.getTime()){
                tempDay.soort = 'latereDatum';
              }
            }
        } else {
          this.latestDagboek = this.nextDagboekWithNumbers;
          for(let tempDay of tempDaysInMonth){
            if(tempDay.date.getTime() < this.currentWerf.startDatum?.getTime()){
              tempDay.soort = 'vroegereDatum';
            } else if(tempDay.date.getTime() > this.todayDate.getTime()){
              tempDay.soort = 'latereDatum';
            }
          }
          this.nextDagboekWithNumbers.date = new Date(this.nextDagboekWithNumbers.date);
          if(this.nextDagboekWithNumbers.date.getDay() === 5){
            this.nextDagboekDate = new Date(this.nextDagboekWithNumbers.date.getFullYear(), this.nextDagboekWithNumbers.date.getMonth(), this.nextDagboekWithNumbers.date.getDate() + 3,4);
          } else {
            this.nextDagboekDate = new Date(this.nextDagboekWithNumbers.date.getFullYear(), this.nextDagboekWithNumbers.date.getMonth(), this.nextDagboekWithNumbers.date.getDate() + 1,4);
          }
          this.nextDagboekWithNumbers.date = new Date(this.nextDagboekDate)
          this.currentCount = 1;
        }

      this.totalPossibleDayCount = 0;

      let fromDate ;
      let toDate;
      //uitrekenen count goedkeuring
      for(let j = 1;j <= this.lastDayOfMonth; j++){
        if(this.todayDate.getTime() > new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), j,1).getTime()){
          let tempDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(),j,7);
          let isoDate = tempDate.toISOString().split('T')[0];
          if(this.dagboekenOfMonth?.find(x => x.isoDate === isoDate) == null && tempDate.getDay() !== 6 && tempDate.getDay() !== 0 &&
            this.currentWerf.startDatum?.getTime() <= tempDate.getTime()){
            if(fromDate == null){
              fromDate = new Date(tempDate);
            }
            toDate = tempDate;
            this.totalPossibleDayCount++;
          }
        }
      }
      this.currentCount = 1;
      if(this.totalPossibleDayCount != null && this.totalPossibleDayCount !== 0 &&
        (this.weatherData == null || this.weatherData.length !== this.totalPossibleDayCount) && fromDate != null && toDate != null){
        this.getAndSetWeatherDetails(fromDate, toDate);
      }
      });

     this.daysInMonth$ = this.daysInMonth.pipe(
       map(daysArray => {
         // Calculate the index of the first day of the month
         const firstDayIndex = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), tempDaysInMonth[0].date.getDate()).getDay();

         // Create an array of empty slots with a special flag
         const emptySlots = new Array(Math.max(0, firstDayIndex - 1)).fill({ isEmpty: true });

         // Merge the emptySlots array with the actual daysArray
         return [...emptySlots, ...daysArray];
       })
     );
     this.deleteDagboekForm = this.formBuilder.group({
     });
     let dagboekDeleteArray = [];
     for(let day of this.daysInMonth.getValue()){
       if(day.hasDagboek){
         dagboekDeleteArray.push(day.isoDate);
         this.deleteDagboekForm.addControl(day.isoDate, new FormControl(true));
       }
     }

     this.kopieerDagboeken = [];
     this.originalDagboekDeleteArray = dagboekDeleteArray;
     if(this.dagboekenOfMonth != null && this.dagboekenOfMonth.length !== 0){
       this.kopieerDagboeken = Object.assign([],this.dagboekenOfMonth).reverse();
       this.kopieerDagboekSelect = this.kopieerDagboeken[0]._id;
       this.isLoaded = true;
     }


     //vorige maand dagboeken toevoegen voor kopieren dagboek bij nieuwe maand
     if(this.dagboekenOfMonth == null || this.dagboekenOfMonth.length < 9){
       let date = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1, 1);
       this.apiService.getDagboekenOfMonth(this.currentWerf._id, date.getMonth() , date.getFullYear()).subscribe(x => {
         let dagboeken = x as DagboekDerWerken[];
         if(dagboeken != null && dagboeken.length !== 0){
           for(let dagboek of dagboeken){
              dagboek.date = new Date(dagboek.date);
           }
           dagboeken = dagboeken.reverse();
           this.kopieerDagboeken = [...this.kopieerDagboeken, ...dagboeken];
           if(this.dagboekenOfMonth == null ||this.dagboekenOfMonth.length === 0){
             this.kopieerDagboekSelect = this.kopieerDagboeken[0]._id;
           }
           this.isLoaded = true;
         } else {
           this.isLoaded = true;
         }
       });
     }

   });
 }
  async clickAutoCompleteWerf($event) {
    let werfName = $event;
    this.weatherData = null;

    while(this.filterItems == null || this.filterItems.length === 0){
      await this.delay(20);
    }
    if(werfName != null && werfName !== ''){
      let werf = this.werven.find(x => x.naam === this.input.nativeElement.value);
      if(werf != null){
        this.currentWerf = werf;
        if(werf.startDatum == null){
          this.geenStartDatumOpWerf = true;
        } else {
          this.currentWerf.startDatum = new Date(this.currentWerf.startDatum);
          this.geenStartDatumOpWerf = false;
        }
        this.showWervenOfWerfleider = false;
        this.formService.latestWerf = werf;
        this.apiService.getFirstAndLatestDagboek(this.currentWerf._id).subscribe(x => {
          let response = x as any;
          if(response?.firstDagboek?.date != null){
            let firstDagboek = response.firstDagboek as DagboekDerWerken;
            if(firstDagboek.date != null)firstDagboek.date = new Date(firstDagboek.date);
            let latestDagboek = response.latestDagboek as DagboekDerWerken;
            if(latestDagboek.date != null){
              latestDagboek.date = new Date(latestDagboek.date);
              //check if lastdagboek.date is the last day of the month without weekends
              let lastDay = new Date(latestDagboek.date.getFullYear(), latestDagboek.date.getMonth() + 1, 0);
              let lastDayIndex = lastDay.getDay();
              let incrementDecrement = 0;
              if(lastDayIndex === 0){
                incrementDecrement = 2;
                lastDay.setDate(lastDay.getDate() - incrementDecrement);
              } else if(lastDayIndex === 6){
                incrementDecrement = 1;
                lastDay.setDate(lastDay.getDate() - incrementDecrement);
              }
              if(latestDagboek.date.getDate() === lastDay.getDate()){
                 latestDagboek.date.setDate(latestDagboek.date.getDate() + incrementDecrement + 1);
              }
              this.currentDate = new Date(latestDagboek.date);
              this.maand = this.maanden[this.currentDate.getMonth()];
              this.jaar = this.currentDate.getFullYear();
            } else if(werf.startDatum != null){
              this.currentDate = new Date(werf.startDatum);
              this.maand = this.maanden[this.currentDate.getMonth()];
              this.jaar = this.currentDate.getFullYear();
            }
            this.firstDagboek = firstDagboek;
            this.latestDagboek = latestDagboek;

          } else {
            this.firstDagboek = null;
            this.latestDagboek = null;
            if(werf.startDatum != null){
              this.currentDate = this.currentWerf.startDatum;
              this.maand = this.maanden[this.currentDate.getMonth()];
              this.jaar = this.currentDate.getFullYear();
            }
          }
          this.reloadList();
        });
      }
    }
  }
  dateToString(date: Date){
    return date.getDate() + ' ' + this.maanden[date.getMonth()].substring(0,3) + ' ' + date.getFullYear().toString().slice(-2);
  }
  checkJaar($event: any) {
    this.isLoaded = false;
    this.weatherData = null;
    if($event > this.currentDate.getFullYear()){
      this.maand = this.maanden[0];
      this.currentDate = new Date($event,0 , 1);
    } else if ($event < this.currentDate.getFullYear()) {
      this.maand = this.maanden[11];
      this.currentDate = new Date($event,11 , 1);
    }
    this.updateFormService();
    this.reloadList();
  }

  checkMaand($event: any) {
    this.isLoaded = false;
    this.weatherData = null;
    let maandIndex = this.maanden.indexOf($event);
    this.currentDate = new Date(this.currentDate.getFullYear(), maandIndex, 1);
    this.updateFormService();
    this.reloadList();
  }

  previousMonth() {
    this.isLoaded = false;
    this.weatherData = null;
    let currentIndex = this.maanden.indexOf(this.maand);
    if (currentIndex > 0) {
      this.maand = this.maanden[currentIndex - 1];
      this.currentDate = new Date(this.currentDate.getFullYear(), currentIndex - 1, 1);
    } else {
      this.maand = this.maanden[this.maanden.length - 1];
      this.currentDate = new Date(this.currentDate.getFullYear() - 1, 11, 1);
    }
    this.jaar = this.currentDate.getFullYear();
    this.updateFormService();
    this.reloadList();
  }

  nextMonth() {
    this.isLoaded = false;
    this.weatherData = null;
    let currentIndex = this.maanden.indexOf(this.maand);
    if (currentIndex < this.maanden.length - 1) {
      this.maand = this.maanden[currentIndex + 1];
      this.currentDate = new Date(this.currentDate.getFullYear(), currentIndex + 1, 1);
    } else {
      this.maand = this.maanden[0];
      this.currentDate = new Date(this.currentDate.getFullYear() + 1, 0, 1);
    }
    this.jaar = this.currentDate.getFullYear();
    this.updateFormService();
    this.reloadList();
  }
  private updateFormService() {
    this.formService.latestWerf = this.currentWerf;
    this.formService.latestMonth = this.currentDate.getMonth();
    this.formService.latestYear = this.currentDate.getFullYear();
  }
 async calculateDaysInMonth(month: number, year: number) {
    const datesObjects: any[] = [];
    while(this.company == null){
      await this.delay(50);
    }
    let eigenVerlofDagen = this.company.verlofDagen as Array<{fromDate: Date, toDate: Date}>;
    // Get the number of days in the month
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    // Loop through the days of the month and generate Date objects
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      // Reset the time to 0 hour, 0 minute, 0 seconds
      date.setHours(4, 0, 0, 0);
      if(date.getDay() !== 0 && date.getDay() !== 6){
        let soort;
        let isoDate = date.toISOString().split('T')[0];
        if(year === 2023){
          if(this.vasteVerlofDagenBouw.rustDagenBouw2023.find(x =>x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else if(this.vasteVerlofDagenBouw.feestDagen2023.find(x =>x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else {
            soort = 'werkdag';
          }
          if(date.getTime() > this.todayDate.getTime()){
            soort = 'latereDatum';
          }
        } else if(year === 2024){
          if(this.vasteVerlofDagenBouw.rustDagenBouw2024.find(x => x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else if(this.vasteVerlofDagenBouw.feestDagen2024.find(x => x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else {
            soort = 'werkdag';
          }
          if(date.getTime() > this.todayDate.getTime()){
            soort = 'latereDatum';
          }
        }  else if(year === 2025){
          if(this.vasteVerlofDagenBouw.rustDagenBouw2025.find(x => x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else if(this.vasteVerlofDagenBouw.feestDagen2025.find(x => x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else {
            soort = 'werkdag';
          }
          if(date.getTime() > this.todayDate.getTime()){
            soort = 'latereDatum';
          }
        }
        if(eigenVerlofDagen != null && eigenVerlofDagen.length !== 0){
          let verlofDag = eigenVerlofDagen.find(x => {
            let fromIso = x.fromDate ? new Date(x.fromDate).toISOString().split('T')[0] : null;
            let toIso = x.toDate ? new Date(x.toDate).toISOString().split('T')[0] : null;

            if (toIso == null) {
              return fromIso === isoDate;
            } else {
              return fromIso <= isoDate && isoDate <= toIso;
            }
          });
          if (verlofDag) {
            soort = 'verlofdag';
          }
        }
        datesObjects.push({date: date, day: this.days[date.getDay()], soort: soort, werkenCount: 0,isoDate: date.toISOString().split('T')[0], werkUren: 0});
      }
    }
    this.lastDayOfMonth = new Date(year, month + 1, 0).getDate();
    //change to friday if the last day is saturday or sunday
     if(new Date(year, month + 1, 0).getDay() === 0){
        this.lastDayOfMonth = this.lastDayOfMonth - 2;
      } else if(new Date(year, month + 1, 0).getDay() === 6){
        this.lastDayOfMonth = this.lastDayOfMonth -1;
      }

    this.daysInMonth.next(datesObjects);
  }

  async bekijk(dayObject) {
    await this.delay(8);
    if(this.isDeleteDagboek || this.canDeleteDagboeken){
      return;
    }
    if(dayObject?.date == null || (dayObject.soort !== 'latereDatum' && dayObject.soort !== 'vroegereDatum')){
      let day = dayObject?.date;
      if(day == null){
        this.reloadList();

      } else {
        this.canDeleteDagboeken = false;
        let werfId = this.currentWerf._id;
        this.currentDate = day;
        let date = new Date(day.getTime());
        date.setHours(4);

        await this.getTotalsOfWerfPerDay(this.currentDate.toString(),werfId);
        let isoDate = date.toISOString().split('T')[0];
        this.apiService.getDagboekDerWerken(isoDate, werfId).subscribe(async x => {
          let dagboek = x as DagboekDerWerken;
          console.log(dagboek)
          if (dagboek && typeof dagboek === 'object') {
            this.currentDagboek = dagboek;
            this._id = dagboek._id;
            if(dagboek.startDate != null){
              dagboek.startDate = new Date(dagboek.startDate);
            }
            if(dagboek.endDate != null){
              dagboek.endDate = new Date(dagboek.endDate);
            }
            if((dagboek.werkuren == null || dagboek.werkuren === '') && dagboek.startDate != null && dagboek.endDate != null){
              dagboek.werkuren = this.calculateWorkHours(dagboek.startDate, dagboek.endDate);
            }
            this.staatForm = this.formBuilder.group({
              werkzaamheden: new FormArray([]),
              bladNummer: dagboek.bladNummer,
              werkDagNummmer: dagboek.werkDagNummmer,
              verletDagNummer: dagboek.verletDagNummer,
              weerverletNummer: dagboek.weerverletNummer,
              verlofDagNummer: dagboek.verlofDagNummer,
              feestDagNummer: dagboek.feestDagNummer,
              ontoegankelijkheidNummer: dagboek.ontoegankelijkheidNummer,
              schorsingNummer: dagboek.schorsingNummer,
              werkuren: dagboek.werkuren && dagboek.werkuren !== '0U' ? this.werkUrenToDate(dagboek.werkuren) : null,
              temp8h: dagboek.temp8h,
              temp13h: dagboek.temp13h,
              weergesteldheid: dagboek.weergesteldheid,
              regenGedurendeUren: dagboek.regenGedurendeUren,
              toestandVorigeNacht: dagboek.toestandVorigeNacht,
              toestandVoormiddag: dagboek.toestandVoormiddag,
              toestandNamiddag: dagboek.toestandNamiddag,
              arbeiderCount:  dagboek.arbeiderCount,
              chauffeursCount: dagboek.chauffeursCount,
              machinistenCount: dagboek.machinistenCount,
              onderaannemerFirmas: new FormArray([]),
              materialen: new FormArray([]),
              photos: new FormArray([]),
              monsternameProeven: dagboek.monsternameProeven,
              opmerkingen: dagboek.opmerkingen,
              getroffenBeslissingen: dagboek.getroffenBeslissingen,
              bezoekenAllerlei: dagboek.bezoekenAllerlei,
              soort: dagboek.soort,
              soortVerlet: dagboek.soortVerlet,
              machines: new FormArray([]),
              extraOnderaannemerFirma:'',
              startUur: dagboek.startDate != null ? this.formatDateToHourAndMinute(dagboek.startDate) : null,
              eindUur: dagboek.endDate != null ? this.formatDateToHourAndMinute(dagboek.endDate) : null,
              vastMaterieelOpWerf: new FormArray([])
            });
            this.currentCount = 1;
            this.totalPossibleDayCount = 1;
            this.opmerkingen = dagboek.opmerkingenDoorArbeiders;
            this.werkzaamheden = dagboek.werkzaamheden;
            this.arbeiders = dagboek.arbeiders;
            this.machinisten = dagboek.machinisten;
            this.chauffeurs = dagboek.chauffeurs;
            // Voorbeeld: toevoegen van initiële werkzaamheden aan de FormArray
            const werkzaamhedenArray = <FormArray>this.staatForm.get('werkzaamheden');
            this.werkzaamheden.forEach(werk => {
              werkzaamhedenArray.push(new FormControl(werk));
            });
            const materialenArray = <FormArray>this.staatForm.get('materialen');
            dagboek.materialen.forEach(materiaal => {
              materialenArray.push(this.setMateriaal(materiaal.naam,  materiaal.hoeveelheid));
            });
            this.onderaannemerFirmas = dagboek.onderaannemerFirmas;
            const onderaannemerFirmaArray = <FormArray>this.staatForm.get('onderaannemerFirmas');
            if(this.onderaannemerFirmas != null){
              this.onderaannemerFirmas.forEach(onderaanneemFirma => {
                onderaannemerFirmaArray.push(this.setOnderaanneemFirma(onderaanneemFirma.firmaNaam, onderaanneemFirma.arbeiderCount, onderaanneemFirma.arbeiders));
              });
            }

            const machinesArray = <FormArray>this.staatForm.get('machines');
            if(dagboek.machines != null && dagboek.machines.length > 0){
              dagboek.machines.forEach(machine => {
                machinesArray.push(this.setMachine(machine.naam,machine.aantal));
              });
            }
            this.photos = dagboek.photos;
            const photosArray = <FormArray>this.staatForm.get('photos');
            if(this.photos != null){
              await this.photos.forEach(photo => {
                if(photo.foto != null && photo.foto !== '' && photo.foto !== 'loading.gif')
                  photosArray.push(this.setPhoto(photo.fotoNaam, photo.foto, photo.addedFoto));
              });
            }
            let vastMaterieelOpWerfArray = <FormArray>this.staatForm.get('vastMaterieelOpWerf');
            if(dagboek.vastMaterieelOpWerf != null){
              await dagboek.vastMaterieelOpWerf.forEach(mat => {
                vastMaterieelOpWerfArray.push(this.setVastMaterieelOpwerf(mat.naam, mat.aantal));
              });
            }
            this.isLoadingWeather = false;
            this.isViewingDay = true;

            //voor bug door time formattering: 0U 0MIN
            await this.delay(100);
            this.staatForm.get('werkuren').setValue(dagboek.werkuren && dagboek.werkuren !== '0U' ? this.werkUrenToDate(dagboek.werkuren) : null)
          } else {
            this.currentDagboek = null;
            this.opmerkingen = [];

            this.calculateAndLoadDay(day.getDate());

          }
          this.checkAndGetMachinesAndMaterialen();
        });
      }
    }
  }
   getTotalsOfWerfPerDay(date1: string, chosenWerfId: string){

    const dagrapporten$ = this.apiService.getDagrapportenExcel(date1, 'geen', chosenWerfId, 'geen', 'geen');
    const transportDagrapporten$ = this.apiService.getTransportDagrapportenExcel(date1, 'geen', chosenWerfId, 'geen', 'geen');

    let usersArrayByDate = [];
     return new Promise<any>((resolve, reject) => {
       forkJoin([dagrapporten$, transportDagrapporten$]).subscribe(async ([dagrapportenResult, transportDagrapportenResult]) => {
           this.dagrapporten = dagrapportenResult as Dagrapport[];

           for (let dagrapport of this.dagrapporten) {
             let vroegsteDagrapportDate = new Date(2080, 1, 1);
             let laatsteDagrapportDate = new Date(2021, 11, 11);
             dagrapport.date = new Date(dagrapport.date);
             let index;
             if (usersArrayByDate.find(x => x.date.getFullYear() === dagrapport.date.getFullYear() && x.date.getMonth() === dagrapport.date.getMonth() && x.date.getDate() === dagrapport.date.getDate()) == null) {
               usersArrayByDate.push({
                 arbeiders: [],
                 chauffeurs: [],
                 machinisten: [],
                 onderaannemerFirmas: [],
                 date: dagrapport.date,
                 werkzaamheden: [],
                 werkuren: '',
                 machines: [],
                 gebruikteMaterialen: [],
                 aanvoerMaterialen: [],
                 opmerkingen: [],
                 photos: []
               });
             }
             index = usersArrayByDate.findIndex(x => x.date.getFullYear() === dagrapport.date.getFullYear() && x.date.getMonth() === dagrapport.date.getMonth() && x.date.getDate() === dagrapport.date.getDate());
             for (let werk of dagrapport.werken) {
               if (werk.startDate != null) {
                 werk.startDate = new Date(werk.startDate);
                 werk.endDate = new Date(werk.endDate);
                 werk.startDate.setSeconds(0, 0);
                 werk.endDate.setSeconds(0, 0);
                 if (werk.startDate.getTime() < vroegsteDagrapportDate.getTime()) {
                   vroegsteDagrapportDate = werk.startDate;
                 }
                 if (werk.endDate.getTime() > laatsteDagrapportDate.getTime()) {
                   laatsteDagrapportDate = werk.endDate;
                 }
               }
               if (werk.werkzaamheid != null) {
                 let werkzaamheid;
                 if (werk.werkzaamheid.naam === 'Andere') {
                   werkzaamheid = werk.anderWerk + ' ';
                 } else if (werk.werkzaamheid.naam === 'Meerwerk') {
                   werkzaamheid = 'Meerwerk ' + werk.meerWerk + ' ';
                 } else if (werk.subActiviteit != null && werk.subActiviteit !== '') {
                   werkzaamheid = werk.werkzaamheid.naam + ' ' + werk.subActiviteit + ' ';
                 } else {
                   werkzaamheid = werk.werkzaamheid.naam + ' ';
                 }
                 if (usersArrayByDate[index].werkzaamheden.find(x => x === werkzaamheid) == null) {
                   usersArrayByDate[index].werkzaamheden.push(werkzaamheid);
                 }
               }
               if (werk.opmerking != null && werk.opmerking !== '') {
                 if (usersArrayByDate[index].opmerkingen.find(x => x === werk.opmerking) == null) {
                   usersArrayByDate[index].opmerkingen.push({arbeiderNaam: dagrapport.user_id?.name, opmerking: werk.opmerking});
                 }
               }
               if (werk.photos != null && werk.photos.length !== 0) {
                 for (let i = 0; i < werk.photos.length; i++) {
                   if (usersArrayByDate[index].photos.find(x => x.src === werk.photos[i]?.src) == null) {
                     usersArrayByDate[index].photos.push({
                       addedFoto: false,
                       foto: werk.photos[i].src,
                       fotoNaam: werk.photos[i].beschrijving
                     });
                   }
                 }
               }
               if (werk.machinisten != null && werk.machinisten.length !== 0) {
                 for (let machinist of werk.machinisten) {
                   let machinistExists = usersArrayByDate[index].machinisten.find(x => x._id === machinist._id);
                   if (machinistExists == null) {
                     usersArrayByDate[index].machinisten.push(machinist);
                   }
                 }
               }
               if (werk.machines != null && werk.machines.length !== 0) {
                 for (let machine of werk.machines) {
                   if (usersArrayByDate[index].machines.find(x => x._id === machine._id) == null) {
                     usersArrayByDate[index].machines.push(machine);
                   }
                 }
               }
               if (werk.handwerkers != null && werk.handwerkers.length !== 0) {
                 for (let arbeider of werk.handwerkers) {
                   let arbeiderExists = usersArrayByDate[index].arbeiders.find(x => x._id === arbeider._id);
                   if (arbeiderExists == null) {
                     usersArrayByDate[index].arbeiders.push(arbeider);
                   }
                 }
               }
               if (werk.onderaannemers != null && werk.onderaannemers.length !== 0) {
                 for (let onderaannemer of werk.onderaannemers) {
                   let onderaannemerIndex = usersArrayByDate[index].onderaannemerFirmas.findIndex(x => x.firmaNaam === onderaannemer.firmaNaam);
                   if (onderaannemerIndex == null || onderaannemerIndex === -1) {
                     usersArrayByDate[index].onderaannemerFirmas.push({
                       firmaNaam: onderaannemer.firmaNaam,
                       arbeiders: [onderaannemer]
                     });
                   } else {
                     if (usersArrayByDate[index].onderaannemerFirmas[onderaannemerIndex].arbeiders.find(x => x._id === onderaannemer._id) == null) {
                       usersArrayByDate[index].onderaannemerFirmas[onderaannemerIndex].arbeiders.push(onderaannemer);
                     }
                   }
                 }
               }

             }
             if (vroegsteDagrapportDate.getFullYear() !== 2080 && laatsteDagrapportDate.getFullYear() !== 2021) {
               const workHours = this.calculateWorkHours(vroegsteDagrapportDate, laatsteDagrapportDate);

               //VERANDEREN !!!!!! STAAT IN WERK LOOP

               usersArrayByDate[index].werkuren = workHours;
               usersArrayByDate[index].startDate = vroegsteDagrapportDate;
               usersArrayByDate[index].endDate = laatsteDagrapportDate;
             }
           }

           this.transportDagrapporten = transportDagrapportenResult as Transportdagrapport[];
           if (this.transportDagrapporten != null && this.transportDagrapporten.length !== 0) {
             for (let transportdagrapport of this.transportDagrapporten) {
               transportdagrapport.date = new Date(transportdagrapport.date);
               let index;
               if (usersArrayByDate.find(x => x.date.getFullYear() === transportdagrapport.date.getFullYear() && x.date.getMonth() === transportdagrapport.date.getMonth() && x.date.getDate() === transportdagrapport.date.getDate()) == null) {
                 usersArrayByDate.push({
                   arbeiders: [],
                   chauffeurs: [],
                   machinisten: [],
                   onderaannemerFirmas: [],
                   date: transportdagrapport.date,
                   werkzaamheden: [],
                   werkuren: '',
                   machines: [],
                   gebruikteMaterialen: [],
                   aanvoerMaterialen: [],
                   opmerkingen: [],
                   photos: []
                 });
               }
               index = usersArrayByDate.findIndex(x => x.date.getFullYear() === transportdagrapport.date.getFullYear() && x.date.getMonth() === transportdagrapport.date.getMonth() && x.date.getDate() === transportdagrapport.date.getDate());
               for (let werk of transportdagrapport.transportwerken) {
                 if (werk.dateLaadplaats != null) {
                   werk.dateLaadplaats = new Date(werk.dateLaadplaats);
                   werk.dateLosplaats = new Date(werk.dateLosplaats);
                 }
                 if (werk.opmerking != null && werk.opmerking !== '') {
                   usersArrayByDate[index].opmerkingen.push({arbeiderNaam: werk.user_id.name, opmerking: werk.opmerking});
                 }
                 if (werk.user_id?._id != null && usersArrayByDate[index].chauffeurs.find(x => x._id === werk.user_id?._id) == null) {
                   usersArrayByDate[index].chauffeurs.push(werk.user_id);
                 }
                   if (werk.losplaats?._id === werk.werf?._id) {

                     if (werk.machine?._id != null) {
                       let index2 = usersArrayByDate[index].machines.findIndex(x => x.naam === werk.machine.naam);
                       if(index2 !== -1){
                          usersArrayByDate[index].machines[index2].aantal += 1;
                       } else {
                          usersArrayByDate[index].machines.push({naam: werk.machine.naam, aantal: 1});
                       }
                     }
                     if (werk.machine2?._id != null) {
                       let index2 = usersArrayByDate[index].machines.findIndex(x => x.naam === werk.machine2.naam);
                       if(index2 !== -1){
                         usersArrayByDate[index].machines[index2].aantal += 1;
                       } else {
                         usersArrayByDate[index].machines.push({naam: werk.machine2.naam, aantal: 1});
                       }
                     }
                     if (werk.materialen != null && werk.materialen.length !== 0) {
                        for (let materiaal of werk.materialen) {
                          materiaal.richting = 'Aanvoer';
                          if (usersArrayByDate[index].aanvoerMaterialen.find(x => x === materiaal.materiaal) == null) {
                            usersArrayByDate[index].aanvoerMaterialen.push({naam: materiaal.materiaal, hoeveelheid: materiaal.hoeveelheid + (materiaal.eenheid != null ? ' ' + materiaal.eenheid : '')});
                          }
                        }
                     }
                 }
               }
             }
           }
           this.dataOfMonthByDay = usersArrayByDate;
           resolve(this.dataOfMonthByDay);
         },
         (error) => {
           // If there's an error, reject the promise
           reject(error);
         });
     });
  }

  async calculateAndLoadDay(i: number){
    let bladNummer;
    let werkDagNummmer = 0;
    let verletDagNummer = 0;
    let weerverletNummer = 0;
    let verlofDagNummer = 0;
    let feestDagNummer =  0;
    let ontoegankelijkheidNummer =  0;
    let schorsingNummer = 0;

    let day = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), i, 7);
    await this.getTotalsOfWerfPerDay(this.currentDate.toString(),this.currentWerf._id);
    let soort = this.daysInMonth.getValue().find(x => x.isoDate === day.toISOString().split('T')[0])?.soort;
    // Now both subscriptions have finished, you can continue with any logic here
       let hasData = this.dataOfMonthByDay.find(x => x.date.getFullYear() === this.currentDate.getFullYear() && x.date.getMonth() === this.currentDate.getMonth() && x.date.getDate() === this.currentDate.getDate());

      if(this.nextDagboekWithNumbers?.toString() === 'Geen dagboek gevonden'){
         this.nextDagboekWithNumbers = new DagboekDerWerken();
         this.nextDagboekWithNumbers.bladNummer = 0;
         this.nextDagboekWithNumbers.verletDagNummer = 0;
         this.nextDagboekWithNumbers.weerverletNummer = 0;
         this.nextDagboekWithNumbers.werkDagNummmer = 0;
         this.nextDagboekWithNumbers.verlofDagNummer = 0;
         this.nextDagboekWithNumbers.feestDagNummer = 0;
         this.nextDagboekWithNumbers.ontoegankelijkheidNummer = 0;
         this.nextDagboekWithNumbers.schorsingNummer = 0;
      }
        bladNummer =  this.nextDagboekWithNumbers.bladNummer;
        verletDagNummer = this.nextDagboekWithNumbers.verletDagNummer;
        werkDagNummmer = this.nextDagboekWithNumbers.werkDagNummmer;
        weerverletNummer = this.nextDagboekWithNumbers.weerverletNummer;
        verlofDagNummer = this.nextDagboekWithNumbers.verlofDagNummer;
        feestDagNummer = this.nextDagboekWithNumbers.feestDagNummer;
        ontoegankelijkheidNummer = this.nextDagboekWithNumbers.ontoegankelijkheidNummer;
        schorsingNummer = this.nextDagboekWithNumbers.schorsingNummer;

       if(soort === 'weerverlet'){
         weerverletNummer = this.nextDagboekWithNumbers.weerverletNummer + 1;
         verletDagNummer = this.nextDagboekWithNumbers.verletDagNummer + 1;
       } else if(soort === 'werkdag'){
         werkDagNummmer = this.nextDagboekWithNumbers.werkDagNummmer + 1;
       } else {
          if(soort === 'verlof'){
            verlofDagNummer = this.nextDagboekWithNumbers.verlofDagNummer + 1;
          }
          if(soort === 'feestdag'){
            feestDagNummer = this.nextDagboekWithNumbers.feestDagNummer + 1;
          }
        /* KAN NIET VOORKOMEN - wordt nooit voorspeld
         if(soort === 'ontoegankelijkheid'){
            ontoegankelijkheidNummer = this.nextDagboekWithNumbers.ontoegankelijkheidNummer + 1;
          }
          if(soort === 'schorsing'){
            schorsingNummer = this.nextDagboekWithNumbers.schorsingNummer + 1;
          }*/
       }
       bladNummer++;




      this.arbeiders = hasData?.arbeiders != null ? hasData.arbeiders : [];
      this.chauffeurs = hasData?.chauffeurs != null ? hasData.chauffeurs : [];
      this.machinisten = hasData?.machinisten != null ? hasData.machinisten : [];
      this.onderaannemerFirmas = hasData?.onderaannemerFirmas != null ? hasData.onderaannemerFirmas : [];
      this.machines = hasData?.machines != null ? hasData.machines : [];
      this.werkzaamheden = hasData?.werkzaamheden != null ? hasData.werkzaamheden : [];
      this.opmerkingen = hasData?.opmerkingen != null ? hasData.opmerkingen : [];
      this.photos = hasData?.photos != null ? hasData.photos : [];

      this.staatForm = this.formBuilder.group({
        werkzaamheden: new FormArray([]),
        bladNummer: bladNummer,
        werkDagNummmer: werkDagNummmer,
        verletDagNummer: verletDagNummer,
        weerverletNummer: weerverletNummer,
        verlofDagNummer: verlofDagNummer,
        feestDagNummer: feestDagNummer,
        ontoegankelijkheidNummer: ontoegankelijkheidNummer,
        schorsingNummer: schorsingNummer,
        werkuren: hasData?.werkuren != null ? this.werkUrenToDate(hasData.werkuren) : null,
        temp8h: '',
        temp13h:'',
        weergesteldheid: '',
        regenGedurendeUren: '',
        toestandVorigeNacht: '',
        toestandVoormiddag: '',
        toestandNamiddag: '',
        arbeiderCount:  this.arbeiders.length,
        machinistenCount: this.machinisten.length,
        chauffeursCount: this.chauffeurs.length,
        onderaannemerFirmas: new FormArray([]),
        materialen: new FormArray([]),
        machines: new FormArray([]),
        photos: new FormArray([]),
        monsternameProeven: '',
        opmerkingen: '',
        getroffenBeslissingen: '',
        bezoekenAllerlei: '',
        soort: '',
        soortVerlet: soort,
        extraOnderaannemerFirma: '',
        startUur:  hasData?.startDate != null ? this.formatDateToHourAndMinute(hasData.startDate) : null,
        eindUur:  hasData?.endDate != null ? this.formatDateToHourAndMinute(hasData.endDate) : null,
        vastMaterieelOpWerf: new FormArray([])
      });
      // Voorbeeld: toevoegen van initiële werkzaamheden aan de FormArray
      const werkzaamhedenArray = <FormArray>this.staatForm.get('werkzaamheden');
      this.werkzaamheden.forEach(werk => {
        werkzaamhedenArray.push(new FormControl(werk));
      });

      if(hasData?.aanvoerMaterialen != null){
        const materialenArray = <FormArray>this.staatForm.get('materialen');
        hasData.aanvoerMaterialen.forEach(materiaal => {
          materialenArray.push(this.setMateriaal(materiaal.naam, (materiaal.hoeveelheid == null || materiaal.hoeveelheid === 'null' ? '' : materiaal.hoeveelheid)));
        });
      }

      const onderaannemerFirmaArray = <FormArray>this.staatForm.get('onderaannemerFirmas');
      if(this.onderaannemerFirmas != null){
        this.onderaannemerFirmas.forEach(onderaanneemFirma => {
          onderaannemerFirmaArray.push(this.setOnderaanneemFirma(onderaanneemFirma.firmaNaam, onderaanneemFirma.arbeiders.length, onderaanneemFirma.arbeiders));
        });
      }
      const photosArray = <FormArray>this.staatForm.get('photos');
      if(this.photos != null){
        await this.photos.forEach(photo => {
          if(photo.foto != null && photo.foto !== '' && photo.foto !== 'loading.gif')
            photosArray.push(this.setPhoto(photo.fotoNaam, photo.foto, photo.addedFoto));
        });
      }
      const machinesArray = <FormArray>this.staatForm.get('machines');
      if(this.machines != null){
        this.machines.forEach(machine => {
          let index = machinesArray.value.findIndex(x => x.naam === machine.naam);
          if(index === -1){
            machinesArray.push(this.setMachine(machine.naam,1));
          } else {
            machinesArray.value[index].aantal += 1;
          }
        });
      }
      let vastMaterieelOpWerfArray = <FormArray>this.staatForm.get('vastMaterieelOpWerf');
      let tempPreviousDate;
      if(day.getDay() === 1){
        tempPreviousDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), (day.getDate() - 3), 4);
      } else {
        tempPreviousDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), (day.getDate() - 1), 4);
      }
      if(tempPreviousDate != null && this.dagboekenOfMonth != null){
        let previousVastMaterieelOpWerf = this.dagboekenOfMonth.find(x => x.isoDate === tempPreviousDate.toISOString().split('T')[0])?.vastMaterieelOpWerf;
        if(previousVastMaterieelOpWerf != null){
           await previousVastMaterieelOpWerf.forEach(mat => {
            vastMaterieelOpWerfArray.push(this.setVastMaterieelOpwerf(mat.naam, mat.aantal));
          });
        }
      }
      this.isLoaded = true;

    if(this.staatForm.controls['temp8h'] == null || this.staatForm.controls['temp8h'].value === ''){
      this.fillInWeatherData(day.toISOString().split('T')[0])
    }
    this.currentDagboek = null;
    await this.delay(50);
    this.isViewingDay = true;
  }
  werkUrenToDate(werkuren: string){
     let werkUren;
     if(werkuren != null && werkuren !== ''){
       if(werkuren.includes('U')){
         werkUren = werkuren.split('U');
       } else {
         //oude werkuren formaat -> nog verwijderen
         werkUren = werkuren.split('u');
       }

       let hours = parseInt(werkUren[0]);
       let minutes = 0;
       if(werkUren[1] != null && werkUren[1] !== ''){
          minutes = parseInt(werkUren[1].replace('MIN',''));
       }
       let date = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate(), hours, minutes);
       return date;
     } else {
        return null;
     }

  }
  async  generateStaatDerVerletdagen() {

    let year = this.currentDate.getFullYear();
    let month = this.currentDate.getMonth();

    let previousMonth = this.currentDate.getMonth()  === 0 ? 11 : this.currentDate.getMonth() - 1;
    let previousYear = this.currentDate.getMonth() === 0 ? this.currentDate.getFullYear() - 1 : this.currentDate.getFullYear();
    let previousStaatDerVerlet = await this.apiService.getStaatDerVerletOfMonth(this.currentWerf._id,previousMonth, previousYear) as StaatDerVerlet;

    if(this.currentWerf.startDatum != null){
      let startDatum = new Date(this.currentWerf.startDatum);
      if(startDatum.getTime() < new Date(year, month, 1).getTime()) {
        if(previousStaatDerVerlet?._id == null) {
          this.toastrService.danger('Gelieve eerst de staat der verlet van de vorige maand(en) op te slaan.', 'Geen vorige staat der verlet gevonden!');
          this.isSavingStaatDerVerlet = false;
          return;
        }
      }
    }

    let daysOfMonth = Object.assign([], this.daysInMonth.getValue());
    let hasAllDagboeken = true;
    let hasOneDagboek = false;
    daysOfMonth.forEach(day => {
      if(!day.hasDagboek) {
        hasAllDagboeken = false;
      }
      if(day.hasDagboek){
        hasOneDagboek = true;
      }
    })

    let staatMaandErvoor;
    if(previousStaatDerVerlet?._id == null){
      staatMaandErvoor = new StaatDerVerlet();
    } else {
      staatMaandErvoor = previousStaatDerVerlet;
    }
    if(hasOneDagboek){
      this.formService.isGeneratingStaatDerVerlet = true;
      this.dagboekExcelService.generateStaatDerVerletExcel(this.company.name,this.company.logoURL, this.currentWerf, this.currentDate,daysOfMonth, this.dagboekenOfMonth, staatMaandErvoor, hasAllDagboeken, this.apiService.userId);
    } else {
      this.toastrService.warning('Er zijn geen dagboeken opgeslagen voor deze maand', 'Geen dagboeken gevonden');
    }
    /*  if(hasAllDagboeken){
          let dialogRef = this.dialog.open(SaveTotalenDialogComponent, {
          height: '390px',
          width: '790px',
            panelClass: 'mat-dialog-padding'
        });
        dialogRef.afterClosed().subscribe(() => {
          if(this.formService.isSave != null){
            this.formService.isGeneratingStaatDerVerlet = true;
            this.dagboekExcelService.generateStaatDerVerletExcel(this.company.name,this.company.logoURL, this.currentWerf, this.currentDate,daysOfMonth, this.dagboekenOfMonth, staatMaandErvoor, hasAllDagboeken, this.apiService.userId);
          }
        });
      } else {*/
  }
  saveDagboek(volgende?: boolean) {
    if(!this.isSaving){
      this.isSaving = true;
      let usersArrayByDate = Object.assign({}, this.staatForm.value) as DagboekDerWerken;
      usersArrayByDate.date = this.currentDate
      usersArrayByDate.date.setHours(4)
      usersArrayByDate.werf = this.currentWerf;
      usersArrayByDate.arbeiders = this.arbeiders;
      usersArrayByDate.chauffeurs = this.chauffeurs;
      usersArrayByDate.machinisten = this.machinisten;
      usersArrayByDate.werkzaamheden = usersArrayByDate.werkzaamheden.filter( x => x != null && x !== '');
      if(usersArrayByDate.startUur != null && usersArrayByDate.startUur !== '' && usersArrayByDate.eindUur != null && usersArrayByDate.eindUur !== ''){
        let startUur = usersArrayByDate.startUur.split(':')[0];
        let startMinuten = usersArrayByDate.startUur.split(':')[1];
        let eindUur = usersArrayByDate.eindUur.split(':')[0];
        let eindMinuten = usersArrayByDate.eindUur.split(':')[1];
        usersArrayByDate.startDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate(), +startUur, +startMinuten);
        usersArrayByDate.endDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate(), +eindUur, +eindMinuten);
        usersArrayByDate.werkuren = this.calculateWorkHours(usersArrayByDate.startDate, usersArrayByDate.endDate);
      }

      if(usersArrayByDate.onderaannemerFirmas !== null && usersArrayByDate.onderaannemerFirmas.length !== 0){
        for(let i = 0;i < usersArrayByDate.onderaannemerFirmas.length; i++){
          if(usersArrayByDate.onderaannemerFirmas[i].arbeiders == null) usersArrayByDate.onderaannemerFirmas[i].arbeiders = [];
          if(this.onderaannemerFirmas[i].arbeiders == null) this.onderaannemerFirmas[i].arbeiders = [];
          usersArrayByDate.onderaannemerFirmas[i].arbeiders = this.onderaannemerFirmas[i].arbeiders;
          if(usersArrayByDate.onderaannemerFirmas[i].arbeiderCount == null || usersArrayByDate.onderaannemerFirmas[i].arbeiderCount === 0){
            usersArrayByDate.onderaannemerFirmas.splice(i,1);
            i--;
          }
        }
        //berekenen totaal onderaannemers
        if(usersArrayByDate.onderaannemerFirmas.length !== 0){
          usersArrayByDate.onderaannemersCount = 0;
          for(let i = 0; i < usersArrayByDate.onderaannemerFirmas.length; i++){
            usersArrayByDate.onderaannemersCount += usersArrayByDate.onderaannemerFirmas[i].arbeiderCount;
          }
        }
      }



      if(usersArrayByDate.machines != null && usersArrayByDate.machines.length !== 0){
        usersArrayByDate.machines = usersArrayByDate.machines.filter(x=> x != null && x.naam.trim() !== '');
        //berekenen totaal machines
        if(usersArrayByDate.machines.length !== 0){
          usersArrayByDate.machinesCount = 0;
          for(let i = 0; i < usersArrayByDate.machines.length; i++){
            usersArrayByDate.machinesCount += usersArrayByDate.machines[i].aantal;
          }
        }
      }
      if(usersArrayByDate.materialen != null && usersArrayByDate.materialen.length !== 0){
        usersArrayByDate.materialen = usersArrayByDate.materialen.filter(x=> x != null && x.naam.trim() !== '');
      }
        let date = new Date(this.currentDate);
      date.setHours(4);
      let isoDate = date.toISOString().split('T')[0];
      usersArrayByDate.isoDate = isoDate;
      usersArrayByDate.company_id = this.company._id;
      usersArrayByDate.companyName = this.company.name;
      usersArrayByDate.opmerkingenDoorArbeiders = this.opmerkingen;


      if(this._id != null){
        usersArrayByDate._id = this._id;

        if(usersArrayByDate.weerverletNummer == null || usersArrayByDate.weerverletNummer.toString() === 'NaN'){
          usersArrayByDate.weerverletNummer = 0;
          usersArrayByDate.toegankelijkheid = 'sudoReload';
        }
        this.apiService.updateDagboekDerWerken(usersArrayByDate, this.currentWerf._id).subscribe(x => {
          let latestDagboekWithCorrectNummers = x as DagboekDerWerken;
          this.nextDagboekWithNumbers.werkDagNummmer = latestDagboekWithCorrectNummers.werkDagNummmer;
          this.nextDagboekWithNumbers.verletDagNummer = latestDagboekWithCorrectNummers.verletDagNummer;
          this.nextDagboekWithNumbers.weerverletNummer = latestDagboekWithCorrectNummers.weerverletNummer;
          this.nextDagboekWithNumbers.verlofDagNummer = latestDagboekWithCorrectNummers.verlofDagNummer;
          this.nextDagboekWithNumbers.feestDagNummer = latestDagboekWithCorrectNummers.feestDagNummer;
          this.nextDagboekWithNumbers.schorsingNummer = latestDagboekWithCorrectNummers.schorsingNummer;
          this.nextDagboekWithNumbers.ontoegankelijkheidNummer = latestDagboekWithCorrectNummers.ontoegankelijkheidNummer;
          this.toastrService.success('Dagboek der werken succesvol geüpdatet', 'Dagboek der werken geüpdatet');
          this.currentDagboek.date = new Date(this.currentDagboek.date);
          if(volgende){
            this._id = null;
            if(this.currentDagboek.date.getDay() === 5){
              this.currentDate = new Date(this.currentDagboek.date.getFullYear(), this.currentDagboek.date.getMonth(), this.currentDagboek.date.getDate() + 2,4);
            } else {
              this.currentDate = new Date(this.currentDagboek.date);
            }
            if(this.currentDate.getDate() < this.lastDayOfMonth){
              this.isLoaded = false;
              this.currentDate.setDate(this.currentDate.getDate() + 1);
              this.calculateAndLoadDay(this.currentDate.getDate());
            }
          }
          this.isSaving = false;
        });
      } else {
        this.apiService.addDagboekDerWerken(usersArrayByDate).subscribe(x => {
          this.currentDagboek = x as DagboekDerWerken;
          this._id = this.currentDagboek._id;
          this.currentDagboek.arbeiders = this.arbeiders;
          this.currentDagboek.chauffeurs = this.chauffeurs;
          this.currentDagboek.machinisten = this.machinisten;
          this.opmerkingen = [];
          this.currentDagboek.date = new Date(this.currentDagboek.date);
          this.nextDagboekWithNumbers = this.currentDagboek;
          this.latestDagboek = this.currentDagboek;
          this.toastrService.success('Dagboek der werken succesvol opgeslagen', 'Dagboek der werken opgeslagen');
          if(volgende){
              this._id = null;
              if(this.currentDagboek.date.getDay() === 5){
                this.currentDate = new Date(this.currentDagboek.date.getFullYear(), this.currentDagboek.date.getMonth(), this.currentDagboek.date.getDate() + 2,4);
              } else {
                this.currentDate = new Date(this.currentDagboek.date);
              }
              if(this.dagboekenOfMonth == null)this.dagboekenOfMonth = [];
              this.dagboekenOfMonth.push(this.currentDagboek);
              this.kopieerDagboeken.unshift(this.currentDagboek);
              this.kopieerDagboekSelect = this.currentDagboek._id
              this.nextDagboekDate = this.currentDate;
              if(this.currentDate.getDate() < this.lastDayOfMonth){
                this.currentCount++;
                this.currentDagboek = null;
                this.isLoaded = false;
                this.currentDate.setDate(this.currentDate.getDate() + 1);
                this.calculateAndLoadDay(this.currentDate.getDate());
              }
          } else {
            this.bekijk(null);
          }
          this.isSaving = false;
        });
      }
    }
  }

  async createStaatDerVerletOfMonth(){
    if(this.isSavingStaatDerVerlet){
      return;
    }
    this.isSavingStaatDerVerlet = true;
    let year =  this.currentDate.getFullYear();
    let month = this.currentDate.getMonth()
    let previousMonth = this.currentDate.getMonth() === 0 ? 11 : this.currentDate.getMonth() - 1;
    let previousYear = this.currentDate.getMonth() === 0 ? this.currentDate.getFullYear() - 1 : this.currentDate.getFullYear();

    let soortenDagen = this.berekenSoortDagenStaatDerVerlet();
    let previousStaatDerVerlet = await this.apiService.getStaatDerVerletOfMonth(this.currentWerf._id, previousMonth, previousYear) as StaatDerVerlet;

    if(this.currentWerf.startDatum != null){
      let startDatum = new Date(this.currentWerf.startDatum);
      if(startDatum.getTime() < new Date(year, month, 1).getTime()) {
        if(previousStaatDerVerlet?._id == null) {
          this.toastrService.danger('Gelieve eerst de staat der verlet van de vorige maand(en) op te slaan.', 'Geen vorige staat der verlet gevonden!');
          this.isSavingStaatDerVerlet = false;
          return;
        }
      }
    }
    let staatDerVerlet;
      //create
      staatDerVerlet = new StaatDerVerlet();
      staatDerVerlet.werf = this.currentWerf._id;
      staatDerVerlet.month = month;
      staatDerVerlet.year = year;
      if(previousStaatDerVerlet.vorderingStaatNr != null){
        staatDerVerlet.vorderingStaatNr = previousStaatDerVerlet.vorderingStaatNr + 1;
      } else {
        staatDerVerlet.vorderingStaatNr = 1;
      }


    staatDerVerlet.verletDagen = soortenDagen.verletDagen;
    staatDerVerlet.werkDagen = soortenDagen.werkDagen;
    staatDerVerlet.weerverletDagen = soortenDagen.weerverletDagen;
    staatDerVerlet.verlofDagen = soortenDagen.verlofDagen;
    staatDerVerlet.feestDagen = soortenDagen.feestDagen;
    staatDerVerlet.ontoegankelijkheidDagen = soortenDagen.ontoegankelijkheidDagen;
    staatDerVerlet.schorsingsDagen = soortenDagen.schorsingsDagen;

    let latestDagboekOfMonth = this.dagboekenOfMonth[this.dagboekenOfMonth.length - 1];
    staatDerVerlet.dagboekBladNummer = latestDagboekOfMonth.bladNummer;
    staatDerVerlet.totaleVerletDagen = latestDagboekOfMonth.verletDagNummer;
    staatDerVerlet.totaleWerkDagen = latestDagboekOfMonth.werkDagNummmer;
    staatDerVerlet.totaleWeerverletDagen = latestDagboekOfMonth.weerverletNummer;
    staatDerVerlet.totaleVerlofDagen = latestDagboekOfMonth.verlofDagNummer;
    staatDerVerlet.totaleFeestDagen = latestDagboekOfMonth.feestDagNummer;
    staatDerVerlet.totaleOntoegankelijkheidDagen = latestDagboekOfMonth.ontoegankelijkheidNummer;
    staatDerVerlet.totaleSchorsingsDagen = latestDagboekOfMonth.schorsingNummer;


    this.apiService.createStaatDerVerlet(staatDerVerlet).subscribe(x => {
          this.isSavingStaatDerVerlet = false;
          this.toastrService.success('Staat der verlet succesvol opgeslagen', 'Staat der verlet opgeslagen');
          this.reloadList();
          } , error => {
          this.isSavingStaatDerVerlet = false;
          this.toastrService.danger('Er is een fout opgetreden bij het opslaan van de staat der verlet', 'Fout bij opslaan staat der verlet');
    });
  }

    berekenSoortDagenStaatDerVerlet(){
      let verletDagen = 0;
      let werkDagen = 0;
      let weerverletDagen = 0;
      let feestDagen = 0;
      let verlofDagen = 0;
      let ontoegankelijkheidDagen = 0;
      let schorsingsDagen = 0;
      for(let dagboek of this.dagboekenOfMonth){
        if(dagboek.soortVerlet === 'weerverlet'){
          weerverletDagen = weerverletDagen + 1;
          verletDagen = verletDagen + 1;
        } else if(dagboek.soortVerlet === 'werkdag'){
          werkDagen = werkDagen + 1;
        } else if(dagboek.soortVerlet === 'verlof'){
          verlofDagen = verlofDagen + 1;
        } else if(dagboek.soortVerlet === 'feestdag'){
          feestDagen = feestDagen + 1;
        } else if(dagboek.soortVerlet === 'ontoegankelijk'){
          verletDagen = verletDagen + 1;
          ontoegankelijkheidDagen = ontoegankelijkheidDagen + 1;
        } else if(dagboek.soortVerlet === 'schorsing'){
          schorsingsDagen = schorsingsDagen + 1;
        }
      }
      return {verletDagen, werkDagen, weerverletDagen, feestDagen, verlofDagen, ontoegankelijkheidDagen, schorsingsDagen};
  }
  getAndSetWeatherDetails(fromDate: Date, toDate: Date){
    this.isLoadingWeather = true;
    if(this.currentWerf.latCoord != null && this.currentWerf.longCoord != null && this.currentWerf.latCoord.toString() !== '' &&this.currentWerf.longCoord.toString() !== '' ){
      let dateString = '';
      for(let date = fromDate.getDate(); date <= toDate.getDate(); date++){
        let newDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), date,4);
        if(newDate.getDay() !== 0 && newDate.getDay() !== 6){
          dateString += new Date(fromDate.getFullYear(), fromDate.getMonth(), date,4).toISOString().split('T')[0] + ';';
        }
      }

      dateString = dateString.substring(0, dateString.length - 1);
      this.apiService.getWeatherOf2Days(dateString, this.currentWerf.latCoord.toString(), this.currentWerf.longCoord.toString()).subscribe(async x => {
        this.weatherData = x as DayWeatherData[];
        if((this.weatherData == null || this.weatherData.length === 0) && this.totalPossibleDayCount > 0){
          this.isLoadingWeather = false;
          this.isErrorByWeatherStack = true;
          return;
        }
        let daysInMonth = this.daysInMonth.getValue();
        daysInMonth.forEach(day => {
          if(this.weatherData[day.isoDate] && day.soort !== 'feestdag' && day.soort !== 'verlofdag'){
            let weatherData = JSON.parse(JSON.stringify(this.weatherData[day.isoDate]));
            if(weatherData != null){
              weatherData = this.spliceOnnodigeHours(weatherData);
              let extraInfoSoortWeerverlet = this.bepaalWeerverlet(weatherData);
              if(extraInfoSoortWeerverlet != null) {
                day.soort = 'weerverlet';
                day.extraInfoOverIsWeerverlet = extraInfoSoortWeerverlet;
              }
            }
          }
        });
        this.daysInMonth.next(daysInMonth);
        this.isLoadingWeather = false;
      } , error => {
        this.isLoadingWeather = false;
      });
    }
  }
  onDeleteClick() {
     this.apiService.deleteDagboekDerWerken(this.currentDagboek).subscribe(x => {
        this.toastrService.success('Dagboek der werken succesvol verwijderd', 'Dagboek der werken verwijderd');
        this.dagboekenOfMonth = this.dagboekenOfMonth.filter(x => x._id !== this.currentDagboek._id);
        this.kopieerDagboeken = this.kopieerDagboeken.filter(x => x._id !== this.currentDagboek._id);
       this.currentDagboek = null;
        this._id = null;
        this.isViewingDay = false;
        this.reloadList();
     });
  }

  async fillInWeatherData(firstDayIso: string){
    //wachten op weerdata bij snel doorklikken & hele maand ophalen
    for(let i = 0; i < 6 ; i++){
      if(this.isLoadingWeather){
         await this.delay(200);
      }
    }
    if(this.weatherData == null || this.weatherData.length === 0 || this.weatherData[firstDayIso] == null)return;
    this.staatForm.controls['temp8h'].setValue(this.weatherData[firstDayIso].hourly[8]?.temperature?.toString());
    this.staatForm.controls['temp13h'].setValue( this.weatherData[firstDayIso].hourly[13]?.temperature?.toString());
    let totaleRegen = 0;
    let regenVoorMiddag = 0;
    let regenNamiddag = 0;
    let regenVorigeNacht = 0;
    let regenGedurendeUren = 0;

    for(let index = 0; index < this.weatherData[firstDayIso].hourly.length; index++){
      this.weatherData[firstDayIso].hourly[index].hour = index;
    }
    for(let i = 0; i <= 6; i++){
      let mmRegen = this.weatherData[firstDayIso].hourly[i]?.precip;
      regenVorigeNacht += mmRegen;
    }
    for(let i = 7; i <= 11; i++){
      let mmRegen = this.weatherData[firstDayIso].hourly[i]?.precip;
      if(mmRegen >= 0.1){
        regenGedurendeUren++;
        totaleRegen += mmRegen;
        regenVoorMiddag += mmRegen;
      }
    }
    for(let i = 12; i <= 15; i++){
      let mmRegen = this.weatherData[firstDayIso].hourly[i]?.precip;
      if(mmRegen >= 0.1){
        regenGedurendeUren++;
        totaleRegen += mmRegen;
        regenNamiddag += mmRegen;
      }
    }
    let classifyRainfall = (amount: number, hours: number) => {
      let hourlyRate = amount / hours;
      if (amount < 0.1 * hours) return "Droog";
      if (hourlyRate <= 2.5) return "Lichte regen";
      return "Regen";
    };

    let classificationVoormiddag = classifyRainfall(regenVoorMiddag, 5);
    let classificationNamiddag = classifyRainfall(regenNamiddag, 4);
    let weergesteldheid = classifyRainfall(totaleRegen, 9);
    let toestandVorigeNacht = classifyRainfall(regenVorigeNacht, 7);

    this.weatherData[firstDayIso] = this.spliceOnnodigeHours(this.weatherData[firstDayIso]);

    //bepalen van weerverlet of NIET
    let isWeerverlet = this.bepaalWeerverlet(this.weatherData[firstDayIso]);

    this.extraInfoOverIsWeerverlet = isWeerverlet;
    if(toestandVorigeNacht !== 'Droog' ){
      this.mmRegenOvernacht = regenVorigeNacht;
    } else {
      this.mmRegenOvernacht = null
    }

    if(isWeerverlet != null){
      this.currentIsoDate = firstDayIso;
    } else{
      this.currentIsoDate = null;
    }

    this.staatForm.controls['weergesteldheid'].setValue(weergesteldheid.toString());
    this.staatForm.controls['toestandVoormiddag'].setValue(classificationVoormiddag.toString());
    this.staatForm.controls['toestandNamiddag'].setValue(classificationNamiddag.toString());
    this.staatForm.controls['regenGedurendeUren'].setValue(regenGedurendeUren.toString());
    this.staatForm.controls['toestandVorigeNacht'].setValue(toestandVorigeNacht.toString());
  }
  getBarHeight(precip: number): number {
    // Zet de maximale neerslag op 10mm voor een volledige balk
    const maxPrecip = 3.5;
    // Bereken het percentage, met een minimum van 1% om altijd zichtbaar te zijn
    return Math.max(0, (precip / maxPrecip) * 100);
  }

  spliceOnnodigeHours(weatherData: DayWeatherData): DayWeatherData{
    for (let i = 23; i >= 16; i--) {
      weatherData.hourly.splice(i, 1);
    }
    for(let i = 6; i >= 0; i--){
      weatherData.hourly.splice(i,1);
    }
    return weatherData;
  }
  spliceOnnodigeHoursMetVorigeNacht(weatherData: DayWeatherData): DayWeatherData{
    for (let i = 23; i >= 16; i--) {
      weatherData.hourly.splice(i, 1);
    }
    return weatherData;
  }
   bepaalWeerverlet(dayData: DayWeatherData): string {
    let extraInfoWeerverlet = '';
    if (!dayData?.hourly) {
      return null;
    }

    // Criterium 1: Temperatuur om 7 uur 's ochtends 0 °C of kouder
    const temp7h = dayData.hourly[0]?.temperature;
    if (temp7h !== undefined && temp7h <= 0) {
      extraInfoWeerverlet += '0°C of kouder om 7 uur: ' + temp7h + '°C';
    }

    // Criterium 2: Minimaal 4 uurvakken met neerslag overdag
    let neerslagUurvakken = 0;
    for (let i = 0; i <= 9; i++) { // Van 7:00 tot 19:00
      if (dayData.hourly[i]?.precip >= 0.1) {
        neerslagUurvakken++;
      }
    }
    if (neerslagUurvakken >= 4) {
      if(extraInfoWeerverlet !== ''){
        extraInfoWeerverlet += ' + \n';
      }
      extraInfoWeerverlet += 'Uurvakken met neerslag:\n' + neerslagUurvakken + ' vakken';
    }

    // Criterium 3: Neerslag meer dan 5 mm per dag
    let totaleNeerslag = 0;
    for (let i = 0; i < dayData.hourly.length; i++) {
      totaleNeerslag += dayData.hourly[i]?.precip || 0;
    }
    if (totaleNeerslag > 5) {
      if(extraInfoWeerverlet !== ''){
        extraInfoWeerverlet += ' + \n';
      }
      extraInfoWeerverlet += 'Totale neerslag is meer dan 5mm per m²: ' + totaleNeerslag.toFixed(2) + ' mm';
    }

    // Criterium 4: Rukwinden van 70 km/uur of meer
    const maxWindsnelheid = Math.max(...dayData.hourly.map(hour => hour?.wind_speed || 0));
    if (maxWindsnelheid >= 70) {
      if(extraInfoWeerverlet !== ''){
        extraInfoWeerverlet +=  ' + \n';
      }
      extraInfoWeerverlet += 'Max windsnelheid is 70 km/uur of meer: ' + maxWindsnelheid + ' km/uur';
    }

    return extraInfoWeerverlet !== '' ? extraInfoWeerverlet : null;
  }
  checkAndGetMachinesAndMaterialen(){
    if(this.allMachines == null){
      this.apiService.getMachines(false,false).subscribe(x => {
        this.allMachines = x as Machine[];
        this.allMachines.sort((a, b) => a.naam.localeCompare(b.naam));
        this.observableFilteredMachines$ = of(this.allMachines.map(x => x.naam));

      });
    }
    if(this.allMaterialen == null){
      this.apiService.getMaterialen().subscribe(x => {
        this.allMaterialen = x as Materiaal[];
        this.allMaterialen.sort((a, b) => a.naam.localeCompare(b.naam));
      });
    }
  }

  private setMachine(naam: string, aantal: number) {
    return this.formBuilder.group({
      naam: naam,
      aantal: aantal
    });
  }
  get machinesControls(): FormControl[] {
    return (this.staatForm.get('machines') as FormArray).controls as FormControl[];
  }
  machinesGetter(): UntypedFormArray {
    return this.staatForm.get('machines') as UntypedFormArray;
  }

  showWerfleiderNames(werfleiders: User[]) {
    return werfleiders.map(x => x.name).join(', ');
  }
  onChangeMachine($event,i: number) {
    this.observableFilteredMachines$ = of($event.target.value).pipe(
      map(filterString => this.filterMachine(filterString)),
    );
  }

  clickAutoCompletemachine($event: any, i: number) {
    this.observableFilteredMachines$ = of([]);
  }
  filterMachine(value: string): string[] {
      const filterValue = value.toLowerCase();
      return this.allMachines.filter(optionValue => optionValue.naam.toLowerCase().includes(filterValue)).map(optionValue => optionValue.naam);
  }

  addMachine() {
    const machinesArray = <FormArray>this.staatForm.get('machines');
    machinesArray.push(this.setMachine('', 1));
  }
  addMateriaal() {
    const materialenArray = <FormArray>this.staatForm.get('materialen');
    materialenArray.push(this.setMateriaal('',''));
  }

  removeWerkzaamheid(index) {
    const werkzaamhedenArray = <FormArray>this.staatForm.get('werkzaamheden');
    werkzaamhedenArray.removeAt(index);
  }
  limitLines(event: Event) {
    const textarea = event.target as HTMLTextAreaElement;
    const lines = textarea.value.split('\n');
    if (lines.length > 6) {
      // Remove extra lines
      const limitedValue = lines.slice(0, 6).join('\n');
      textarea.value = limitedValue;
    }
  }




  changeNumbersOfDay($event: any) {
      if (this.currentDagboek == null) {
        if (this.nextDagboekWithNumbers?.toString() === 'Geen dagboek gevonden' || this.nextDagboekWithNumbers == null) {
          this.nextDagboekWithNumbers = new DagboekDerWerken();
          this.nextDagboekWithNumbers.bladNummer = 0;
          this.nextDagboekWithNumbers.werkDagNummmer = 0;
          this.nextDagboekWithNumbers.verletDagNummer = 0;
        }

        let werkDagNummmer = this.nextDagboekWithNumbers.werkDagNummmer;
        let verletDagNummer = this.nextDagboekWithNumbers.verletDagNummer;
        //check null kan later weg, numbers zijn pas later toegevoegd
        let weerverletNummer = this.nextDagboekWithNumbers.weerverletNummer != null ? this.nextDagboekWithNumbers.weerverletNummer : 0;
        let verlofDagNummer = this.nextDagboekWithNumbers.verlofDagNummer != null ? this.nextDagboekWithNumbers.verlofDagNummer : 0;
        let feestDagNummer = this.nextDagboekWithNumbers.feestDagNummer != null ? this.nextDagboekWithNumbers.feestDagNummer : 0;
        let ontoegankelijkheidNummer = this.nextDagboekWithNumbers.ontoegankelijkheidNummer != null ? this.nextDagboekWithNumbers.ontoegankelijkheidNummer : 0;
        let schorsingNummer = this.nextDagboekWithNumbers.schorsingNummer != null ? this.nextDagboekWithNumbers.schorsingNummer : 0;
        if ($event === 'werkdag') {
          werkDagNummmer = this.nextDagboekWithNumbers.werkDagNummmer + 1;
        } else if ($event === 'weerverlet') {
          weerverletNummer = this.nextDagboekWithNumbers.weerverletNummer + 1;
          verletDagNummer = this.nextDagboekWithNumbers.verletDagNummer + 1;
        } else {
          if ($event === 'schorsing') {
            schorsingNummer = this.nextDagboekWithNumbers.schorsingNummer + 1;
          }
          if ($event === 'verlofdag') {
            verlofDagNummer = this.nextDagboekWithNumbers.verlofDagNummer + 1;
          }
          if ($event === 'feestdag') {
            feestDagNummer = this.nextDagboekWithNumbers.feestDagNummer + 1;
          }
          if ($event === 'ontoegankelijk') {
            ontoegankelijkheidNummer = this.nextDagboekWithNumbers.ontoegankelijkheidNummer + 1;
            verletDagNummer = this.nextDagboekWithNumbers.verletDagNummer + 1;
            this.staatForm.controls['toegankelijkheid'] = 'Ontoegankelijk';
          }
        }
        this.staatForm.controls['werkDagNummmer'].setValue(werkDagNummmer.toString());
        this.staatForm.controls['verletDagNummer'].setValue(verletDagNummer.toString());
        this.staatForm.controls['weerverletNummer'].setValue(weerverletNummer.toString());
        this.staatForm.controls['verlofDagNummer'].setValue(verlofDagNummer.toString());
        this.staatForm.controls['feestDagNummer'].setValue(feestDagNummer.toString());
        this.staatForm.controls['ontoegankelijkheidNummer'].setValue(ontoegankelijkheidNummer.toString());
        this.staatForm.controls['schorsingNummer'].setValue(schorsingNummer.toString());
      } else {
        let oldDagboekNumbers = {...this.currentDagboek};
        if(this.currentDagboek.soortVerlet === 'werkdag'){
          oldDagboekNumbers.werkDagNummmer = this.currentDagboek.werkDagNummmer - 1;
        } else if(this.currentDagboek.soortVerlet === 'weerverlet'){
          oldDagboekNumbers.verletDagNummer = this.currentDagboek.verletDagNummer - 1;
          oldDagboekNumbers.weerverletNummer = this.currentDagboek.weerverletNummer - 1;
        } else if(this.currentDagboek.soortVerlet === 'verlofdag'){
          oldDagboekNumbers.verlofDagNummer = this.currentDagboek.verlofDagNummer - 1;
        } else if(this.currentDagboek.soortVerlet === 'feestdag'){
          oldDagboekNumbers.feestDagNummer = this.currentDagboek.feestDagNummer - 1;
        } else if(this.currentDagboek.soortVerlet === 'ontoegankelijk'){
          oldDagboekNumbers.ontoegankelijkheidNummer = this.currentDagboek.ontoegankelijkheidNummer - 1;
          oldDagboekNumbers.verletDagNummer = this.currentDagboek.verletDagNummer - 1;
        } else if(this.currentDagboek.soortVerlet === 'schorsing'){
          oldDagboekNumbers.schorsingNummer = this.currentDagboek.schorsingNummer - 1;
        }
        if ($event === 'werkdag') {
          oldDagboekNumbers.werkDagNummmer = oldDagboekNumbers.werkDagNummmer + 1;
        } else if ($event === 'weerverlet') {
          oldDagboekNumbers.weerverletNummer = oldDagboekNumbers.weerverletNummer + 1;
          oldDagboekNumbers.verletDagNummer = oldDagboekNumbers.verletDagNummer + 1;
        } else {
          if ($event === 'schorsing') {
            oldDagboekNumbers.schorsingNummer = oldDagboekNumbers.schorsingNummer + 1;
          }
          if ($event === 'verlofdag') {
            oldDagboekNumbers.verlofDagNummer = oldDagboekNumbers.verlofDagNummer + 1;
          }
          if ($event === 'feestdag') {
            oldDagboekNumbers.feestDagNummer = oldDagboekNumbers.feestDagNummer + 1;
          }
          if ($event === 'ontoegankelijk') {
            oldDagboekNumbers.verletDagNummer = oldDagboekNumbers.verletDagNummer + 1;
            oldDagboekNumbers.ontoegankelijkheidNummer = oldDagboekNumbers.ontoegankelijkheidNummer + 1;
            this.staatForm.controls['toegankelijkheid'] = 'Ontoegankelijk';
          }
        }
        this.staatForm.controls['werkDagNummmer'].setValue(oldDagboekNumbers.werkDagNummmer.toString());
        this.staatForm.controls['verletDagNummer'].setValue(oldDagboekNumbers.verletDagNummer.toString());
        this.staatForm.controls['weerverletNummer'].setValue(oldDagboekNumbers.weerverletNummer.toString());
        this.staatForm.controls['verlofDagNummer'].setValue(oldDagboekNumbers.verlofDagNummer.toString());
        this.staatForm.controls['feestDagNummer'].setValue(oldDagboekNumbers.feestDagNummer.toString());
        this.staatForm.controls['ontoegankelijkheidNummer'].setValue(oldDagboekNumbers.ontoegankelijkheidNummer.toString());
        this.staatForm.controls['schorsingNummer'].setValue(oldDagboekNumbers.schorsingNummer.toString());
      }
    }

  addOnderaannemerFirma() {
    const onderaannemerFirmas = <FormArray>this.staatForm.get('onderaannemerFirmas');
    const extraOnderaannemer = this.staatForm.controls['extraOnderaannemerFirma'];
    if(extraOnderaannemer.value != null && extraOnderaannemer.value !== ''){
      onderaannemerFirmas.push(this.formBuilder.group({
        firmaNaam: extraOnderaannemer.value,
        arbeiderCount: 1,
        arbeiders: []
      }));
      if(this.onderaannemerFirmas == null) this.onderaannemerFirmas = [];
      this.onderaannemerFirmas.push(new OnderaannemerFirma(extraOnderaannemer.value, 1,[]));
      this.staatForm.controls['extraOnderaannemerFirma'].setValue('');
    }
  }

  async printPidpaDagboeken(){
    this.apiService.getFirstAndLatestDagboek(this.currentWerf._id).subscribe(x => {
      let response = x as any;
      if(response?.firstDagboek?.date != null){
        let firstDagboek = response.firstDagboek as DagboekDerWerken;
        if(firstDagboek.date != null)firstDagboek.date = new Date(firstDagboek.date);
        let latestDagboek = response.latestDagboek as DagboekDerWerken;
        if(latestDagboek.date != null){
          latestDagboek.date = new Date(latestDagboek.date);
          this.currentDate = new Date(latestDagboek.date);
          this.maand = this.maanden[this.currentDate.getMonth()];
          this.jaar = this.currentDate.getFullYear();
        } else if(this.currentWerf.startDatum != null){
          this.currentDate = new Date(this.currentWerf.startDatum);
          this.maand = this.maanden[this.currentDate.getMonth()];
          this.jaar = this.currentDate.getFullYear();
        }
        this.firstDagboek = firstDagboek;
        this.latestDagboek = latestDagboek;
      } else {
        this.firstDagboek = null;
        this.latestDagboek = null;
      }



      let dialogRef = this.dialog.open(DagboekPdfDialogComponent, {
        height: '550px',
        width: '750px',
        data: {werf: this.currentWerf, firstDagboek: this.firstDagboek, latestDagboek: this.latestDagboek},
        panelClass: 'mat-dialog-padding'
      });
      dialogRef.afterClosed().subscribe(() => {
        if(this.formService.isSave != null){
          if(!this.isGeneratingPidpaPdf){
            this.isGeneratingPidpaPdf = true;
            function formatDate(date) {
              let d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

              if (month.length < 2)
                month = '0' + month;
              if (day.length < 2)
                day = '0' + day;

              return [year, month, day].join('-');
            }

            let formattedStartDate = formatDate(this.formService.startDate);
            let formattedEndDate = formatDate(this.formService.endDate);
            let fileName = 'Dagboek-' + this.currentWerf.naam + '-' + formattedStartDate + ' tot ' + formattedEndDate + '.pdf';
            this.apiService.generatePidpaPdf(this.currentWerf._id, fileName,this.formService.startDate.toISOString(), this.formService.endDate.toISOString()).subscribe(x => {
              if(x != null){
                if(x.toString().substring(0,5) ===  'Er zi'){
                  this.toastrService.danger(x.toString(),'Geen dagrapporten gevonden.')
                  this.isGeneratingPidpaPdf = false;
                  return;
                } else {
                  this.isGeneratingPidpaPdf = false;
                  const url = x as string;

                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', fileName); // Optional: specify a filename

                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              }
            }, error => {
              this.isGeneratingPidpaPdf = false;
              this.toastrService.danger('Er is een fout opgetreden bij het genereren van de pdf.','Fout bij genereren pdf');
            });
          }
        }
      });

    });

  }

  getRange(count, arrayLength) {
    return new Array(arrayLength - count);
  }

  addVastMaterieelOpWerf() {
    const vasteMaterieelOpWerf = <FormArray>this.staatForm.get('vastMaterieelOpWerf');
    if(vasteMaterieelOpWerf.value != null && vasteMaterieelOpWerf.value !== '') {
      vasteMaterieelOpWerf.push(this.formBuilder.group({
        naam: '',
        aantal: 1
      }));
    }
  }
  isHigherThenOne(number)   :number {
    if (number == null || number < 1 || number === '') {
      return 0;
    } else {
      return 1;
    }
  }

  removeVastMateriaalOpWerf(i: number) {
    const vasteMaterieelOpWerf = <FormArray>this.staatForm.get('vastMaterieelOpWerf');
    vasteMaterieelOpWerf.removeAt(i);
  }

  async changeStartOfEinduur() {
    await this.delay(50);
    let startUur = this.staatForm.controls['startUur'].value;
    let eindUur = this.staatForm.controls['eindUur'].value;

    if(startUur != null && startUur !== '' && eindUur != null && eindUur !== ''){
      let startUren = startUur.split(':')[0];
      let startMinuten = startUur.split(':')[1];
      let eindUren = eindUur.split(':')[0];
      let eindMinuten = eindUur.split(':')[1];
      let startDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate(), startUren, startMinuten);
      let endDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate(), eindUren, eindMinuten);
      let totalMinutes = (endDate.getTime() - startDate.getTime()) / 60000;
      let hours = Math.floor(totalMinutes / 60);
      let minutes = Math.round(totalMinutes) % 60;
      let werkUrenDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate(), hours,minutes);
      this.staatForm.controls['werkuren'].setValue(werkUrenDate);
    }
  }
  changeWerkUren($event) {

    let werkUren = $event.time;
    if(werkUren != null && werkUren !== ''){
      let hours = werkUren.getHours();
      let minutes = werkUren.getMinutes();
      let startUurOfBedrijf = this.currentWerf.startUur;
      if(startUurOfBedrijf == null || startUurOfBedrijf === ''){
        startUurOfBedrijf = this.company.startUur;
      }

      let startUur = startUurOfBedrijf.split(':')[0];
      let startMinuten = startUurOfBedrijf.split(':')[1];
      let startDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate(), +startUur, +startMinuten);
      let eindDate = moment(startDate).add(hours,'hours').toDate();
      eindDate = moment(eindDate).add(minutes,'minutes').toDate();

      this.staatForm.controls['startUur'].setValue(this.formatDateToHourAndMinute(startDate));
      this.staatForm.controls['eindUur'].setValue(this.formatDateToHourAndMinute(eindDate));
    }
  }
  removeOnderaannemerFirma(i: number) {
    const onderaannemerFirmas = <FormArray>this.staatForm.get('onderaannemerFirmas');
    onderaannemerFirmas.removeAt(i);
  }

  removeMachine(i: number) {
    const machines = <FormArray>this.staatForm.get('machines');
    machines.removeAt(i);
  }

  removeMateriaal(i: number) {
    const materialen = <FormArray>this.staatForm.get('materialen');
    materialen.removeAt(i);
  }

  async changeDeleteDagboek(isoDate: string) {
    this.isDeleteDagboek = true;
    await this.delay(20);
    let hasDeletedDagboekCheckbox = false;
    for (const controlName in this.deleteDagboekForm.controls) {
      if (Object.hasOwnProperty.call(this.deleteDagboekForm.controls, controlName)) {
        const controlValue = this.deleteDagboekForm.controls[controlName].value;

        if (controlValue === false) {
          hasDeletedDagboekCheckbox = true;
          break; // Exit the loop as we have found an unchecked checkbox
        }
      }
    }
    this.canDeleteDagboeken = hasDeletedDagboekCheckbox;
    this.isDeleteDagboek = false;
  }

  verwijderDagboeken() {
    let verwijderDagboeken: string[] = [];

    // Loop through the form controls
    for (const controlName in this.deleteDagboekForm.controls) {
      if (Object.hasOwnProperty.call(this.deleteDagboekForm.controls, controlName)) {
        const control = this.deleteDagboekForm.controls[controlName];

        // Check if the control's value is true
        if (control.value === false) {
          verwijderDagboeken.push(controlName);
        }
      }
    }

    let dagboekIds = [];
    for(let isodate of verwijderDagboeken){
      dagboekIds.push({dagboekId:this.dagboekenOfMonth.find(x => x.isoDate === isodate)._id});
    }

    this.apiService.verwijderDagboeken(dagboekIds).subscribe(x => {
      this.isViewingDay = false;
      this.isLoaded = false;
      this.opmerkingen = [];
      for(let isodate of verwijderDagboeken){
        this.dagboekenOfMonth = this.dagboekenOfMonth.filter(x => x._id !== isodate);
        this.kopieerDagboeken = this.kopieerDagboeken.filter(x => x._id !== isodate);
      }
      this._id = null;
      this.dagboekenOfMonth = [];
        this.reloadList();
    });
  }


  get werkzaamhedenControls(): FormControl[] {
    return (this.staatForm.get('werkzaamheden') as FormArray).controls as FormControl[];
  }
  get materialenControls(): FormControl[] {
    return (this.staatForm.get('materialen') as FormArray).controls as FormControl[];
  }
  get vasteMaterieelOpWerfControls(): FormControl[] {
    return (this.staatForm.get('vastMaterieelOpWerf') as FormArray).controls as FormControl[];
  }
  get onderaannemerFirmasControls(): FormControl[] {
    return (this.staatForm.get('onderaannemerFirmas') as FormArray).controls as FormControl[];
  }
  get photoControls(): FormControl[] {
    return (this.staatForm.get('photos') as FormArray).controls as FormControl[];
  }

  delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }
  werkzaamhedenGetter() : UntypedFormArray {
    return this.staatForm.get("werkzaamheden") as UntypedFormArray
  }
  vastMaterieelOpWerfGetter() : UntypedFormArray {
    return this.staatForm.get("vastMaterieelOpWerf") as UntypedFormArray
  }

  addWerkzaamheid() {
    const werkzaamhedenArray = <FormArray>this.staatForm.get('werkzaamheden');
    werkzaamhedenArray.push(new FormControl(''));
  }
  setOnderaanneemFirma(firmaNaam: string, arbeiderCount: number, arbeiders: User[]): UntypedFormGroup {
    return this.formBuilder.group({
      firmaNaam: firmaNaam,
      arbeiderCount: arbeiderCount,
      arbeiders: this.formBuilder.array(arbeiders)
    });
  }
  setPhoto(fotoNaam: string, foto: string, addedFoto: boolean): UntypedFormGroup {
    return this.formBuilder.group({
      fotoNaam: fotoNaam,
      foto: foto,
      addedFoto: addedFoto
    });
  }
  setVastMaterieelOpwerf(naam: string, aantal: number): UntypedFormGroup {
    return this.formBuilder.group({
      naam,
      aantal
    });
  }
  setMateriaal(naam: string, hoeveelheid: string): UntypedFormGroup {
    return this.formBuilder.group({
      naam,
      hoeveelheid
    });
  }

  calculateWorkHours(startDate, endDate) {
    const millisecondsDifference = endDate.getTime() - startDate.getTime();
    const totalMinutes = millisecondsDifference / 60000;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.round(totalMinutes) % 60;

    return `${hours}U ${minutes}MIN`;
  }

  imagePopUp(photo: string) {
    this.formService._chosenPhoto = photo;
    let dialogRef = this.dialog.open(PhotoPopupDialog, {
      height: '98vh',
      width: '42vw',
      panelClass: 'mat-dialog-padding'
    });
  }

  kopieerDagboek() {

    let latestDagboek = this.kopieerDagboeken[0];
    //asiggin new object

    let origiDagboek = Object.assign({},this.kopieerDagboeken.find(x => x._id === this.kopieerDagboekSelect));
    origiDagboek._id = null;
    origiDagboek.date = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate(), 4);
    origiDagboek.isoDate = origiDagboek.date.toISOString().split('T')[0];
    if(origiDagboek.startDate != null){
      origiDagboek.startDate = new Date(origiDagboek.startDate);
      origiDagboek.startDate = new Date(origiDagboek.date.getFullYear(), origiDagboek.date.getMonth(), origiDagboek.date.getDate(), origiDagboek.startDate.getHours(), origiDagboek.startDate.getMinutes());
    }
    if(origiDagboek.endDate != null){
      origiDagboek.endDate = new Date(origiDagboek.endDate);
      origiDagboek.endDate = new Date(origiDagboek.date.getFullYear(), origiDagboek.date.getMonth(), origiDagboek.date.getDate(), origiDagboek.endDate.getHours(), origiDagboek.endDate.getMinutes());
    }

    if((origiDagboek.werkuren == null || origiDagboek.werkuren === '') && origiDagboek.startDate != null && origiDagboek.endDate != null){
      origiDagboek.werkuren = this.calculateWorkHours(origiDagboek.startDate, origiDagboek.endDate);
    }

    if(origiDagboek.soortVerlet != null && origiDagboek.soortVerlet !== ''){
      origiDagboek.bladNummer = +latestDagboek.bladNummer + 1;
      origiDagboek.werkDagNummmer = +latestDagboek.werkDagNummmer;
      origiDagboek.verletDagNummer = +latestDagboek.verletDagNummer;
      origiDagboek.weerverletNummer = +latestDagboek.weerverletNummer;
      origiDagboek.verlofDagNummer = +latestDagboek.verlofDagNummer;
      origiDagboek.feestDagNummer = +latestDagboek.feestDagNummer;
      origiDagboek.ontoegankelijkheidNummer = +latestDagboek.ontoegankelijkheidNummer;
      if (origiDagboek.soortVerlet === 'werkdag') {
        origiDagboek.werkDagNummmer = +latestDagboek.werkDagNummmer + 1;
      } else if (origiDagboek.soortVerlet === 'weerverlet') {
        origiDagboek.weerverletNummer = +latestDagboek.weerverletNummer + 1;
        origiDagboek.verletDagNummer = +latestDagboek.verletDagNummer + 1;
      } else {
        if (origiDagboek.soortVerlet === 'schorsing') {
          origiDagboek.schorsingNummer = +latestDagboek.schorsingNummer + 1;
        }
        if (origiDagboek.soortVerlet === 'verlofdag') {
          origiDagboek.verlofDagNummer = +latestDagboek.verlofDagNummer + 1;
        }
        if (origiDagboek.soortVerlet === 'feestdag') {
          origiDagboek.feestDagNummer = +latestDagboek.feestDagNummer + 1;
        }
        if (origiDagboek.soortVerlet === 'ontoegankelijk') {
          origiDagboek.verletDagNummer = +latestDagboek.verletDagNummer + 1;
          origiDagboek.ontoegankelijkheidNummer = +latestDagboek.ontoegankelijkheidNummer + 1;
        }
      }
    }

    origiDagboek.created = null;
    origiDagboek.updated = null;

    this.staatForm = this.formBuilder.group({
      werkzaamheden: new FormArray([]),
      bladNummer: origiDagboek.bladNummer,
      werkDagNummmer: origiDagboek.werkDagNummmer,
      verletDagNummer: origiDagboek.verletDagNummer,
      weerverletNummer: origiDagboek.weerverletNummer,
      verlofDagNummer: origiDagboek.verlofDagNummer,
      feestDagNummer: origiDagboek.feestDagNummer,
      ontoegankelijkheidNummer: origiDagboek.ontoegankelijkheidNummer,
      schorsingNummer: origiDagboek.schorsingNummer,
      toegankelijkheid: origiDagboek.toegankelijkheid,
      werkuren: origiDagboek.werkuren && origiDagboek.werkuren !== '0U'  ? this.werkUrenToDate(origiDagboek.werkuren) : null,
      startUur: origiDagboek.startDate ? this.formatDateToHourAndMinute(origiDagboek.startDate) : null,
      eindUur: origiDagboek.endDate ?this.formatDateToHourAndMinute(origiDagboek.endDate) : null,
      temp8h: this.staatForm.controls['temp8h'].value,
      temp13h: this.staatForm.controls['temp13h'].value,
      weergesteldheid:  this.staatForm.controls['weergesteldheid'].value,
      regenGedurendeUren: this.staatForm.controls['regenGedurendeUren'].value,
      toestandVorigeNacht: this.staatForm.controls['toestandVorigeNacht'].value,
      toestandVoormiddag: this.staatForm.controls['toestandVoormiddag'].value,
      toestandNamiddag: this.staatForm.controls['toestandNamiddag'].value,
      arbeiderCount:  origiDagboek.arbeiderCount,
      machinistenCount:origiDagboek.machinistenCount,
      chauffeursCount: origiDagboek.chauffeursCount,
      onderaannemerFirmas: new FormArray([]),
      materialen: new FormArray([]),
      machines: new FormArray([]),
      photos: new FormArray([]),
      monsternameProeven: origiDagboek.monsternameProeven,
      opmerkingen: origiDagboek.opmerkingen,
      getroffenBeslissingen: origiDagboek.getroffenBeslissingen,
      bezoekenAllerlei: origiDagboek.bezoekenAllerlei,
      soort: origiDagboek.soort,
      soortVerlet: origiDagboek.soortVerlet,
      extraOnderaannemerFirma: '',
      vastMaterieelOpWerf: new FormArray([])
    });
    // Voorbeeld: toevoegen van initiële werkzaamheden aan de FormArray
    const werkzaamhedenArray = <FormArray>this.staatForm.get('werkzaamheden');
    origiDagboek.werkzaamheden.forEach(werk => {
      werkzaamhedenArray.push(new FormControl(werk));
    });
    const materialenArray = <FormArray>this.staatForm.get('materialen');
    origiDagboek.materialen.forEach(materiaal => {
      materialenArray.push(this.setMateriaal(materiaal.naam, (materiaal.hoeveelheid == null || materiaal.hoeveelheid === 'null' ? '' : materiaal.hoeveelheid)));
    });
    const onderaannemerFirmaArray = <FormArray>this.staatForm.get('onderaannemerFirmas');
    if(origiDagboek.onderaannemerFirmas != null){
      origiDagboek.onderaannemerFirmas.forEach(onderaanneemFirma => {
        onderaannemerFirmaArray.push(this.setOnderaanneemFirma(onderaanneemFirma.firmaNaam, onderaanneemFirma.arbeiders.length, onderaanneemFirma.arbeiders));
      });
    }
    const machinesArray = <FormArray>this.staatForm.get('machines');
    if(origiDagboek.machines != null){
      origiDagboek.machines.forEach(machine => {
        let index = machinesArray.value.findIndex(x => x.naam === machine.naam);
        if(index === -1){
          machinesArray.push(this.setMachine(machine.naam,1));
        } else {
          machinesArray.value[index].aantal += 1;
        }
      });
    }
    let vastMaterieelOpWerfArray = <FormArray>this.staatForm.get('vastMaterieelOpWerf');
      if(origiDagboek.vastMaterieelOpWerf != null){
         origiDagboek.vastMaterieelOpWerf.forEach(mat => {
          vastMaterieelOpWerfArray.push(this.setVastMaterieelOpwerf(mat.naam, mat.aantal));
        });
    }
      this.arbeiders = origiDagboek.arbeiders;
      this.chauffeurs = origiDagboek.chauffeurs;
      this.machinisten = origiDagboek.machinisten;
      this.onderaannemerFirmas = origiDagboek.onderaannemerFirmas;
  }

  selectWerfOfWerfleider(werf: Werf) {
    this.showWervenOfWerfleider = false;
    this.input.nativeElement.value = werf.naam;
    this.currentWerf = werf;
    this.clickAutoCompleteWerf(this.currentWerf.naam);
  }
  getWeatherForecastOfAllActiveWerven(todayOrTommorow: string) {
    if(this.isViewingWeerVoorspelling){
      this.isViewingWeerVoorspelling = false;
      return;
    }
    this.wervenWithWeerVoorspelling = [];
    this.isViewingWeerVoorspelling = true;

    let werfData = [];
    let dateOfForecast = new Date();
    if(todayOrTommorow === 'tomorrow'){
      dateOfForecast.setDate(dateOfForecast.getDate() + 1);
    }
    this.currentDate = dateOfForecast;
    for(let werf of this.actieveWerven){
      werfData.push({werf: werf._id, lat: werf.latCoord, lon: werf.longCoord});
    }
    this.actieveWerven.sort((a,b) => {
      if(!b.isNogNietGestart && a.isNogNietGestart) return 1;
      if(b.isNogNietGestart && !a.isNogNietGestart) return -1;
      if(!a.isWerfleiderOfThisWerf && b.isWerfleiderOfThisWerf) return 1;
      if(a.isWerfleiderOfThisWerf && !b.isWerfleiderOfThisWerf) return -1;
      if(a.gemeente == null || a.gemeente === '') return 1;
      if(b.gemeente == null || b.gemeente === '') return -1;
      return a.gemeente.localeCompare(b.gemeente);
    });
    this.apiService.getWeatherForecastOfAllActiveWerven(werfData, todayOrTommorow).subscribe(x => {

     let weerVoorspellingWerven = x as {werf: string, foreCastData: DayWeatherData}[];
      let actieveWerven = [...this.actieveWerven];

      for(let werf of actieveWerven){
        let weerVoorspellingWerf = weerVoorspellingWerven.find(x => x.werf === werf._id);
        if(weerVoorspellingWerf?.foreCastData != null){
          let weerVoorspelling = weerVoorspellingWerf.foreCastData[dateOfForecast.getFullYear() + '-' + ('0' + (dateOfForecast.getMonth() + 1)).slice(-2) + '-' + ('0' + dateOfForecast.getDate()).slice(-2)];
          if(weerVoorspelling == null){
            werf.weatherData = null;
            werf.soort = 'geen coördinaten ingesteld';
            werf.extraInfoOverIsWeerverlet = 'geen coördinaten ingesteld';
          } else {
            let extraInfoSoortWeerverlet;
            if(todayOrTommorow === 'tomorrow'){
              //heeft ook uren 0 tot 6
              werf.weatherData = this.spliceOnnodigeHoursMetVorigeNacht(weerVoorspelling);
              for(let index = 0; index < werf.weatherData.hourly.length; index++){
                werf.weatherData.hourly[index].hour = index.toString();
              }
              let weervoorspellingWithoutFirst6Hours = {...weerVoorspelling};
              weervoorspellingWithoutFirst6Hours.hourly = weervoorspellingWithoutFirst6Hours.hourly.slice(7,weervoorspellingWithoutFirst6Hours.hourly.length)
              extraInfoSoortWeerverlet = this.bepaalWeerverlet(weervoorspellingWithoutFirst6Hours);
            } else {
              werf.weatherData = this.spliceOnnodigeHours(weerVoorspelling);
              for(let index = 0; index < werf.weatherData.hourly.length; index++){
                werf.weatherData.hourly[index].hour = (index + 7).toString();
              }
              extraInfoSoortWeerverlet = this.bepaalWeerverlet(weerVoorspelling);
            }

            if(extraInfoSoortWeerverlet != null) {
              werf.soort = 'weerverlet';
              werf.extraInfoOverIsWeerverlet = extraInfoSoortWeerverlet;
            } else {
              werf.soort = 'geen weerverlet';
              werf.extraInfoOverIsWeerverlet = null;
            }
          }
        }
      }

      this.wervenWithWeerVoorspelling = actieveWerven;
      console.log(actieveWerven);
    });
  }

  goToEditWerf() {
    this.formService.comingFromDagboeken = true;
    this.router.navigate(['/pages/materieel-instellen/editwerf', this.currentWerf._id]);
  }

  openWeerverletBepalingPopup() {
    this.dialog.open(ExtraInfoWeerverletBepaling, {
      width: '600px'
    });
  }

  deleteStaatDerVerletOfMonth() {
      const dialogRef = this.dialog.open(DeleteDialogStaatDerVerlet, {
        width:'470px',
        panelClass: 'mat-dialog-padding'
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if(result){
          this.apiService.deleteStaatDerVerletOfMonth(this.currentStaatDerVerlet._id).subscribe(x => {
            this.reloadList();
          });
        }
      });

  }
  getSoortVerletClass(): string {
    const soortVerletValue = this.staatForm.get('soortVerlet')?.value;
    return soortVerletValue ? soortVerletValue : '';
  }
  goToWerfList() {
    this.formService.materieelKeuze = 'Werven';
    this.router.navigate(['/pages/materieel-instellen']);
  }
  voegOpmerkingToeAanDagboek(opmerking: string) {
     if(opmerking && opmerking !== ''){
       let opmerkingIndex = this.opmerkingen.findIndex(x => x.opmerking === opmerking);
        if(opmerkingIndex !== -1){
          this.opmerkingen.splice(opmerkingIndex,1);
          let opmerkingenString =  this.staatForm.controls['opmerkingen'].value;
          if(opmerkingenString.length > 0){
            opmerkingenString += '\n';
          }
          opmerkingenString += opmerking ;
          this.staatForm.controls['opmerkingen'].setValue(opmerkingenString);
        }
     }
  }
  convertToshortHourText(werkUren: string) {
    if(werkUren == null || werkUren === ''){
      return '';
    }
    werkUren = werkUren.replace(' ','');
    let hours = werkUren.split('U')[0];
    let minutes = '0';
    if(werkUren.split('U')[1]){
      minutes = werkUren.split('U')[1].split('MIN')[0];
    }
    return hours + 'u' + minutes;
  }
  closeWeerVoorspelling() {
    this.currentIsoDate = null;
    this.isViewingWeerVoorspelling = false;
  }
  protected readonly faClouds = faClouds;
  protected readonly faSun = faSun;
  protected readonly faCloudHailMixed = faCloudHailMixed;
  protected readonly faPersonDigging = faPersonDigging;
  protected readonly faCalendarClock = faCalendarClock;

  nullToZero(arbeiderCount: number) {
    if(arbeiderCount == null){
      return 0;
    }
    return arbeiderCount;
  }
v
  protected readonly faFloppyDisk = faFloppyDisk;
  protected readonly faGear = faGear;
  protected readonly faArrowRightLong = faArrowRightLong;


  protected readonly faRoadBarrier = faRoadBarrier;
  protected readonly faPause = faPause;
  protected readonly faUmbrellaBeach = faUmbrellaBeach;
  protected readonly faGift = faGift;
  protected readonly NbTooltipModule = NbTooltipModule;
  protected readonly faTrash = faTrash;

  protected readonly faLock = faLock;
  protected readonly faTimes = faTimes;
  protected readonly faCross = faCross;
  protected readonly faXmark = faXmark;
  protected readonly faHashtag = faHashtag;


  protected readonly faCloudSun = faCloudSun;
  protected readonly faArrowLeftFromBracket = faArrowLeftFromBracket;


}
@Component({
  selector: 'delete-dialog-staatderverlet',
  templateUrl: 'delete-staatderverlet-dialog.html',
})
export class DeleteDialogStaatDerVerlet {
  constructor(
    public dialogRef: MatDialogRef<DeleteDialogStaatDerVerlet>,
    public formService: FormService
  ) {
    this.formService.isDelete = false;
  }

  onNoClick(): void {
    this.formService.isDelete = false;
    this.dialogRef.close();
  }

  onDeleteClick() {
    this.formService.isDelete = true;
    this.dialogRef.close();
  }
}
