<nb-spinner *ngIf="isSaving" class="opslaan-spinner" size="giant" [message]="'Opslaan..'" status="info"></nb-spinner>

<mat-card-title class="custom-title"><i class="fa-solid fa-users-line bigFaIcon"></i>Ploegen instellen
  <button (click)="savePloegen()" [disabled]="!hasChangedValue" mat-raised-button class="save-button" color="primary">Opslaan</button>

    <h4 style="color: #333;margin-bottom: 2px;margin-top: -9px">Toepassingen:</h4>
    <ul style="padding:0 0 0 40px; margin:0;">
      <li>Prioriteit arbeiders in mobiele app voor ploegbaas</li>
      <li>Werfplanning</li>
      <li>Identiek aan "Voorkeurarbeiders/ploeg" bij een gebruiker</li>
    </ul>
 </mat-card-title>
<mat-card  *ngIf="isLoaded" class="scroll-table">
    <div class="ploegen-beheer">
      <div style="margin-bottom: 8px;" class="ploeg-column">
        <h3 style="margin-bottom: 5px" class="text-lg font-medium mb-2"><b>Beschikbare Arbeiders</b></h3>
        <div
          cdkDropList
          #unassignedList="cdkDropList"
          [cdkDropListData]="unassignedUsers"
          [cdkDropListConnectedTo]="getAllConnectedLists()"
          (cdkDropListDropped)="drop($event)"
          class="user-list-unassigned"
        >
          <div *ngFor="let user of unassignedUsers" cdkDrag class="user-box">
            {{user.name}}
          </div>
        </div>
          <p  cdkDropList #newPloegList="cdkDropList"
              [cdkDropListData]="[]"
              [cdkDropListConnectedTo]="[unassignedList]"
              (cdkDropListDropped)="dropNewPloeg($event)" class="createGroupDropArea"><i style="font-size: 15px; margin-right: 7px;" class="fa fa-plus"></i> <b>Nieuwe ploeg aanmaken?</b><br>
            Sleep een ploegbaas naar dit vak</p>
      </div>



      <div class="ploegen-grid">

        <div *ngFor="let ploeg of ploegen; let i = index" class="ploeg-column extraSpecsPloeg">
          <div class="ploeg-header">
            <p style="color:#3c3cff;margin-bottom: 0" class="text-lg font-large"><i class="fa fa-users-line" style="font-size: 16px;margin-right: 1px;color:#3c3cff;"></i>  {{ploeg.ploegbaas.name}}</p>
            <button  class="closeButton" nbButton status="warning" (click)="removePloeg(i)">
              <nb-icon style="font-size: 16px" pack="fa" icon="xmark"></nb-icon>
            </button>
          </div>
          <div
            cdkDropList
            #ploegList="cdkDropList"
            [cdkDropListData]="ploeg.voorkeurArbeiders"
            [cdkDropListConnectedTo]="getConnectedListsForPloeg(ploegList)"
            (cdkDropListDropped)="drop($event)"
            class="user-list" style="padding:3px;"
          >
            <div *ngFor="let member of ploeg.voorkeurArbeiders;let j = index" cdkDrag class="user-box" >
              {{member.name}}
            </div>
          </div>
      </div>
    </div>
  </div>
</mat-card>

