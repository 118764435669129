import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogActions,
  MatDialogTitle,
  MatDialogClose
} from '@angular/material/dialog';
import { MatButtonModule } from "@angular/material/button";
import { NgIf } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: 'app-confirm-delete-ploeg',
  template: `
    <h2 mat-dialog-title class="dialog-title">
      <mat-icon color="warn">warning</mat-icon>
      Ploeg verwijderen
    </h2>
    <mat-dialog-content>
      <p class="message">Bent u zeker dat u de ploeg <strong>"{{ data.ploegName }}"</strong> wilt verwijderen?</p>
      <p *ngIf="data.hasMembers == true" class="warning">
        <mat-icon style="min-width: 35px">info</mat-icon>
        Let op: Deze ploeg bevat nog arbeiders. Ze zullen worden verplaatst naar de beschikbare arbeiders.
      </p>
    </mat-dialog-content>
    <mat-dialog-actions style="margin-top: 3px" align="end">
      <button mat-stroked-button [mat-dialog-close]="false">Annuleren</button>
      <button style="margin-right: 5px;margin-left: 15px" mat-raised-button color="warn" [mat-dialog-close]="true">
        <mat-icon>delete</mat-icon>
        Verwijderen
      </button>
    </mat-dialog-actions>
  `,
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatButtonModule,
    MatDialogTitle,
    NgIf,
    MatDialogClose,
    MatIconModule
  ],
  styles: [`
    .dialog-title {
      display: flex;
      align-items: center;
      color: #f44336;
      margin-bottom: 8px;
    }
    .dialog-title mat-icon {
      margin-right: 8px;
    }
    .message {
      font-size: 16px;
      margin-bottom: 16px;
    }
    .warning {
      display: flex;
      align-items: center;
      background-color: #fff3e0;
      padding: 12px;
      border-radius: 4px;
      color: #e65100;
    }
    .warning mat-icon {
      margin-right: 8px;
    }
    mat-dialog-actions {
      margin-top: 24px;
    }
  `]
})
export class ConfirmDeleteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { ploegName: string; hasMembers: boolean }
  ) {}
}
