<nb-spinner *ngIf="isSaving" class="opslaan-spinner" size="giant" [message]="'Opslaan..'" status="info"></nb-spinner>

<div class="buttonRow">
  <h1 class="custom-title">Eigen verlofdagen instellen</h1>
  <button mat-raised-button class="nav-button-black" (click)="goBack()"><i class="fa fa-chevron-left"></i> Terug</button>


  <button [disabled]="isSaving || !hasChangedValue" (click)="opslaan()" mat-raised-button class="form-button-blue" color="primary">
    <div class="divInsideButton">
      <fa-icon class="normalIcon" [icon]="faFloppyDisk"></fa-icon>
      <span class="button-text">Opslaan</span></div>
  </button>
</div>
<nb-card style="max-width:1000px;"  class="scroll-table2">
  <form *ngIf="isLoaded" [formGroup]="form" class="form-container">
    <button style="margin-top: 10px;margin-right:50px; align-self: flex-end !important;" class="btn btn-add" (click)="voegVerlofdagToe()">
      Voeg Datum of Periode toe <i style="margin-left:5px" class="fas fa-plus-circle"></i>
    </button>
    <div formArrayName="verlofdagen" class="verlofdagen-container">
      <div *ngFor="let verlofdag of verlofdagen().controls; let i = index">
        <div [formGroupName]="i" class="verlofdag-item">
          <input class="selectBox" formControlName="datum" nbInput placeholder="Stel hier een nieuwe verlofdatum in.." [nbDatepicker]="datePicker">
          <nb-rangepicker (rangeChange)="checkIfItNeedsToAddNewVerlofInput($event, i)" #datePicker></nb-rangepicker>
          <button  mat-raised-button class="delete-button" color="warn" type="button"
                  (click)="verwijderVerlofdag(i)"><i style="margin-left:1px !important;" class="fa-solid fa-trash-can"></i></button>
          <span *ngIf="form.get('verlofdagen').get(i.toString()).get('datum').value?.start != null && form.get('verlofdagen').get(i.toString()).get('datum').value?.start !== ''" class="datumText">
  {{ getDutchDayName(form.get('verlofdagen').get(i.toString()).get('datum').value?.start) }} {{ (form.get('verlofdagen').get(i.toString()).get('datum').value?.start) | date:'shortDate' }}
            {{form.get('verlofdagen').get(i.toString()).get('datum').value?.end != null && form.get('verlofdagen').get(i.toString()).get('datum').value?.end !== '' ? ' tot ': ''}}
          <span *ngIf="form.get('verlofdagen').get(i.toString()).get('datum').value?.end != null && form.get('verlofdagen').get(i.toString()).get('datum').value?.end !== ''" >
                {{ getDutchDayName(form.get('verlofdagen').get(i.toString()).get('datum').value?.end) }} {{ (form.get('verlofdagen').get(i.toString()).get('datum').value?.end) | date:'shortDate' }}</span>
</span>
        </div>
      </div>
    </div>
  </form>


</nb-card>
