import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import { Router } from '@angular/router';
import {NbToastrService} from "@nebular/theme";
import {ApiService} from "../../services/api.service";
import {Dagrapport} from "../../models/dagrapport";
import moment from 'moment';
import {Werf} from "../../models/werf";
import {ExcelService} from "../../services/ExcelService";
import {Company} from "../../models/company";
import {Werk} from "../../models/werk";
import {Werkzaamheid} from "../../models/werkzaamheid";
import {Subactiviteit} from "../../models/subactiviteit";
import {forkJoin, Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {Transportdagrapport} from "../../models/transport-dagrapport";
import {DagboekExcelService} from "../../services/DagboekExcelService";
import {User} from "../../models/user";

@Component({
  selector: 'app-overzicht-dagrapport-page',
  templateUrl: './overzicht-dagrapporten.component.html',
  styleUrls: ['./overzicht-dagrapporten.component.scss'],
})
export class OverzichtDagrapportenComponent implements OnInit {
  @ViewChild('autoInput') input;
  @ViewChild('autoInput2') inputChauffeur;
  @ViewChild('autoInput3') inputArbeider;

  datePicker ;
  dagrapporten: Dagrapport[];
  date: Date;
  isWaiting: boolean = false;
  chosenWerfId: any;
  chosenWerf: Werf;
  werven: Werf[];
  company: Company;
  earliestWerkDate: Date;
  latestWerkDate: Date;
  isLoaded: boolean = false;
  betweenDates: boolean = false;
  isOneDate: boolean = false;
  isGenerating: boolean = false;
  users2Werven;
  allWerkzaamheden: Werkzaamheid[] = [];
  allDagrapporten: Dagrapport[] = [];
  chosenWerkzaamheidId: any;
  currentWerkzaamheidHasSubActiviteiten: boolean = false;
  currentSubActiviteiten : Subactiviteit[] = [];
  chosenSubActiviteit: any;
  allWerken: Werk[] = [];
  heeftKostenBerekening: boolean = false;
  isPrint: boolean = false;
  myDate: any;
  filterSelect: any;
  filterItems: Werf[] = [];
  observableFilteredItems$: Observable<string[]>;
  transportDagrapporten: Transportdagrapport[];
  werfFilter: any;
  allUsers: User[];
  allChauffeurs: User[];
  filterChauffeurs: User[] = [];
  observableFilteredChauffeurs$: Observable<string[]>;
  chauffeurFilter: any;
  chosenChauffeurId: string;
  allArbeiders: User[];
  filterArbeiders: User[] = [];
  observableFilteredArbeiders$: Observable<string[]>;
  arbeiderFilter: any;
  chosenArbeiderId: string;
  constructor(private formBuilder: UntypedFormBuilder, private router: Router,
              private apiService: ApiService, private toastrService: NbToastrService,
              private excelService: ExcelService, private dagboekExcelService: DagboekExcelService) {
  }

  ngOnInit() {
    //this.generateDateDagrapport(new Date(2022,7,25).toDateString());
    this.isGenerating = false;
    this.isPrint = false;
    this.currentWerkzaamheidHasSubActiviteiten = false;
    this.apiService.getWervenLight().subscribe(x => {
      this.werven = x as Werf[];
      this.werven.sort((a,b) => a.naam.localeCompare(b.naam));
      this.filterItems = this.werven;
      this.observableFilteredItems$ = of(this.filterItems.map(x => x.naam));
    })
    this.apiService.getUsers().subscribe(x => {
      this.allUsers = x as User[];
      this.allChauffeurs = this.allUsers.filter(x => x.role === '59cf78e883680012b0438502' && x.name !== 'bjorn massoels');
      this.allArbeiders = this.allUsers.filter(x => x.role === '59cf78e883680012b0438501' && x.name !== 'bjorn massoels');
      this.filterArbeiders = Object.assign([], this.allArbeiders);
      this.filterChauffeurs = Object.assign([],this.allChauffeurs);
      this.observableFilteredChauffeurs$ = of(this.filterChauffeurs.map(x => x.name));
      this.observableFilteredArbeiders$ = of(this.filterArbeiders.map(x => x.name));
    });
    this.apiService.getCompany().subscribe(x => {
      this.company = x as Company;
      if(this.company._id == null) this.company._id = this.company.id;
      this.heeftKostenBerekening = this.company.heeftKostenBerekening;
    })
    this.apiService.getWerkzaamheden().subscribe(x => {
      this.allWerkzaamheden = x as Werkzaamheid[];
    })
  }

  async generateDagrapport(datePicker, chosenWerfId: string, chosenChauffeurId: string, chosenArbeiderId: string) {
    if(!this.isGenerating){
      this.isGenerating = true;
      this.isPrint = false;
      this.users2Werven = [];
      this.chosenWerf = this.werven?.find(x => x._id === chosenWerfId);
      if(chosenWerfId == null){
        chosenWerfId = 'geen';
      }
      if(chosenChauffeurId == null ||chosenChauffeurId === '')chosenChauffeurId = 'geen';
      if(chosenArbeiderId == null ||chosenArbeiderId === '')chosenArbeiderId = 'geen';
      if(chosenArbeiderId != null && chosenArbeiderId !== '' && chosenArbeiderId !== 'geen' && chosenChauffeurId != null && chosenChauffeurId !== '' && chosenChauffeurId !== 'geen'){
        this.toastrService.danger('Kies een ploegbaas of chauffeur apart. Het is niet mogelijk om dit samen te genereren','Kies een ploegbaas of chauffeur apart.')
        this.isGenerating = false;
        return;
      }
      if(datePicker?.queue?.start != null || datePicker?.queue?.end != null){
        let searchDate;
        if(datePicker?.queue?.start == null){
          searchDate = datePicker;
          this.date = new Date(searchDate);
          this.betweenDates = false;
          this.isOneDate = true;
          await this.getDagrapportenPDF(this.date.toDateString(), 'geen', chosenWerfId, chosenChauffeurId, chosenArbeiderId);
        } else if(datePicker.queue.end == null) {
          this.date = new Date(datePicker.queue.start);
          this.betweenDates = false;
          this.isOneDate = true;
          await this.getDagrapportenPDF(this.date.toDateString(), 'geen', chosenWerfId, chosenChauffeurId, chosenArbeiderId);
        } else {
          if(datePicker.queue.start.toString() === datePicker.queue.end.toString()){
            this.date = new Date(datePicker.queue.start);
            this.betweenDates = false;
            await this.getDagrapportenPDF(this.date.toDateString(), 'geen', chosenWerfId, chosenChauffeurId, chosenArbeiderId);
          } else {
            let startDate = moment(datePicker.queue.start);
            this.date = startDate.toDate();
            this.isOneDate = false;
            this.betweenDates = true;
            let endDate = moment(datePicker.queue.end).toDate();
            await this.getDagrapportenPDF(this.date.toDateString(), endDate.toDateString(), chosenWerfId, chosenChauffeurId, chosenArbeiderId);
          }
        }
      } else if(chosenWerfId !== 'geen' || chosenChauffeurId !== 'geen' || chosenArbeiderId !== 'geen'){
        this.betweenDates = false;
        this.isOneDate = false;
        await this.getDagrapportenPDF('geen','geen',chosenWerfId, chosenChauffeurId, chosenArbeiderId);
      } else {
        this.toastrService.danger('Gelieve iets te kiezen als filter','Kies een filter.')
        this.isGenerating = false;
      }
    }
  }


  getDagrapportenPDF(date1: string, date2: string, chosenWerfId: string, chosenChauffeurId: string, chosenArbeiderId: string) {
    let chosenWerfName;
    let realDate1;
    let realDate2;
    if(date1 != null && date1 !== 'geen'){
      realDate1 = new Date(date1);
    }else{
      date1 = null;
    }
    if(date2 != null && date2 !== 'geen'){
      realDate2 = new Date(date2);
    } else {
      date2 = null;
    }
    let fileName = 'dagrapporten';
    if(chosenChauffeurId !== 'geen') {
      fileName = fileName +  '-' + this.allChauffeurs.find(x => x._id === chosenChauffeurId).name;
    }
    if(chosenArbeiderId !== 'geen') {
      fileName = fileName +  '-' +this.allArbeiders.find(x => x._id === chosenArbeiderId).name ;
    }
    if(chosenWerfId != null && chosenWerfId !== 'geen') {
     chosenWerfName = this.chosenWerf?.naam;
    }
    if(chosenWerfName != null) {
      fileName = fileName +  '-' + chosenWerfName;
    }
    if (date1 != null && date2 != null) {
      fileName = fileName + '-' +("0" + (realDate1.getDate())).slice(-2) + "/" + ("0" + (realDate1.getMonth() + 1)).slice(-2) + "/" + ("0" + (realDate1.getFullYear())).slice(-2) + '-' + ("0" + (realDate2.getDate())).slice(-2) + "/" + ("0" + (realDate2.getMonth() + 1)).slice(-2) +
        "/" + ("0" + (realDate2.getFullYear())).slice(-2);
    } else if (date1 != null || date2 != null) {
      fileName = fileName + '-' + ("0" + (realDate1.getDate())).slice(-2) + "/" + ("0" + (realDate1.getMonth() + 1)).slice(-2) + "/" + ("0" + (realDate1.getFullYear())).slice(-2);
    }
    fileName = fileName + '.pdf';

    fileName = fileName.replace(/[/\\?%*:|"<>]/g, '-');

    this.apiService.getDagrapportenPDF(date1, date2, chosenWerfId, chosenChauffeurId, chosenArbeiderId, chosenWerfName, fileName).subscribe(x => {

      if(x != null){
        if(x.toString().substring(0,5) ===  'Er zi'){
          this.toastrService.danger(x.toString(),'Geen dagrapporten gevonden.')
          this.isGenerating = false;
          return;
        } else {
          this.isGenerating = false;
          this.isPrint = true;
          const url = x as string;

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); // Optional: specify a filename

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }, error => {
      this.isGenerating = false;
      this.toastrService.danger('Er is een fout opgetreden bij het genereren van de pdf.','Fout bij genereren pdf.')
    });
  }

  delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }
  toggleDisplay() {
    const element = document.getElementById('printContainer');
    if (element.style.display == 'block') {
      element.style.display = 'none';
    } else {
      element.style.display = 'block';
    }
  }


  getDagrapportenFromWerfCost(date1: string, date2: string, chosenWerfId: string, chosenChauffeurId?: string, chosenArbeiderId?: string){
    let vroegsteDagrapportDate = new Date(2080,1,1);
    let laatsteDagrapportDate = new Date(2021,11,11);

    const dagrapporten$ = this.apiService.getDagrapportenExcel(date1, date2, chosenWerfId, chosenChauffeurId, chosenArbeiderId);
    const transportDagrapporten$ = this.apiService.getTransportDagrapportenExcel(date1, date2, chosenWerfId, chosenChauffeurId, chosenArbeiderId);

    forkJoin([dagrapporten$, transportDagrapporten$]).subscribe(([dagrapportenResult, transportDagrapportenResult]) => {
      this.dagrapporten = dagrapportenResult as Dagrapport[];
      for(let dagrapport of this.dagrapporten){
        dagrapport.date = new Date(dagrapport.date);
        for(let werk of dagrapport.werken){
          if(werk.startDate != null){
            werk.startDate = new Date(werk.startDate);
            werk.endDate = new Date(werk.endDate);

            if(werk.startDate.getTime() < vroegsteDagrapportDate.getTime()){
              vroegsteDagrapportDate = werk.startDate;
            }
            if(werk.endDate.getTime() > laatsteDagrapportDate.getTime()){
              laatsteDagrapportDate = werk.endDate;
            }
          }
        }
      }

      this.transportDagrapporten = transportDagrapportenResult as Transportdagrapport[];
      if(this.transportDagrapporten != null && this.transportDagrapporten.length !== 0) {
        for (let transportdagrapport of this.transportDagrapporten) {
          transportdagrapport.date = new Date(transportdagrapport.date);
          for (let werk of transportdagrapport.transportwerken) {
            if (werk.dateLaadplaats != null) {
              werk.dateLaadplaats = new Date(werk.dateLaadplaats);
              werk.dateLosplaats = new Date(werk.dateLosplaats);

              if (werk.dateLaadplaats?.getTime() < vroegsteDagrapportDate.getTime()) {
                vroegsteDagrapportDate = werk.dateLaadplaats;
              }
              if (werk.dateLosplaats?.getTime() > laatsteDagrapportDate.getTime()) {
                laatsteDagrapportDate = werk.dateLosplaats;
              }
              if(werk.opmerking != null && werk.opmerking !== ''){
                werk.opmerking = this.formatDateToHourAndMinute(werk.dateLaadplaats) + ' - ' + this.formatDateToHourAndMinute(werk.dateLosplaats) + ': ' + werk.opmerking;
              }
            }
          }
        }
      }

      if(vroegsteDagrapportDate.getFullYear() === 2080){
        vroegsteDagrapportDate = null;
      }
      if(laatsteDagrapportDate.getFullYear() === 2021){
        laatsteDagrapportDate = null;
      }


      // Now both subscriptions have finished, you can continue with any logic here
      if((this.dagrapporten == null || this.dagrapporten.length === 0) && (this.transportDagrapporten == null || this.transportDagrapporten.length === 0)){
        if(date1 == null && date2 == null && chosenWerfId === 'geen'){
          this.toastrService.danger('Selecteer een werf en/of datum(s)','Selecteer datum en/of werf')
        } else {
          this.toastrService.danger('Er zijn geen dagrapporten gevonden met deze zoekcriteria','Geen dagrapporten')
        }
        this.isGenerating = false;
        return;
      }
      let headers;
      let chosenWerfNaam = 'geen';
      if(chosenWerfId !== 'geen'){
        chosenWerfNaam = this.dagrapporten[0].werf.naam;
        headers = ['Datum', 'Ploeg', 'Samenstelling', '€/u', '€/dag','Uren', 'Uitgevoerd werk', 'Opmerking', 'Materialen'];
      } else {
        headers = ['Werf','Datum', 'Ploeg', 'Samenstelling', '€/u', '€/dag','Uren', 'Uitgevoerd werk', 'Opmerking','Materialen'];
      }
      let realDate1;
      let realDate2;
      if(date1 != null && date1 !== 'geen'){
        realDate1 = new Date(date1);
      }else{
        date1 = null;
      }
      if(date2 != null && date2 !== 'geen'){
        realDate2 = new Date(date2);
      } else {
        date2 = null;
      }
      if(this.company.uurLoonChauffeur == null) this.company.uurLoonChauffeur = 0;
        let fileName = 'Kostenberekeningen';
        if(chosenChauffeurId !== 'geen') {
          fileName = fileName +  '-' + this.allChauffeurs.find(x => x._id === chosenChauffeurId).name;
        }
        if(chosenArbeiderId !== 'geen') {
          fileName = fileName +  '-' +this.allArbeiders.find(x => x._id === chosenArbeiderId).name ;
        }
        if(chosenWerfNaam !== 'geen') {
          fileName = fileName +  '-' + chosenWerfNaam;
        }
        if (date1 != null && date2 != null) {
          fileName = fileName + '-' +("0" + (realDate1.getDate())).slice(-2) + "/" + ("0" + (realDate1.getMonth() + 1)).slice(-2) + "/" + ("0" + (realDate1.getFullYear())).slice(-2) + '-' + ("0" + (realDate2.getDate())).slice(-2) + "/" + ("0" + (realDate2.getMonth() + 1)).slice(-2) +
            "/" + ("0" + (realDate2.getFullYear())).slice(-2);
        } else if (date1 != null || date2 != null) {
          fileName = fileName + '-' + ("0" + (realDate1.getDate())).slice(-2) + "/" + ("0" + (realDate1.getMonth() + 1)).slice(-2) + "/" + ("0" + (realDate1.getFullYear())).slice(-2);
        }
        fileName = fileName + '.xlsx';
        this.excelService.generateExcelDagrapportEnTotalen(headers, this.dagrapporten, this.transportDagrapporten,null,this.company.name,vroegsteDagrapportDate,laatsteDagrapportDate, realDate1, realDate2, chosenWerfNaam, this.company, fileName);
        this.isGenerating = false;
    });
  }

  generateWerkzaamheidCost() {
    let werkzaamheid = this.allWerkzaamheden.find(x => x._id === this.chosenWerkzaamheidId);
    if(!this.currentWerkzaamheidHasSubActiviteiten || this.chosenSubActiviteit == null || this.chosenSubActiviteit === ''){
      this.chosenSubActiviteit = '';
    }
    this.apiService.getWerkenByWerkzaamheid(this.chosenWerkzaamheidId, this.chosenSubActiviteit).subscribe(x => {
        this.allWerken = x as Werk[];
      if(this.allWerken == null || this.allWerken.length === 0){
          this.toastrService.danger('Er zijn nog geen werken uitgevoerd bij deze werkzaameid of subactiviteit','Nog geen werken')
        return;
      }
      this.excelService.generateExcelWerkenByWerkzaamheid(this.allWerken, this.company.name, this.werven, werkzaamheid, this.chosenSubActiviteit);
    })
  }

    formatDateToHourAndMinute(date: Date){
      return ('0' + date.getHours()).slice(-2) + ':' + ('0' + (date.getMinutes())).slice(-2);
    }
  async generateNieuweKostenBerekening(datePicker, chosenWerfId: string, chosenChauffeurId: string, chosenArbeiderId: string) {
    this.isGenerating = true;
    this.chosenWerf = this.werven.find(x => x._id === chosenWerfId);
    if(chosenWerfId == null){
      chosenWerfId = 'geen';
    }
    if(chosenChauffeurId == null ||chosenChauffeurId === '')chosenChauffeurId = 'geen';
    if(chosenArbeiderId == null ||chosenArbeiderId === '')chosenArbeiderId = 'geen';
    if(datePicker?.queue?.start != null || datePicker?.queue?.end != null){
      let searchDate;
      if(datePicker?.queue?.start == null){
        searchDate = datePicker;
        this.date = new Date(searchDate);
        this.betweenDates = false;
        this.isOneDate = true;
        await this.getDagrapportenFromWerfCost(this.date.toDateString(), 'geen', chosenWerfId, chosenChauffeurId, chosenArbeiderId);
      } else if(datePicker.queue.end == null) {
        this.date = new Date(datePicker.queue.start);
        this.betweenDates = false;
        this.isOneDate = true;
        await this.getDagrapportenFromWerfCost(this.date.toDateString(), 'geen', chosenWerfId, chosenChauffeurId, chosenArbeiderId);
      } else {
        if(datePicker.queue.start.toString() === datePicker.queue.end.toString()){
          this.date = new Date(datePicker.queue.start);
          this.betweenDates = false;
          await this.getDagrapportenFromWerfCost(this.date.toDateString(), 'geen', chosenWerfId, chosenChauffeurId, chosenArbeiderId);
        } else {
          let startDate = moment(datePicker.queue.start);
          this.date = startDate.toDate();
          this.isOneDate = false;
          this.betweenDates = true;
          let endDate = moment(datePicker.queue.end).toDate();
          await this.getDagrapportenFromWerfCost(this.date.toDateString(), endDate.toDateString(), chosenWerfId, chosenChauffeurId, chosenArbeiderId);
        }
      }
    } else if(chosenWerfId !== 'geen' || chosenChauffeurId !== 'geen' || chosenArbeiderId !== 'geen'){
      this.betweenDates = false;
      this.isOneDate = false;
      await this.getDagrapportenFromWerfCost('geen','geen',chosenWerfId, chosenChauffeurId, chosenArbeiderId);
    } else {
      this.toastrService.danger('Gelieve iets te kiezen als filter','Kies een filter.');
      this.isGenerating = false;
    }
  }

  clearDatum() {
    this.myDate = null;
    this.datePicker = null;
  }
  private filter(value: string): string[] {
    const filterValue = value?.toLowerCase();
    return this.filterItems.filter(optionValue => optionValue.naam.toLowerCase().includes(filterValue)).map(optionValue => optionValue.naam);
  }

  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onChange() {
    this.observableFilteredItems$ = this.getFilteredOptions(this.input.nativeElement.value);
  }

  onSelectionChange($event) {
    this.observableFilteredItems$ = this.getFilteredOptions($event);
    let newSearch = $event;
      let newWerf = this.werven?.find(x => x.naam === newSearch);
      if(newWerf != null){
        this.chosenWerfId = newWerf._id;
        this.filterItems = this.werven;
        this.observableFilteredItems$ = of(this.filterItems.map(x => x.naam));
      }
  }

  clearAutocomplete() {
    this.input.nativeElement.value = '';
    this.filterItems = this.werven;
    this.chosenWerfId = 'geen';
    this.chosenWerf = null;
    this.observableFilteredItems$ = of(this.filterItems.map(x => x.naam));
  }

  getMateriaalOfMachineDetails(werk: any): string {
    let details = '';
    let richting = '';
    if(werk.losplaats?._id === werk.werf?._id){
      richting = 'Aanvoer';
    } else if(werk.laadplaats?._id === werk.werf?._id){
      richting = 'Afvoer';
    } else {
      richting = 'Verplaatsing';
    }
    if(werk.machine != null && werk.machine !== ''){
      details += richting + ' ' +werk.machine.naam;
      if(werk.machine2 != null && werk.machine2 !== ''){
        details += '\n' + richting + ' ' +werk.machine2.naam;
      }
    }
    if(werk.materialen != null && werk.materialen.length !== 0){
      for(let i = 0; i < werk.materialen.length;i++){
        if(i === 0 && ((werk.machine != null && werk.machine !== '') || (werk.machine2 != null && werk.machine2 !== ''))){
          details += '\n';
        }
        if (werk.materialen[i].materiaal) {
          details += richting + ' ' + (werk.materialen[i].hoeveelheid == null ? '': werk.materialen[i].hoeveelheid + ' ' + werk.materialen[i].materiaalGewicht ) + ' ' + werk.materialen[i].materiaal ;
        }
        if(i !== werk.materialen.length - 1){
          details += '\n';
        }
      }
    }
    return details;
  }

  onChangeChauffeur() {
    this.observableFilteredChauffeurs$ = this.getFilteredOptionsChauffeur(this.inputChauffeur.nativeElement.value);
  }

  onSelectionChangeChauffeur($event: any) {
    this.observableFilteredChauffeurs$ = this.getFilteredOptionsChauffeur($event);
    let newSearch = $event;
    let chauffeur = this.allChauffeurs?.find(x => x.name === newSearch);
    if(chauffeur != null){
      this.chosenChauffeurId = chauffeur._id;
      this.filterChauffeurs = this.allChauffeurs;
      this.observableFilteredChauffeurs$ = of(this.filterChauffeurs.map(x => x.name));
    }
  }

  clearAutocompleteChauffeur() {
    this.inputChauffeur.nativeElement.value = '';
    this.filterChauffeurs = this.allChauffeurs;
    this.chosenChauffeurId = 'geen'
    this.observableFilteredChauffeurs$ = of(this.filterChauffeurs.map(x => x.name));
  }


  getFilteredOptionsChauffeur(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filterChauffeur(filterString)),
    );
  }


  filterChauffeur(value: string){
      const filterValue = value?.toLowerCase();
      return this.allChauffeurs?.filter(optionValue => optionValue.name.toLowerCase().includes(filterValue)).map(optionValue => optionValue.name);
  }




  onChangeArbeider() {
    this.observableFilteredArbeiders$ = this.getFilteredOptionsArbeider(this.inputArbeider.nativeElement.value);
  }

  onSelectionChangeArbeider($event: any) {
    this.observableFilteredArbeiders$ = this.getFilteredOptionsArbeider($event);
    let newSearch = $event;
    let arbeider = this.allArbeiders?.find(x => x.name === newSearch);
    if(arbeider != null){
      this.chosenArbeiderId = arbeider._id;
      this.filterArbeiders = this.allArbeiders;
      this.observableFilteredArbeiders$ = of(this.filterArbeiders.map(x => x.name));
    }
  }

  clearAutocompleteArbeider() {
    this.inputArbeider.nativeElement.value = '';
    this.filterArbeiders = this.allArbeiders;
    this.chosenArbeiderId = 'geen'
    this.observableFilteredArbeiders$ = of(this.filterArbeiders.map(x => x.name));
  }


  getFilteredOptionsArbeider(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filterArbeider(filterString)),
    );
  }


  filterArbeider(value: string){
    const filterValue = value?.toLowerCase();
    return this.allArbeiders?.filter(optionValue => optionValue.name.toLowerCase().includes(filterValue)).map(optionValue => optionValue.name);
  }
}
