import {AfterViewInit, Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {User} from "../../models/user";
import {CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {of} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDeleteDialogComponent} from "./confirm-delete-ploeg";
import {FormService} from "../../services/form.service";
import {NbToastrService} from "@nebular/theme";

@Component({
  selector: 'ngx-ploegen-instellen',
  templateUrl: './ploegen-instellen.component.html',
  styleUrls: ['./ploegen-instellen.component.scss']
})
export class PloegenInstellenComponent implements OnInit, AfterViewInit {
  @ViewChild('unassignedList') unassignedList: CdkDropList;
  @ViewChild('newPloegList') newPloegList: CdkDropList;
  @ViewChildren('ploegList') ploegListsQuery: QueryList<CdkDropList>;
  ploegLists: CdkDropList[] = [];

  public isLoaded: boolean;
  isSaving: boolean = false;
  allUsers: User[] = [];
  unassignedUsers: User[] = [];
  ploegen: { ploegbaas: User, voorkeurArbeiders: User[] }[] = [];
  hasChangedValue: boolean = false;
  originalPloegen: { ploegbaas: User, voorkeurArbeiders: User[] }[] = [];
  constructor(private apiService: ApiService, private dialog: MatDialog, private formService: FormService,
              private toastrService: NbToastrService) {}

  ngOnInit() {
    this.hasChangedValue = false;
    this.isLoaded = false;
    this.loadUsers();
  }


  ngAfterViewInit() {
    this.ploegListsQuery.changes.subscribe(() => {
      this.hasChangedValue = true;
      this.updatePloegLists();
    });

    this.updatePloegLists();
  }

  updatePloegLists() {
    this.ploegLists = this.ploegListsQuery.toArray();
  }

 async  loadUsers() {
  let allArbeiders;
  if(this.formService.allUsers){
    allArbeiders =  JSON.parse(JSON.stringify(this.formService.allUsers));
    allArbeiders = allArbeiders.filter(x => (x.role === '59cf78e883680012b0438501' || x.role === '59cf78e883680012b0438502' || x.functieNaam === 'Arbeider') && x.functieNaam !== 'Chauffeur' && x.functieNaam !== 'Onderaannemer' && x.role !== '59cf78e883680012b0438505');
  } else {
    //voor op andere pagina te kunnen gebruiken
    allArbeiders = await this.apiService.getUsersByRoleLight('Ploegbaas&Arbeider').toPromise() as User[];
    allArbeiders = allArbeiders.filter(x => x.functieNaam !== 'Chauffeur');
  }
   allArbeiders.sort((a, b) => a.name?.localeCompare(b.name));
      allArbeiders = allArbeiders.filter(x => x.name !== 'bjorn massoels')
      let allPloegbazen = allArbeiders.filter(x => x.voorkeurArbeiders != null && x.voorkeurArbeiders.length !== 0);
      for(let ploegbaas of allPloegbazen){
        for(let i = 0; i < ploegbaas.voorkeurArbeiders.length; i++){
          let index = allArbeiders.findIndex(x => x._id === ploegbaas.voorkeurArbeiders[i].toString());
          if(index !== -1){
            ploegbaas.voorkeurArbeiders[i] = allArbeiders[index];
          } else {
            ploegbaas.voorkeurArbeiders.splice(i, 1);
            i--;
          }
        }
      }
      allPloegbazen = allPloegbazen.filter(x => x.voorkeurArbeiders.length !== 0);
      this.ploegen = [];
      for(let ploegbaas of allPloegbazen){
        this.ploegen.push({ploegbaas: ploegbaas, voorkeurArbeiders: ploegbaas.voorkeurArbeiders});
        allArbeiders = allArbeiders.filter(x => x._id !== ploegbaas._id && !ploegbaas.voorkeurArbeiders.some(m => m._id === x._id));
      }
     this.originalPloegen = this.ploegen.map(ploeg => ({
       ploegbaas: { ...ploeg.ploegbaas },
       voorkeurArbeiders: [...ploeg.voorkeurArbeiders]
     }));



   this.unassignedUsers = allArbeiders;
   this.isLoaded = true;
  }


  drop(event: CdkDragDrop<User[]>) {
    this.hasChangedValue = true;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.sortListByName(event.container.data);
  }
  sortListByName(list: User[]) {
    list.sort((a, b) => a.name.localeCompare(b.name));
  }

  dropNewPloeg(event: CdkDragDrop<User[]>) {
    this.hasChangedValue = true;
    if (event.previousContainer !== event.container && event.previousContainer.data.length > 0) {
      const newPloegbaas = event.previousContainer.data[event.previousIndex];
      this.createNewPloeg(newPloegbaas);
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      // Leeg de nieuwe ploeg dropzone onmiddellijk
      event.container.data.pop();
    }
  }

  createNewPloeg(ploegbaas: User) {
    this.ploegen.push({ ploegbaas: ploegbaas, voorkeurArbeiders: [] });
    this.ploegen.sort((a, b) => a.ploegbaas.name.localeCompare(b.ploegbaas.name));
  }

  removePloeg(index: number) {
    this.hasChangedValue = true;
      const ploeg = this.ploegen[index];
      const hasMembers = ploeg.voorkeurArbeiders != null && ploeg.voorkeurArbeiders.length > 0;

      const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
        width: '460px',
        data: { ploegName: ploeg.ploegbaas.name, hasMembers }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const removedPloeg = this.ploegen.splice(index, 1)[0];
          // Move the ploegbaas back to unassigned users
          this.unassignedUsers.push(removedPloeg.ploegbaas);
          // Move all voorkeurArbeiders back to unassigned users
          this.unassignedUsers.push(...removedPloeg.voorkeurArbeiders);
          // Sort the unassigned userslist
          this.sortListByName(this.unassignedUsers);
        }
      });
  }

  getAllConnectedLists(): CdkDropList[] {
    return [this.newPloegList, ...this.ploegLists];
  }
  getConnectedListsForPloeg(currentPloegList: CdkDropList): CdkDropList[] {
    return [this.unassignedList, ...this.ploegLists.filter(list => list !== currentPloegList)];
  }

  savePloegen() {
    if(this.isSaving) {
      return;
    }
    const ploegListToDataMap = new Map<CdkDropList, { ploegbaas: User, voorkeurArbeiders: User[] }>();
    this.ploegListsQuery.toArray().forEach((ploegList, index) => {
      ploegListToDataMap.set(ploegList, this.ploegen[index]);
    });
    const updatedPloegen = this.ploegListsQuery.toArray().map(ploegList => {
      const ploegData = ploegListToDataMap.get(ploegList);
      return {
        ploegbaas: ploegData.ploegbaas,
        voorkeurArbeiders: ploegList.data as User[]
      };
    });
    const { changedPloegen, deletedPloegen, newPloegen } = this.findChangedPloegen(this.originalPloegen, updatedPloegen);

    console.log('Changed ploegen:', changedPloegen);

    if (changedPloegen.length > 0 || deletedPloegen.length > 0 || newPloegen.length > 0) {
      // Send only the changed ploegen to the backend
      this.apiService.updatePloegen(changedPloegen, deletedPloegen, newPloegen).subscribe(
        () => {
          console.log('Changed ploegen saved successfully');
          this.toastrService.success('De ploegen zijn gewijzigd.', 'Succes!');
         // this.ploegen = updatedPloegen; // Update the original ploegen array
          this.isSaving = false;
          this.hasChangedValue = false;
        },
        error => {
          this.toastrService.danger('Er is een fout opgetreden bij het opslaan van de ploegen.', 'Fout!');
          console.error('Error saving changed ploegen', error);
          this.isSaving = false;
        }
      );
    } else {
      console.log('No changes detected in ploegen');
      this.isSaving = false;
    }
  }



  private findChangedPloegen(originalPloegen: { ploegbaas: User, voorkeurArbeiders: User[] }[],
                             updatedPloegen: { ploegbaas: User, voorkeurArbeiders: User[] }[]):
    { changedPloegen: { ploegbaas: User, voorkeurArbeiders: User[] }[],
      deletedPloegen: { ploegbaas: User, voorkeurArbeiders: User[] }[],
      newPloegen: { ploegbaas: User, voorkeurArbeiders: User[] }[] } {

    const changedPloegen: { ploegbaas: User, voorkeurArbeiders: User[] }[] = [];
    const newPloegen: { ploegbaas: User, voorkeurArbeiders: User[] }[] = [];
    const deletedPloegen: { ploegbaas: User, voorkeurArbeiders: User[] }[] = [];

    // Create maps for efficient lookup
    const originalPloegMap = new Map(originalPloegen.map(p => [p.ploegbaas._id, p]));
    const updatedPloegMap = new Map(updatedPloegen.map(p => [p.ploegbaas._id, p]));

    // Find new and changed ploegen
    updatedPloegen.forEach(updatedPloeg => {
      const originalPloeg = originalPloegMap.get(updatedPloeg.ploegbaas._id);
      if (!originalPloeg) {
        // This is a new ploeg
        newPloegen.push(updatedPloeg);
      } else {
        // Check if voorkeurArbeiders have changed
        if (this.hasVoorkeurArbeidersChanged(originalPloeg, updatedPloeg)) {
          changedPloegen.push(updatedPloeg);
        }
      }
    });

    // Find deleted ploegen
    originalPloegen.forEach(originalPloeg => {
      if (!updatedPloegMap.has(originalPloeg.ploegbaas._id)) {
        deletedPloegen.push(originalPloeg);
      }
    });

    return { changedPloegen, deletedPloegen, newPloegen };
  }

// Helper function to check if voorkeurArbeiders have changed
  private hasVoorkeurArbeidersChanged(originalPloeg: { ploegbaas: User, voorkeurArbeiders: User[] },
                                      updatedPloeg: { ploegbaas: User, voorkeurArbeiders: User[] }): boolean {
    if (originalPloeg.voorkeurArbeiders.length !== updatedPloeg.voorkeurArbeiders.length) {
      return true;
    }
    return originalPloeg.voorkeurArbeiders.some((arbeider, index) =>
      arbeider._id !== updatedPloeg.voorkeurArbeiders[index]._id
    );
  }
}
